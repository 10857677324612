import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react"

// State
import { useDispatch, useSelector } from "@/state/StateProvider"
import { AuthSliceState, setLastEmail } from "@/state/features/authSlice"

// Types
import { MethodEnum } from "@/api/rest/generated/@types"

const SignInContext = createContext<SignInContextType>(null!)

export type States = "sign-in" | "recovery-codes" | "confirm" | "activate-email"

interface SignInContextType {
	state: States
	setState: (state: States) => void
	initialEmail: string | null
	setInitialEmail: (email: string) => void
	recoveryCodes: Array<string>
	setRecoveryCodes: (recoveryCodes: Array<string>) => void
	method?: MethodEnum
	setMethod: (method: MethodEnum) => void
}

export const useSignIn = () => useContext(SignInContext)

export const SignInProvider = ({
	children,
	recoveryCodes: recoveryCodesFromProps,
	initialEmail: emailFromProps,
}: Partial<Pick<SignInContextType, "recoveryCodes" | "initialEmail">> & {
	children: ReactNode
}) => {
	// State
	const dispatch = useDispatch()
	const rememberMe = useSelector(
		({ auth }: { auth: AuthSliceState }) => auth.rememberMe,
	)
	const lastEmail = useSelector(
		({ auth }: { auth: AuthSliceState }) => auth.lastEmail,
	)
	const [state, setState] = useState<States>("sign-in")
	const [method, setMethod] = useState<MethodEnum>()
	const [recoveryCodes, setRecoveryCodes] = useState<
		SignInContextType["recoveryCodes"]
	>(recoveryCodesFromProps ?? [])

	const [initialEmail, setInitialEmail] = useState(() => {
		if (emailFromProps) return emailFromProps

		if (rememberMe === true) {
			return lastEmail
		}

		return null
	})

	useEffect(() => {
		if (rememberMe === true && initialEmail) {
			dispatch(setLastEmail(initialEmail))
		} else {
			dispatch(setLastEmail(null))
		}
	}, [rememberMe, initialEmail])

	return (
		<SignInContext.Provider
			value={{
				state,
				setState,
				method,
				setMethod,
				initialEmail,
				setInitialEmail,
				recoveryCodes,
				setRecoveryCodes,
			}}
		>
			{children}
		</SignInContext.Provider>
	)
}
