import { Helmet } from "@/lib/seo"
import { FiFile } from "react-icons/fi"
import { useTrans } from "@/i18n"
import { Anchor } from "@/components/Anchor"
import { Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { Tooltip } from "@/components/tooltips/Tooltip"
import { useCurrentProject } from "../hooks"

// Environment variables
import { API_URL } from "@/lib/env"

/**
 * ProjectDocumentation
 * @returns
 */
export const ProjectDocumentation = () => {
	const t = useTrans("project")
	const { data } = useCurrentProject()

	return (
		<>
			<Helmet>
				<title>
					{t("project.documentation.page_title", {
						projectName: data?.project?.name,
					})}
				</title>
			</Helmet>
			<div className="space-y-6">
				<Card className="xl:w-[60%]">
					<Heading as="h5" className="mb-3 sm:truncate">
						{t("project.documentation.page_title", {
							projectName: data?.project?.name,
						})}
					</Heading>
					<p
						className="text-sm text-gray-500"
						data-testid="project.documentation.copy"
					>
						{t("project.documentation.copy")}
					</p>
				</Card>
				<Card className="xl:w-[60%]">
					<Heading as="h5" className="mb-3 sm:truncate">
						{t("project.documentation.downloads_heading")}
					</Heading>
					<div className="grid gap-2">
						{/** Solar bonds certificates */}
						<div className="flex">
							<Anchor
								href={String(
									`${API_URL}api/investor/solar-share-certificate/${data?.project?.id}`,
								)}
								target="_blank"
								download
								className="text-secondary-300 hover:text-secondary-900 flex items-center text-sm font-medium"
							>
								<FiFile className="mr-2" />
								{t(
									"project.documentation.solar_bonds_certificate.title",
								)}
							</Anchor>
						</div>

						{data?.project?.documents?.map((document) => {
							return (
								<div
									className="flex"
									key={String(document!.url)}
								>
									<Anchor
										href={String(document!.url)}
										target="_blank"
										download
										className="text-secondary-300 hover:text-secondary-900 flex items-center text-sm font-medium"
									>
										<FiFile className="mr-2" />
										{document!.title}
									</Anchor>
									{!!document?.description?.length && (
										<Tooltip
											text={document.description}
											targetClassNamesProp={
												"text-gray-500"
											}
										/>
									)}
								</div>
							)
						})}
					</div>
				</Card>
			</div>
		</>
	)
}
