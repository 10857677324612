import { Button } from "@/components/Button"
import { Tooltip } from "@/components/Tooltip"

// Env variables
import {
	// Vercel
	VERCEL_ENV,
	VERCEL_URL,
	VERCEL_BRANCH_URL,
	VERCEL_GIT_COMMIT_SHA,
	VERCEL_GIT_COMMIT_MESSAGE,
	VERCEL_GIT_COMMIT_AUTHOR_NAME,
	GRAPHQL_URL,
	API_URL,
	EXTERNAL_PLATFORM_PRIVACY_POLICY,
	EXTERNAL_PLATFORM_INFO_CENTER,
	SERVER_CSP_NONCE,
	MOCK_BACKEND,
	STORYBOOK_API_URL,
	GTM_CONTAINER_ID,
	JEST_WORKER_ID,
	GOOGLE_MAPS_API_KEY,
	GOOGLE_MAPS_ID,
	DEBUG_I18N,
	SENTRY_DSN,
	SENTRY_DEBUG,
	AUTH_LOGOUT_URL,
	AUTH_LOGIN_URL,
} from "@/lib/env"

/**
 * Info
 * @returns
 */
export function Info() {
	return (
		<div className="p-5">
			<Tooltip content="The Environment that the app is deployed and running on. The value can be either production, preview, or development.">
				<div className="grid grid-cols-2">
					<h2>VERCEL_ENV</h2>
					<p>{VERCEL_ENV || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip
				content="The domain name of the generated deployment URL. Example: *.vercel.app. The value does not include the protocol schemehttps://. 
				NOTE: This Variable cannot be used in conjunction with Standard Deployment Protection. See Migrating to Standard Protection."
			>
				<div className="grid grid-cols-2">
					<h2>VERCEL_URL</h2>
					<p>{VERCEL_URL || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="The domain name of the generated Git branch URL. Example: *-git-*.vercel.app. The value does not include the protocol scheme https://.">
				<div className="grid grid-cols-2">
					<h2>VERCEL_BRANCH_URL</h2>
					<p>{VERCEL_BRANCH_URL || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="The git SHA of the commit the deployment was triggered by. Example: fa1eade47b73733d6312d5abfad33ce9e4068081.">
				<div className="grid grid-cols-2">
					<h2>VERCEL_GIT_COMMIT_SHA</h2>
					<p>{VERCEL_GIT_COMMIT_SHA || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="The message attached to the commit the deployment was triggered by. Example: Update about page.">
				<div className="grid grid-cols-2">
					<h2>VERCEL_GIT_COMMIT_MESSAGE</h2>
					<p>{VERCEL_GIT_COMMIT_MESSAGE || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="The name attached to the author of the commit that the project was deployed by. Example: John Doe.">
				<div className="grid grid-cols-2">
					<h2>VERCEL_GIT_COMMIT_AUTHOR_NAME</h2>
					<p>{VERCEL_GIT_COMMIT_AUTHOR_NAME || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="The GraphQL API endpoint used by the app.">
				<div className="grid grid-cols-2">
					<h2>GRAPHQL_URL</h2>
					<p>{GRAPHQL_URL || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="The base URL for the API services.">
				<div className="grid grid-cols-2">
					<h2>API_URL</h2>
					<p>{API_URL || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="Link to the external platform's privacy policy.">
				<div className="grid grid-cols-2">
					<h2>EXTERNAL_PLATFORM_PRIVACY_POLICY</h2>
					<p>{EXTERNAL_PLATFORM_PRIVACY_POLICY || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="Link to the external platform's information center.">
				<div className="grid grid-cols-2">
					<h2>EXTERNAL_PLATFORM_INFO_CENTER</h2>
					<p>{EXTERNAL_PLATFORM_INFO_CENTER || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="Nonce value for CSP (Content Security Policy) headers.">
				<div className="grid grid-cols-2">
					<h2>SERVER_CSP_NONCE</h2>
					<p>{SERVER_CSP_NONCE || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="Indicates whether the app is using a mock backend.">
				<div className="grid grid-cols-2">
					<h2>MOCK_BACKEND</h2>
					<p>{MOCK_BACKEND || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="The API URL used for Storybook integration.">
				<div className="grid grid-cols-2">
					<h2>STORYBOOK_API_URL</h2>
					<p>{STORYBOOK_API_URL || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="The Google Tag Manager container ID.">
				<div className="grid grid-cols-2">
					<h2>GTM_CONTAINER_ID</h2>
					<p>{GTM_CONTAINER_ID || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="The Jest worker ID used for test runs.">
				<div className="grid grid-cols-2">
					<h2>JEST_WORKER_ID</h2>
					<p>{JEST_WORKER_ID || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="The Google Maps API key for embedding maps.">
				<div className="grid grid-cols-2">
					<h2>GOOGLE_MAPS_API_KEY</h2>
					<p>{GOOGLE_MAPS_API_KEY || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="The Google Maps ID for customizing map styling.">
				<div className="grid grid-cols-2">
					<h2>GOOGLE_MAPS_ID</h2>
					<p>{GOOGLE_MAPS_ID || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="Indicates whether i18n debug mode is enabled.">
				<div className="grid grid-cols-2">
					<h2>DEBUG_I18N</h2>
					<p>{DEBUG_I18N || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="The Sentry DSN for error monitoring.">
				<div className="grid grid-cols-2">
					<h2>SENTRY_DSN</h2>
					<p>{SENTRY_DSN || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="Indicates whether Sentry debug mode is enabled.">
				<div className="grid grid-cols-2">
					<h2>SENTRY_DEBUG</h2>
					<p>{SENTRY_DEBUG || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="URL for the authentication logout process.">
				<div className="grid grid-cols-2">
					<h2>AUTH_LOGOUT_URL</h2>
					<p>{AUTH_LOGOUT_URL || "n/a"}</p>
				</div>
			</Tooltip>

			<Tooltip content="URL for the authentication login process.">
				<div className="grid grid-cols-2">
					<h2>AUTH_LOGIN_URL</h2>
					<p>{AUTH_LOGIN_URL || "n/a"}</p>
				</div>
			</Tooltip>

			<Button
				className="mt-8"
				onClick={() => {
					throw new Error("Test throw error")
				}}
			>
				Test throw error
			</Button>
		</div>
	)
}
