import { useReducedMotion } from "@/lib/animations"
import { useAreFontsReady } from "@/hooks/useAreFontsReady"

function isFirefox() {
	const userAgent = navigator.userAgent.toLowerCase()
	return userAgent.includes("firefox") && !userAgent.includes("chrome/")
}

/**
 * @todo implement <Counter /> clickup: ZPD-8163
 */

export function Counter(props: any) {
	const areFontsReady = useAreFontsReady()
	const hasReducedMotion = useReducedMotion()

	if (hasReducedMotion || isFirefox() || !areFontsReady) {
		return <>{props.text}</>
	}

	return <div {...props} />
}
