// Generic
import { NotFound } from "@/pages/NotFound"
import { Info } from "@/pages/profile/pages/Info"

// Auth
import { Register } from "@/pages/account/Register"
import { Confirm } from "@/pages/account/Confirm"
import { SignIn } from "@/pages/account/SignIn"
import { Reset } from "@/pages/account/Reset"
import { Recover } from "@/pages/account/Recover"

// Misc
import { Recent } from "@/pages/profile/pages/Recent"

// (Investor) Profile
import { Profile } from "@/pages/profile"
import { Investor } from "@/pages/investor"

// Finance
import { FinanceDashboard } from "@/pages/finance/pages/Dashboard"
import { FinancePerformance } from "@/pages/finance/pages/Performance"
import { FinanceSolarInterestTariff } from "@/pages/finance/pages/SolarInterestTariff"
import { FinanceBondsloan } from "@/pages/finance/pages/FinanceBondsloan"
import { Payments } from "@/pages/payments"

// Investments
import { Project } from "@/pages/project"
import { InvestmentsProjectsCurrentOverview } from "@/pages/investments/current-overview"
import { ProjectNotFound } from "@/pages/project/ProjectNotFound"
import { InvestmentsPage } from "@/pages/investments"
import { AppSettings } from "@/pages/profile/pages/AppSettings"

// Stats
import { Dashboard } from "@/pages/statistics/platform/AllProjectStats"
import { SingleProjectStats } from "@/pages/statistics/platform/SingleProjectStats"
import { AllProjectsSolarInterestTariff } from "@/pages/statistics/platform/AllProjectsSolarInterestTariff"

// Constants
import { Pages } from "./routes"
export { Pages } // Re-export for backwards compatibility

/**
 * Lazy loading components
 * DOCS: https://react.dev/reference/react/lazy
 */
export const LazyLoadingPages = {
	NotFound,
	Info,

	// Auth
	Register,
	Confirm,
	SignIn,
	Reset,
	Recover,
	Investor,
	Recent,

	// Finance
	FinanceDashboard,
	FinancePerformance,
	FinanceSolarInterestTariff,
	FinanceBondsloan,
	Payments,

	// Investments
	Project,
	ProjectNotFound,
	Investments: InvestmentsPage,
	InvestmentsProjectsCurrentOverview,

	// Stats
	Dashboard,
	SingleProjectStats,
	AllProjectsSolarInterestTariff,

	// Settings
	AppSettings,
	Profile,
}
