import { useFormik } from "formik"
import { ApiErrorResponseInterface } from "@/api/rest"
import { useTrans } from "@/i18n"
import { Yup } from "@/lib/yup"
import { FormikError } from "@/components/form-controls/Errors"
import {
	FormikErrors,
	FormikInput,
	FormikLabel,
	FormikSubmitButton,
	FormikWrapper,
} from "@/components/form-controls/formik"
import { Heading } from "@/components/Typography"

// Queries
import { useProfileSecurityIntroQuery } from "@/api/graphql"
import { apiAuthActivateCreate } from "@/api/rest/generated/api/api"
import { MethodEnum } from "@/api/rest/generated/@types"

const validationSchema = Yup.object().shape({
	phoneNumber: Yup.string().test(
		"valid-phone-number",
		"profile.security.two_factor_auth.form.invalid_phone_number",
		(value) =>
			typeof value === "string"
				? // @TODO add final validation regarding to supported phone number by smsApi
				  true
				: false,
	),
})

interface ProfileSecurityStep2Props {
	onSuccess: () => void
}

export const SMSMethodConfirmPhoneNumber = ({
	onSuccess,
}: ProfileSecurityStep2Props) => {
	const t = useTrans("profile")

	const { data } = useProfileSecurityIntroQuery()

	const form = useFormik({
		validationSchema,
		initialValues: {
			phoneNumber: data?.me?.profile?.phone ?? "",
		},
		onSubmit: async (_values, helpers) => {
			try {
				const response = await apiAuthActivateCreate(MethodEnum.sms_api)
				// @ts-ignore
				if (!response) {
					helpers.setFieldError(
						"errors.common",
						"common.form_errors.unknown_error",
					)
					return
				}

				onSuccess()
			} catch (e) {
				const error = e as ApiErrorResponseInterface | null

				if (error?.json?.error) {
					helpers.setFieldError("errors.local", error?.json?.error)
				} else {
					helpers.setFieldError(
						"errors.common",
						"common.form_errors.unknown_error",
					)
				}
			}
		},
	})

	return (
		<FormikWrapper form={form}>
			<Heading as="h6" styleAs="h6" className="mb-4 sm:truncate">
				{t("profile.security.two_factor_auth.confirm_number.header")}
			</Heading>

			<div className="text-gray-700">
				<p className="mb-3 text-sm text-gray-500">
					{t("profile.security.two_factor_auth.phone_number")}
				</p>
				<div className="mb-4">
					<FormikLabel htmlFor="phoneNumber" className="sr-only">
						{t(
							"profile.security.two_factor_auth.phone_number.label",
						)}
					</FormikLabel>
					<FormikInput
						disabled
						name="phoneNumber"
						className="block w-full lg:w-1/2"
						type="tel"
					/>
					<FormikError field="phoneNumber" namespace="profile" />
				</div>
				<div>
					<FormikErrors i18nNamespace="profile" />
				</div>
			</div>

			<FormikSubmitButton
				className="lg:ml-auto"
				disableWhenPristine={false}
				size="small"
			>
				{t("profile.security.two_factor_auth.confirm_number.submit")}
			</FormikSubmitButton>
		</FormikWrapper>
	)
}
