import { DateTime } from "@/lib/dates"

type Node = { date?: string | null; performance_ratio?: string | null }

// takes a date, and works out if a date is inbetween these two dates
function isOutOfDateBoundCheck(
	date: DateTime,
	earliest: DateTime,
	latest: DateTime,
) {
	if (earliest < date && latest > date) {
		return true
	}

	return false
}

export function trimArrayDatesAfterDate(
	data: Array<Node | undefined | null>,
	_date?: DateTime,
) {
	if (!data?.length) return data

	const date = _date || DateTime.local().toUTC().endOf("day")

	// we find the first invalid date, by looking at the current node
	// and checking if it's before our date. if it is, we check the _next_
	// node in the data array and see if that one is _after_ our "date".
	const firstInvalidDate = data.find((node, index) => {
		if (!node?.date) {
			return false
		}

		const next = data[index + 1]?.date
		if (!next) {
			return null
		}

		// we want to find the element that is the "last" date that is below
		// the "date". so if the earliest node is before the "date", and the latest
		// is after, then we know we have the last good date
		return isOutOfDateBoundCheck(
			date,
			DateTime.fromISO(node.date),
			DateTime.fromISO(next),
		)
	})?.date

	// we can be in two positions here. either the data array has only
	// dates in the past, or dates in the future. as in, the data array
	// only includes dates that are on one side of our "date". so we need to
	// dig further and check which side of the date fence we are on
	if (!firstInvalidDate) {
		const hasDatesBefore = data.find((node) => {
			if (!node?.date) {
				return false
			}

			const currentNodeDateTime = DateTime.fromISO(node?.date)
			if (currentNodeDateTime < date) {
				return true
			}
			return false
		})

		// so if we have a date that is _before_ our "date", then we know
		// that all the dates are on the smaller side, so we can just return
		// the original array since we are only trimming for  "dates after date"
		if (hasDatesBefore) {
			return data
		}

		// otherwise we are in the latter part, so all the dates are in the future
		return []
	}

	const indexOfFirstInvalidDate = data
		.map((node) => node?.date)
		.indexOf(firstInvalidDate)

	// if the index was the last item, then just return the data
	if (indexOfFirstInvalidDate === data.length - 1) return data

	return data.slice(0, indexOfFirstInvalidDate + 1)
}

type GraphNode = {
	x: number | string
	y: number
}

// returns -1 if there is no "last empty value"
export function indexOfLastMatchInArray(data: Array<GraphNode>, matcher = 0) {
	// we can take a shortcut on some arrays that end in non-zero values
	if (data[data.length - 1]?.y !== matcher) {
		return -1
	}

	// reverse is mutable
	const reversedData = data.slice().reverse()

	const lastValuesX = reversedData.find((node, index) => {
		const nextNodeY = reversedData[index + 1]?.y

		if (node.y === matcher && nextNodeY !== matcher) return true

		return false
	})?.x

	if (lastValuesX === undefined) return data.length - 1

	const indexOfLastValue = data.map((node) => node?.x).indexOf(lastValuesX)

	return indexOfLastValue
}
