import { useState, useEffect } from "react"

export function useAreFontsReady() {
	const [state, set] = useState(false)

	useEffect(() => {
		try {
			// @ts-ignore
			Promise.any([
				document.fonts.ready,
				new Promise((resolve) => setTimeout(resolve, 2000)),
			]).finally(() => {
				set(true)
			})
		} catch {
			set(true)
		}
	}, [])

	return state
}
