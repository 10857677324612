import { useCallback, useRef, useState } from "react"

type Cb = () => void

export const useDebounce = (time: number) => {
	const ref = useRef<NodeJS.Timeout | null>(null)

	const clear = useCallback(() => {
		if (ref.current === null) return
		clearTimeout(ref.current)
	}, [ref])

	const set = useCallback(
		(cb: Cb) => {
			clear()
			ref.current = setTimeout(() => cb(), time)
		},
		[ref, clear, time],
	)

	return [set, clear] as const
}

export const useDebouncedState = <T extends any>(
	initialValue: T,
	debounceTime = 2000,
) => {
	const [debounce, clear] = useDebounce(debounceTime)
	const [value, set] = useState<T>(initialValue)

	const proxySet = useCallback(
		(value: T) => {
			clear()
			set(value)
		},
		[clear, set],
	)

	return [value, proxySet, (value: T) => debounce(() => set(value))] as const
}
