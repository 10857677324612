import {
	BarGraphData,
	LineGraphData,
} from "../../graphs/types/bar-line/MultiGraph"

export function findLongestArrayInMultiGraphData(
	arrays: Array<BarGraphData | LineGraphData>,
) {
	const lengths = arrays.map((a) => a.data.length)
	const index = lengths.indexOf(Math.max(...lengths))

	return arrays[index]
}
