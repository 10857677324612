// React
import { useEffect, useState } from "react"

// Dates
import { DateTime, ms } from "@/lib/dates"

export function useCurrentDate(interval = ms("15m")) {
	const [now, set] = useState(new Date())

	useEffect(() => {
		const work = () => {
			set(new Date())
		}

		const timeout = setInterval(work, interval)
		return () => {
			clearInterval(timeout)
		}
	}, [interval])

	return now
}

export function useCurrentDateTime(interval = ms("15m")) {
	return DateTime.fromJSDate(useCurrentDate(interval))
}
