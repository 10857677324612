import { useMemo, useEffect } from "react"

// Datetime
import { DateTime } from "@/lib/dates"

// GraphlQL
import { useProjectGraphCumulativeProductionQuery } from "@/api/graphql"

// Hooks
import { useCurrentProjectId } from "../hooks/useCurrentProjectId"
import { useCurrentProject } from "../hooks"

// Graphs
import { CumulativeProductionGraph } from "@/components/visx/CumulativeProductionGraph"
import { useProductionCumulativeGraphContext } from "@/components/graphs/types/stacked-area/ProductionCumulative"

function useProductionCumulativeGraphData() {
	const { graphInterval, startTime, setMinimumTime, endTime } =
		useProductionCumulativeGraphContext()

	const projectId = useCurrentProjectId()
	const { data: projectData } = useCurrentProject()

	// Set the start date of the project as minimum time
	useEffect(() => {
		if (projectData?.project?.start) {
			setMinimumTime(
				DateTime.fromISO(projectData?.project?.start).toJSDate(),
			)
		}
	}, [projectData?.project?.start, setMinimumTime])

	const queryOptions = useMemo(() => {
		return {
			projectId: String(projectId),
			startTime,
			endTime,
			// monthly timerange in graph = daily intervals
			// yearly timerange in graph = monthly interals
			// all = monthly interals
			interval: graphInterval === "month" ? "day" : "month",
		}
	}, [startTime, projectId, endTime, graphInterval])

	const { data } = useProjectGraphCumulativeProductionQuery(queryOptions)

	return data?.me?.investment_projects?.results?.[0]
		?.investor_production_stats
}

export function ProjectGraphCumulativeProduction() {
	const { graphInterval } = useProductionCumulativeGraphContext()
	const data = useProductionCumulativeGraphData()

	const cumulativeProductionData = useMemo(() => {
		return (
			data?.cumulative_production_data?.map((data) => {
				return {
					x: data?.date
						? DateTime.fromISO(data.date).toUTC().toMillis()
						: 0,
					y: data?.production ? parseFloat(data.production) : 0,
				}
			}) ?? []
		)
	}, [data?.cumulative_production_data])

	const expectedCumulativeProductionData = useMemo(() => {
		return (
			data?.expected_cumulative_production_data?.map((data) => {
				return {
					x: data?.date
						? DateTime.fromISO(data.date).toUTC().toMillis()
						: 0,
					y: data?.production ? parseFloat(data.production) : 0,
				}
			}) ?? []
		)
	}, [data?.expected_cumulative_production_data])

	return (
		<CumulativeProductionGraph
			data={cumulativeProductionData}
			estimation={expectedCumulativeProductionData}
			topPadding={50}
			key={graphInterval}
		/>
	)
}
