import lodashMerge from "lodash.merge"
import lodashCloneDeep from "lodash.clonedeep"

export const merge = lodashMerge
export const clone = lodashCloneDeep

export const callAll =
	(...fns: Array<Function>) =>
	(...args: any) =>
		fns.forEach((fn) => fn?.(...args))

export const asyncDelay = (ms = 1000) => new Promise((r) => setTimeout(r, ms))

// replace all non alphanunberic characters with an underscore
// replace all spaces with an underscore
export function normaliseString(value: string) {
	return value
		.replace(/[^a-z0-9]/gim, "")
		.replace(/\s+/g, "_")
		.toLowerCase()
}
