import { Fragment, useMemo, useState } from "react"
import { useFinancePerformanceQuery } from "@/api/graphql"

// Icons
import { CheckIcon, SelectorIcon } from "@/lib/icons"

// UI
import { classNames } from "@/lib/classnames"
import { Combobox } from "@headlessui/react"
import { Input } from "@/components/form-controls/Input"

function useCurrentFinanceProject({ projectId }: { projectId?: number }) {
	const { data } = useFinancePerformanceQuery()

	if (projectId) {
		return data?.projects?.find(
			(project) => project.id === String(projectId),
		)
	}
	return null
}

/**
 * ProjectSelector
 * @param param0
 * @returns
 */
interface ProjectSelectorProps {
	projectId?: number
	onChange: (id: string) => void
}
export function ProjectSelector({ projectId, onChange }: ProjectSelectorProps) {
	const { data } = useFinancePerformanceQuery()
	const currentProject = useCurrentFinanceProject({ projectId })
	const [query, setQuery] = useState<string>(currentProject?.name ?? "")

	const sortedProjects = useMemo(() => {
		return (
			data?.projects?.sort(
				(a, b) => a.name?.localeCompare(b?.name ?? "") ?? 0,
			) ?? []
		)
	}, [data?.projects])

	const filteredProjects =
		query === "" || query === currentProject?.name
			? sortedProjects
			: sortedProjects?.filter((project) => {
					return project?.name
						?.toLowerCase()
						.includes(query.toLowerCase())
			  })

	return (
		<Combobox
			as={Fragment}
			value={currentProject?.id}
			onChange={(value) => {
				// Get project name
				const nextProject = data?.projects?.find(
					(project) => project.id === value,
				)?.name

				if (nextProject) {
					setQuery(nextProject)
				}

				// Call onChange
				if (value) onChange(value)
			}}
		>
			<div className="relative mt-1 w-full max-w-xs self-end">
				<Combobox.Button as={Fragment}>
					<Input
						className="w-full"
						onChange={(e) => {
							setQuery(e.currentTarget.value)
						}}
						value={query ?? ""}
					/>
				</Combobox.Button>
				<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
					<SelectorIcon
						className="h-5 w-5 text-gray-400"
						aria-hidden="true"
					/>
				</Combobox.Button>

				{filteredProjects && filteredProjects?.length > 0 && (
					<Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{filteredProjects.map((project) => (
							<Combobox.Option
								key={project?.id}
								value={project?.id}
								className={({ active }) =>
									classNames(
										"relative cursor-default select-none py-2 pl-3 pr-9",
										active
											? "bg-primary-500"
											: "text-gray-900",
									)
								}
							>
								{({ selected }) => (
									<>
										<div className="flex">
											<span
												className={classNames(
													"truncate",
													selected && "font-semibold",
												)}
											>
												{project?.name}
											</span>
										</div>

										{selected && (
											<span className="text-black-500 absolute inset-y-0 right-0 flex items-center pr-4">
												<CheckIcon
													className="h-5 w-5"
													aria-hidden="true"
												/>
											</span>
										)}
									</>
								)}
							</Combobox.Option>
						))}
					</Combobox.Options>
				)}
			</div>
		</Combobox>
	)
}
