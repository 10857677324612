import { ReactNode } from "react"
import { useTrans } from "@/i18n"

// UI
import { Container } from "../Container"
import { ErrorState } from "./ErrorState"
import { ErrorBoundary } from "./ErrorBoundary"

interface GlobalErrorBoundaryProps {
	children: ReactNode
}

/**
 * This is used as a global error boundary for the entire app.
 * Passes all errors on to ErrorBoundary and shows a full-page fallback component.
 * @param param0
 * @returns
 */
export function GlobalErrorBoundary({ children }: GlobalErrorBoundaryProps) {
	const t = useTrans("common")

	return (
		<ErrorBoundary
			fallback={
				<Container className="flex min-h-screen items-center justify-center">
					<ErrorState
						title={t("common.app.error.title")}
						message={t("common.app.error.message")}
					/>
				</Container>
			}
		>
			{children}
		</ErrorBoundary>
	)
}
