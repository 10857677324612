import { useMemo } from "react"

// Translations
import { useLang } from "@/context/lang"
import { useTrans } from "@/i18n"

// UI
import { GraphHeader } from "@/components/graphs/header/GraphHeader"

// Hooks
import { useFinanceGraphProductionData } from "./FinanceGraphProduction"
import {
	AvailableDatePeriods,
	useProductionGraphContext,
} from "@/components/graphs/types/bar/ProductionGraph"

// DateTime
import { DateTime } from "@/lib/dates"
import { useCurrentDate } from "@/hooks/useCurrentDate"

function useToday() {
	return DateTime.fromJSDate(useCurrentDate())
}

export function FinanceGraphProductionHeader() {
	const t = useTrans("finance")
	const { config, formatNumber } = useLang()
	const today = useToday()
	const { data } = useFinanceGraphProductionData()
	const { graphInterval } = useProductionGraphContext()

	const amount = useMemo(() => {
		return data?.me?.finance_projects?.results?.[0]?.overall_statistics?.total_production_data
			?.reduce((acc, curr) => {
				const value = curr?.production ? parseFloat(curr.production) : 0
				return value ? acc + value : acc
			}, 0)
			.toFixed(2)
	}, [data])

	return (
		<>
			{graphInterval === AvailableDatePeriods.MONTH ? (
				<GraphHeader
					title={t("finance.data.production.yearly.title", {
						year: today.year,
					})}
					subtitle={t("finance.data.production.yearly.subtitle", {
						amount: amount ? formatNumber(parseFloat(amount)) : 0,
					})}
				/>
			) : (
				<GraphHeader
					title={t("finance.data.production.monthly.title", {
						month: today
							.toJSDate()
							.toLocaleDateString(config.locale, {
								month: "long",
							}),
					})}
					subtitle={t("finance.data.production.monthly.subtitle", {
						amount: amount ? formatNumber(parseFloat(amount)) : 0,
					})}
				/>
			)}
		</>
	)
}
