import { useTrans } from "@/i18n"
import { EmptyGridState } from "../../../EmptyGridState"
import { Heading } from "../../../Typography"

function FloatingEmptyGridState() {
	return (
		<div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
			<EmptyGridState className="h-full max-h-40 w-full justify-center" />
		</div>
	)
}

export function ProductionCumulativeNoData({
	variant = "today",
}: {
	variant?: "today" | "past"
}) {
	const t = useTrans("project")

	return (
		<div className="absolute left-0 top-1/2 flex w-full -translate-y-1/2 flex-col items-center justify-center">
			<FloatingEmptyGridState />
			<div className="z-2 relative px-8">
				<Heading as="h4" className="text-center">
					{variant === "today"
						? t("project.data.production_cumulative.no_data.title")
						: t(
								"project.data.production_cumulative.no_data.in_the_past.title",
						  )}
				</Heading>
				<p className="mt-2 max-w-2xl text-center text-sm text-gray-500">
					{variant === "today"
						? t("project.data.production_cumulative.no_data.copy")
						: t(
								"project.data.production_cumulative.no_data.in_the_past.copy",
						  )}
				</p>
			</div>
		</div>
	)
}
