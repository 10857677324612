import { HTMLProps, HTMLAttributes } from "react"
import { classNames } from "@/lib/classnames"

type Tags = "h1" | "h2" | "h3" | "h4" | "h5" | "h6"

interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
	// what to render the component as. this also implies styling
	as?: Tags
	// what to style the component as. this uses the "as" prop, if not provided
	// use case: when you want to render a h1, but not have it as large text, so you can
	// style it as a h3
	styleAs?: Tags
}

type ClassesPerTag = { [key in Tags]: string }

const classesPerTag: ClassesPerTag = {
	h1: "text-6xl",
	h2: "text-4xl",
	h3: "text-3xl",
	h4: "text-2xl",
	h5: "text-xl",
	h6: "text-base",
}

export function Heading({
	as: Component = "h1",
	styleAs,
	children,
	className: classNameFromProps = "",
	...rest
}: HeadingProps) {
	const classIndex = styleAs || Component
	const classes = classesPerTag[classIndex]

	const props = {
		className: `${classNameFromProps} ${classes} font-static uppercase font-extrabold`,
		...rest,
	}

	return <Component {...props}>{children}</Component>
}

const secondaryHeadingClassesPerTag: ClassesPerTag = {
	h1: "text-3xl",
	h2: "text-2xl",
	h3: "text-xl",
	h4: "text-lg",
	h5: "text-md",
	h6: "text-sm",
}

export function SecondaryHeading({
	as: Component = "h1",
	styleAs,
	children,
	className,
	...rest
}: HeadingProps) {
	const classIndex = styleAs || Component

	return (
		<Component
			className={classNames(
				secondaryHeadingClassesPerTag[classIndex],
				className,
				"font-bold text-black",
			)}
			{...rest}
		>
			{children}
		</Component>
	)
}

export function Subheading({
	as: Component = "h4",
	children,
	className = "",
	...props
}: HeadingProps) {
	return (
		<Component
			className={`${className} text-xs font-medium uppercase tracking-wider text-gray-500`}
			{...props}
		>
			{children}
		</Component>
	)
}

export function P({ className, ...rest }: HTMLProps<HTMLParagraphElement>) {
	return <p className={classNames(className, "font-flexo")} {...rest}></p>
}
