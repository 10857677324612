import { DateTime } from "@/lib/dates"
import { useTrans } from "@/i18n"
import { Card } from "./Card"
import { TimelineItem, TimelineWrapper } from "./Timeline"
import { Heading } from "./Typography"

/**
 * RecentUserActivity
 * @returns
 */
export const RecentUserActivity = () => {
	const t = { recent: useTrans("recent"), common: useTrans("common") }

	const now = DateTime.local()

	const timeline = [
		{
			type: "login",
			content: `<bold>Log in</bold> on 27th February at 5:32pm on <bold>mobile</bold>`,
			date: now.minus({ minutes: 72 }),
		},
		{
			type: "login",
			content: `<bold>Log in</bold> on 27th February at 5:32pm on <bold>mobile</bold>`,
			date: now.minus({ days: 7 }),
		},

		{
			type: "login",
			content: `<bold>Log in</bold> on 23th February at 12:31pm on <bold>Chrome</bold>`,
			date: now.minus({ days: 400 }),
		},
	]

	return (
		<Card>
			<Heading as="h5" className="mb-3 sm:truncate">
				User Activity
			</Heading>
			<div className="space-y-6 md:grid md:grid-cols-4 md:gap-6 md:space-y-0">
				<div>
					<p className="text-gray-500 xl:w-3/4">
						{t.recent("recent.recent_activity.copy")}
					</p>
				</div>
				<div className="flow-root text-sm md:col-span-3 xl:col-span-2">
					<TimelineWrapper>
						{timeline.map((item, index) => (
							<TimelineItem
								position={
									index === timeline.length - 1
										? "end"
										: undefined
								}
								key={`${item.content}-${index}`}
								type={item.type}
								date={item.date}
								content={item.content}
							/>
						))}
					</TimelineWrapper>
				</div>
			</div>
		</Card>
	)
}
