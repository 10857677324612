import { QueryClient } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			suspense: true,
			staleTime: 5 * 60 * 1000,
			cacheTime: 10 * 60 * 1000,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			retry: 1,
		},
	},
})

// Export
export { ReactQueryDevtools }
export {
	QueryClientProvider,
	QueryClient,
	useIsFetching,
	useQueryErrorResetBoundary,
} from "@tanstack/react-query"
