import { ReactNode, createContext, Suspense, useContext, useState } from "react"
import { DateTime } from "@/lib/dates"
import { getChildByType } from "react-nanny"
import { useCurrentDate } from "@/hooks/useCurrentDate"
import { useSunCalc } from "@/hooks/useSunCalc"
import { CardBody } from "../../../Card"
import { GraphHeaderPreloader } from "../../header/GraphHeader"
import { AreaGraphActions } from "./AreaGraphActions"
import { AreaGraphHeader } from "./AreaGraphHeader"
import { EmptyGridStateAnimated } from "../../../EmptyGridState"

type Context = {
	date: Date
	setDate(graphInterval: Context["date"]): void
	sunrise: number
	sunset: number
	isToday: boolean
	isBeforeSunrise: boolean
	maximumDate: DateTime
	minimumDate: DateTime
}

const AreaGraphContext = createContext<Context>(null!)

export function useAreaGraphContext() {
	const context = useContext(AreaGraphContext)

	if (!context) {
		throw new Error(
			"AreaGraphContext must be used within a AreaGraphProvider",
		)
	}

	return context
}

export function AreaGraphCardPreloader() {
	return (
		<CardBody>
			<div className="w-full lg:w-72">
				<GraphHeaderPreloader />
			</div>
			<div className="h-96 p-10">
				<div className="h-full w-full animate-pulse bg-gray-50" />
			</div>
		</CardBody>
	)
}

interface AreaGraphProps {
	minimumDate?: DateTime
	maximumDate?: DateTime
	children: ReactNode
}

export function AreaGraph({
	// we do not store daily information for days more than a week old
	minimumDate = DateTime.local().minus({ weeks: 1 }).endOf("day"),
	maximumDate = DateTime.local().endOf("day"),
	children,
}: AreaGraphProps) {
	const content = getChildByType(children, AreaGraph.Content)
	const header = getChildByType(children, AreaGraph.Header)

	const now = useCurrentDate()
	const [date, setDate] = useState<Date>(maximumDate.toJSDate())

	const currentDateTime = DateTime.fromJSDate(date)

	const result = useSunCalc(date)
	const sunrise = result.sunrise.getTime()
	const sunset = result.sunset.getTime()

	const nowDateTime = DateTime.fromJSDate(now)

	// is currentDateTime (current selected date) before now?
	const isToday = currentDateTime.hasSame(nowDateTime, "day")
	const isBeforeSunrise = isToday && Number(date) < Number(sunrise)

	return (
		<AreaGraphContext.Provider
			value={{
				date,
				setDate,
				sunrise,
				sunset,
				isBeforeSunrise,
				isToday,
				maximumDate,
				minimumDate,
			}}
		>
			<CardBody
				className="lg:flex lg:items-center lg:justify-between"
				data-testid="production-daily"
			>
				<Suspense
					fallback={
						<GraphHeaderPreloader className="w-full lg:w-72" />
					}
				>
					{header ? (
						header
					) : (
						<>
							<div>
								<AreaGraphHeader />
							</div>
							<div className="mt-4 flex lg:mt-0">
								<AreaGraphActions />
							</div>
						</>
					)}
				</Suspense>
			</CardBody>
			<div className="relative h-96">
				<Suspense
					fallback={
						<EmptyGridStateAnimated className="h-[30%] w-full" />
					}
				>
					{content}
				</Suspense>
			</div>
		</AreaGraphContext.Provider>
	)
}

AreaGraph.Content = ({ children }: { children: ReactNode }) => {
	return <>{children}</>
}

AreaGraph.Header = ({ children }: { children: ReactNode }) => {
	return <>{children}</>
}
