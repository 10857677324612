import { TouchEvent, MouseEvent, useCallback, useMemo, useRef } from "react"
import { AnimatePresence, motion } from "@/lib/animations"
import { stripSeparatorsFromWholeNumber } from "@/lib/math"
import { ParentSize } from "./components/ParentSize"
import { formatGraphValue } from "@/misc/helpers"

// UI
import { Heading, P } from "../Typography"
import {
	Tooltip,
	TooltipLine,
	TooltipPoint,
} from "./components/Tooltip/Tooltip"

// Graphs
import { indexOfLastMatchInArray } from "../graphs/lib/data"
import { Axis, AxisBottom, Orientation, TickRendererProps } from "@visx/axis"
import { curveMonotoneX } from "@visx/curve"
import { localPoint } from "@visx/event"
import { LinearGradient } from "@visx/gradient"
import { GridRows } from "@visx/grid"
import { scaleLinear, scaleTime } from "@visx/scale"
import { AreaClosed, Bar } from "@visx/shape"
import { Text } from "@visx/text"
import { useTooltip } from "@visx/tooltip"
import { bisector, extent, max } from "d3-array"

// Datetime
import { timeFormat } from "@/lib/charts"
import { DateTime } from "@/lib/dates"

// Translations
import { useLang } from "@/context/lang"
import { useTrans } from "@/i18n"
import { getKeyAndValueFromNumber } from "@/lib/i18n"

type TooltipData = {
	label: string
	// null means no value
	// to make sure it's different from 0
	// since we can have 0 values, and 0 is a valid value
	x: number | null
	y: number | null
	y2: number
	// null means no value
	// to make sure it's different from 0
	// since we can have 0 values, and 0 is a valid value
	value: number | null
	value2: number | null
	tooltipTopForEstimation: number
}

type Data = {
	x: number
	y: number
}

export type CumulativeProductionGraphProps = {
	width: number
	height: number
	margin?: { top: number; right: number; bottom: number; left: number }
	accentColor?: string
	brandGray?: string
	data: Array<Data>
	estimation: Array<Data>
	topPadding?: number
}

const CumulativeProductionGraphContainer = (
	props: Omit<CumulativeProductionGraphProps, "width" | "height">,
) => {
	return (
		<ParentSize>
			{({ width, height }) => {
				if (width < 10) return null
				return (
					<CumulativeProductionGraph
						{...props}
						width={width}
						height={height}
					/>
				)
			}}
		</ParentSize>
	)
}

CumulativeProductionGraphContainer.defaultMargin = {
	top: 0,
	left: 66,
	right: 25,
	bottom: 40,
}

export { CumulativeProductionGraphContainer as CumulativeProductionGraph }

// accessors
const getX = (d: Data) => new Date(d.x)
const getY = (d: Data) => d.y ?? 0
const bisectDate = bisector<Data, Date>((d) => new Date(d.x)).left

function CumulativeProductionGraph({
	width,
	height,
	accentColor = "#FFD900",
	brandGray = "#4e5155",
	data,
	estimation,
	margin = CumulativeProductionGraphContainer.defaultMargin,
	topPadding = 25,
}: CumulativeProductionGraphProps) {
	const scrollRef = useRef<HTMLDivElement>(null!)

	// Translations
	const t = useTrans("common")
	const { formatNumber } = useLang()
	const { config } = useLang()

	// DateTimes (should be defined here because they depend on locale)
	// const formatDate = timeFormat("%b %d, '%y")
	const formatDateWithOneMonthInDateRange = timeFormat("%d")
	const formatDateYearWithOneYearInDateRange = timeFormat("%b")
	const formatDateYear = timeFormat("%b %y")

	const { tooltipData, tooltipLeft, tooltipTop, showTooltip, hideTooltip } =
		useTooltip<TooltipData>()

	const containerHeight = height
	height = height - topPadding

	// bounds
	const innerWidth = width - margin.left - margin.right
	const innerHeight = height - margin.top - margin.bottom

	// flatten all datas into one array for picking
	const datasMerged = useMemo(() => {
		if (estimation.length === 0) return data

		return data.map((item, i) => {
			// the idea here is to get the maximum Y value
			// to help with the band and linear scales below
			// otherwise the graph doesn't know the highest y value
			return Object.assign({}, item, {
				// find the largest y by using Math.max
				y: Math.max(item.y, estimation[i].y),
				x: Math.max(item.x, estimation[i].x),
			})
		})
	}, [data, estimation])

	// scales
	const xScale = useMemo(() => {
		return scaleTime({
			range: [margin.left, innerWidth + margin.left],
			domain: extent(data, getX) as [Date, Date],
		})
	}, [innerWidth, data, margin.left])

	const yScale = useMemo(() => {
		return scaleLinear({
			range: [innerHeight + margin.top, margin.top],
			domain: [0, max(datasMerged, getY) ?? 0],
			nice: true,
		})
	}, [margin.top, datasMerged, innerHeight])

	// tooltip handler
	const handleTooltip = useCallback(
		(event: TouchEvent<SVGRectElement> | MouseEvent<SVGRectElement>) => {
			const x = localPoint(event)?.x ?? 0
			const x0 = xScale.invert(x)

			const index = bisectDate(data, x0, 1)
			const d0 = data[index - 1]
			const d1 = data[index]
			const estimation0 = estimation[index - 1]
			const estimation1 = estimation[index]

			let d = d0
			if (d1 && getX(d1)) {
				d =
					x0.valueOf() - getX(d0).valueOf() >
					getX(d1).valueOf() - x0.valueOf()
						? d1
						: d0
			}

			let e = estimation0
			if (estimation1 && getX(estimation1)) {
				e =
					x0.valueOf() - getX(estimation0).valueOf() >
					getX(estimation1).valueOf() - x0.valueOf()
						? estimation1
						: estimation0
			}

			const valueY = getY(d)
			const dataY = yScale(valueY)
			const dataX = xScale(getX(d))
			const estimatedDataY = yScale(getY(e))

			// we set value to null if the next value is empty
			// this is so the line/tooltip/interactive part of the graph
			// does not render the marker when it's at the "end" of the array
			let value: number | null = valueY
			let y: number | null = dataY

			if (!value) {
				const restOfArray = data.slice(index)
				// is this the end of all the 0 values? aka we are on the flat line
				//
				//    ._._._.
				//  ./       \.
				//             \._._._._.
				//               ↑↑↑↑↑ we are here so do not show the marker on the graph
				//						(marker being small yellow circle indicating current hovered value)
				if (restOfArray.every((data) => data.y === 0)) {
					value = null
					y = null
				}
				// is this the end of the data array?
				else if (!data[index]) {
					value = null
					y = null
				}
			}

			showTooltip({
				tooltipData: {
					label: getX(d).toLocaleDateString(config.locale, {
						month: window.innerWidth < 500 ? "short" : "long",
						year: "numeric",
						day: "numeric",
					}),
					y,
					x: dataX,
					y2: yScale(getY(e)),
					value,
					value2: getY(e),
					tooltipTopForEstimation: estimatedDataY,
				},
				tooltipLeft: dataX,
				// what's going on here? well, we want the top of the tooltip line
				// to be at the highest value. since we are working from the top, we need the smallest value!
				tooltipTop: Math.min(dataY, estimatedDataY),
			})
		},
		[xScale, data, estimation, config.locale, showTooltip, yScale],
	)

	function xTickComponent({ formattedValue, ...props }: TickRendererProps) {
		const { i18n, value: displayValue } = getKeyAndValueFromNumber(
			formattedValue
				? Number(stripSeparatorsFromWholeNumber(formattedValue))
				: 0,
		)

		const value = t(i18n, {
			number: formatNumber(displayValue),
		})

		return <Text {...props}>{value}</Text>
	}

	const amountOfUniqueYears = useMemo(
		() =>
			data.reduce((acc, curr) => {
				const { year } = DateTime.fromMillis(curr.x)
				if (!acc.has(year)) {
					acc.add(year)
				}
				return acc
			}, new Set()).size,
		[data],
	)

	const amountOfUniqueMonths = useMemo(
		() =>
			data.reduce((acc, curr) => {
				const { month } = DateTime.fromMillis(curr.x)
				if (!acc.has(month)) {
					acc.add(month)
				}
				return acc
			}, new Set()).size,
		[data],
	)

	const yAxisTickComponentProps = useMemo(() => {
		let numTicks = Math.floor(innerWidth / 100)

		if (amountOfUniqueYears !== 1) {
			numTicks = Math.max(4, Math.floor(innerWidth / 75))
		}

		// do not sure more ticks than lengths of data
		if (data.length && numTicks > data.length) {
			numTicks = data.length
		}

		return { numTicks }
	}, [amountOfUniqueYears, data.length, innerWidth])

	// what is this? this is to calculate the width of the
	// data graph (so not the estimated data). do we this because
	// if the amount in the "data" array is less than the estimated data,
	// for example if it's may 4th, the data array may have data for may 1st-4th,
	// but the estimated data will run from may1st-may30th, what happens is that
	// the edge of the data graph falls down to zero:
	//
	//            .____.
	//      .____/      \
	// .___/             \.
	//
	// the last "." being the value being the last good value, so it's null or 0.
	// but we don't want that trailing edge falling down, since it looks like a fall in values
	// so we put a mask over this graph to make it look like:
	//
	//            .____.
	//      .____/
	// .___/
	// =================
	//
	// the "===" being the width of the mask
	const dataBarWidth = useMemo(() => {
		const indexOfLastData = indexOfLastMatchInArray(data, 0)

		// if there are no 0 matches, then just return the full width
		// or if there is a 0 match, then return the width of the data graph
		if (indexOfLastData < 1) return innerWidth
		// if there is a 0 value at end, return full width
		if (indexOfLastData === data.length - 1) return innerWidth

		const divisor = data.length - 1
		// -1 since we want the one before, since indexOf gives us
		// the first 0 item
		const dividend = indexOfLastData - 1

		return (dividend / divisor) * innerWidth
	}, [data, innerWidth])

	return (
		<div ref={scrollRef} className="relative">
			<svg width={width} height={containerHeight}>
				<svg
					y={topPadding}
					className="overflow-visible"
					height={height}
					width={width}
				>
					{/** look in index.css to see how first child is hidden */}
					<GridRows
						scale={yScale}
						width={width - margin.left - margin.right - 25}
						height={height - margin.top}
						numTicks={3}
						stroke="#C7C8CC"
						strokeDasharray="10,2"
						opacity={0.75}
						top={margin.top}
						left={margin.left + 25}
						className="grpah-grid-rows"
					/>
					<LinearGradient
						id="area-gradient"
						from={accentColor}
						to={accentColor}
						toOpacity={0.5}
					/>
					<LinearGradient
						id="area-gradient-estimate"
						from={brandGray}
						to={brandGray}
						toOpacity={0.01}
						fromOpacity={0.5}
					/>
					<LinearGradient
						id="area-gradient-line"
						from="#000"
						to="#000"
						toOpacity={0}
						fromOpacity={1}
					/>
					{useMemo(
						// below tenary looks random but
						// it helps toggling the graphs on and off for debugging
						() =>
							false ? null : (
								<>
									{/* data */}
									<AreaClosed<Data>
										data={data}
										x={(d) => xScale(getX(d)) ?? 0}
										y={(d) => yScale(getY(d)) ?? 0}
										yScale={yScale}
										strokeWidth={0}
										stroke="url(#area-gradient)"
										fill="url(#area-gradient)"
										clipPath="url(#mask)"
										curve={curveMonotoneX}
									/>
								</>
							),
						[data, yScale, xScale],
					)}
					<clipPath id="mask">
						<motion.rect
							x={margin.left}
							y={margin.top}
							height={innerHeight}
							fill="white"
							initial={{ width: 0 }}
							animate={{
								// if there is less data than estimation data, we only animate the mask
								// up to the last point
								width: dataBarWidth,
							}}
							transition={{
								duration: 2,
								delay: 1,
							}}
						/>
					</clipPath>
					{useMemo(
						// below tenary looks random but
						// it helps toggling the graphs on and off for debugging
						() =>
							false ? null : (
								<>
									{/* estimated */}
									<AreaClosed<Data>
										data={estimation}
										x={(d) => xScale(getX(d)) ?? 0}
										y={(d) => yScale(getY(d)) ?? 0}
										yScale={yScale}
										strokeWidth={0}
										stroke="url(#area-gradient-estimate)"
										fill="url(#area-gradient-estimate)"
										clipPath="url(#mask-estimate)"
										curve={curveMonotoneX}
									/>
								</>
							),
						[estimation, yScale, xScale],
					)}
					<clipPath id="mask-estimate">
						<motion.rect
							x={margin.left}
							y={margin.top}
							height={innerHeight}
							fill="white"
							initial={{ width: 0 }}
							viewport={{ once: true, root: scrollRef }}
							animate={{
								width: innerWidth,
							}}
							transition={{
								duration: 2,
							}}
						/>
					</clipPath>
					<Bar
						x={margin.left}
						y={margin.top}
						width={innerWidth}
						height={innerHeight}
						fill="transparent"
						rx={14}
						onTouchStart={handleTooltip}
						onTouchMove={handleTooltip}
						onMouseMove={handleTooltip}
						onMouseLeave={hideTooltip}
					/>
					<Axis
						numTicks={3}
						orientation={Orientation.left}
						scale={yScale}
						top={margin.top + 7}
						left={margin.left}
						hideTicks
						hideZero
						hideAxisLine
						tickLabelProps={() => ({
							fontSize: 20,
							color: brandGray,
							fontFamily: "Static",
							fontWeight: 700,
							opacity: 0.5,
							textAnchor: "middle",
							enableBackground: "white",
						})}
						tickComponent={xTickComponent}
					/>
					<AxisBottom
						labelOffset={10}
						top={innerHeight + margin.top}
						left={0}
						orientation={Orientation.bottom}
						tickFormat={(v) => {
							const cb =
								amountOfUniqueYears === 1 &&
								amountOfUniqueMonths === 1
									? formatDateWithOneMonthInDateRange
									: amountOfUniqueMonths > 1
									? formatDateYear
									: amountOfUniqueYears > 1
									? formatDateYear
									: formatDateYearWithOneYearInDateRange

							return cb(new Date(String(v)))
						}}
						scale={xScale}
						tickLength={0}
						hideTicks
						{...yAxisTickComponentProps}
						tickComponent={(props) => (
							<svg
								x={props.x}
								y={-2}
								className="overflow-visible"
							>
								<circle r={3} cx={0} cy={3} />
								<text
									x={0}
									y={20}
									className="font-flexo text-sm"
								>
									<tspan
										dominantBaseline="middle"
										textAnchor="middle"
									>
										{props.formattedValue}
									</tspan>
								</text>
							</svg>
						)}
					/>
					<AnimatePresence>
						{tooltipTop !== undefined &&
						tooltipLeft !== undefined ? (
							<motion.svg
								initial={{ opacity: 0 }}
								exit={{ opacity: 0 }}
								animate={{ opacity: 1 }}
							>
								<TooltipLine
									x={tooltipLeft}
									y={tooltipTop}
									width={4}
									height={
										innerHeight -
										Math.min(
											tooltipTop,
											tooltipData?.tooltipTopForEstimation ??
												0,
										)
									}
								>
									{tooltipData?.y2 ? (
										<TooltipPoint
											x={tooltipLeft}
											y={tooltipData.y2}
											fill="#4e5155"
										/>
									) : null}
									{tooltipData?.y ? (
										<TooltipPoint
											x={tooltipLeft}
											y={tooltipData.y}
											fill="#FFD900"
										/>
									) : null}
								</TooltipLine>
							</motion.svg>
						) : null}
					</AnimatePresence>
				</svg>
			</svg>
			<AnimatePresence>
				{tooltipData &&
				tooltipTop !== undefined &&
				tooltipLeft !== undefined ? (
					<motion.div
					// initial={{ opacity: 0 }}
					// exit={{ opacity: 0 }}
					// animate={{ opacity: 1 }}
					>
						<Tooltip
							left={tooltipLeft}
							top={tooltipTop}
							offsetLeft={10}
							offsetTop={-42}
							key={
								tooltipLeft / 2 < innerWidth ? "left" : "right"
							}
						>
							<TotalProductionTooltip
								value={tooltipData.value}
								value2={tooltipData.value2}
								label={tooltipData.label}
							/>
						</Tooltip>
					</motion.div>
				) : null}
			</AnimatePresence>
		</div>
	)
}

const displayFullDigitsInTooltip = true

function TotalProductionTooltip({
	label,
	value,
	value2,
}: {
	label: string
	value: number | string | null
	value2?: number | string | null
}) {
	const t = useTrans()
	const { formatNumber } = useLang()

	function getValue(value: number | string) {
		const formattedValue = formatGraphValue(value)

		if (displayFullDigitsInTooltip) {
			return formatNumber(formattedValue)
		}

		const { i18n, value: displayValue } =
			getKeyAndValueFromNumber(formattedValue)

		const text = t(i18n, {
			number: formatNumber(displayValue),
		})

		return text
	}

	// only add decimal points if necessary
	if (typeof value === "number" && Math.round(value) !== value) {
		value = value.toFixed(2)
	}
	if (typeof value2 === "number" && Math.round(value2) !== value2) {
		value2 = value2.toFixed(2)
	}

	return (
		<div className="px-4 py-3">
			<Heading as="h3" styleAs="h5" className="text-black">
				{label}
			</Heading>
			<P className="grid grid-cols-[10px_auto_1fr] gap-x-3 gap-y-1 text-black md:mt-1">
				{value !== null ? (
					<>
						<svg width={10} height={10} viewBox="0 0 10 10">
							<circle r={5} cx={5} cy={5} fill="#FFD900" />
						</svg>
						{t("common.multi_graph.tooltip.total_production")}
						<span className="font-bold">
							{t(
								"common.multi_graph.tooltip.total_production.value",
								{
									value: getValue(value),
								},
							)}
						</span>
					</>
				) : null}
				{value2 !== null && value !== undefined ? (
					<>
						<svg width={10} height={10} viewBox="0 0 10 10">
							<circle r={5} cx={5} cy={5} fill="#4e5155" />
						</svg>
						{t("common.multi_graph.tooltip.expected_production")}
						<span className="font-bold">
							{t(
								"common.multi_graph.tooltip.expected_production.value",
								{ value: getValue(value2 as number) },
							)}
						</span>
					</>
				) : null}
			</P>
		</div>
	)
}
