import { BiHide, BiShow } from "react-icons/bi"
import { useTrans } from "@/i18n"

interface ShowPasswordProps {
	display: boolean
	name: string
	setDisplay: (newState: boolean) => void
}

export const ShowPassword = ({
	display,
	name,
	setDisplay,
}: ShowPasswordProps) => {
	const t = useTrans("common")

	return (
		<>
			<input
				type="checkbox"
				checked={display}
				className="sr-only"
				name={name}
				onChange={() => setDisplay(!display)}
			/>
			<label
				className="ml-auto flex cursor-pointer items-center space-x-1 text-sm text-gray-500 hover:text-gray-700"
				onClick={() => setDisplay(!display)}
				htmlFor={name}
			>
				{display ? (
					<>
						<BiHide />
						<span>{t("common.password_reveal.hide")}</span>
					</>
				) : (
					<>
						<BiShow />
						<span>{t("common.password_reveal.show")}</span>
					</>
				)}
			</label>
		</>
	)
}
