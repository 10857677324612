import { forwardRef, memo, ReactNode } from "react"
import { FormikContextType, FormikProvider, useFormikContext } from "formik"
import get from "lodash.get"
import { useTrans } from "@/i18n"
import { Button, SubmitButton, SubmitButtonProps } from "../Button"
import { Checkbox, CheckboxProps } from "./Checkbox"
import { FormError } from "./Errors"
import { Input, InputProps } from "./Input"
import { Label, LabelProps } from "./Label"
import { Radio, RadioProps } from "./Radio"
import { TextArea, TextAreaProps } from "./TextArea"

// helper function so we can get object from dot notation
// since in some forms there are deeply nested form states
// i.e.: get({ a: { b:1 } }, ["a", "b"])
function getFromPath(
	object: Object,
	path: string | number | symbol,
	defaultValue?: any,
) {
	return get(
		object,
		typeof path === "string" ? path.split(".") : path,
		defaultValue,
	)
}

export type FormikRadioProps = RadioProps & { name: string }

const FormikRadioContainer = forwardRef<HTMLInputElement, FormikRadioProps>(
	(props, ref) => {
		const form = useFormikContext<any>()

		return (
			<Radio
				onChange={form.handleChange}
				onBlur={form.handleBlur}
				id={props.name}
				value={getFromPath(form.values, props.name, "")}
				ref={ref}
				{...props}
			/>
		)
	},
)

export type FormikLabelProps = LabelProps<string | Object>

function FormikLabelContainer(props: FormikLabelProps) {
	const form = useFormikContext()

	const hasError =
		props.htmlFor &&
		Boolean(
			getFromPath(form.errors, props.htmlFor, "") &&
				getFromPath(form.touched, props.htmlFor, ""),
		)

	// @ts-ignore
	return <Label {...props} hasError={hasError} />
}

export type FormikInputProps = Omit<InputProps, "name"> & {
	name: KeyOfObjectOrString<string | Object>
	id?: KeyOfObjectOrString<string | Object>
}

function FormikInputContainer(props: FormikInputProps) {
	const form = useFormikContext<any>()

	const hasError = Boolean(
		getFromPath(form.errors, props.name, "") &&
			getFromPath(form.touched, props.name, ""),
	)

	return (
		<Input
			onChange={form.handleChange}
			onBlur={form.handleBlur}
			value={getFromPath(form.values, props.name, "")}
			hasError={hasError}
			id={String(props.name)}
			{...props}
			name={String(props.name)}
		/>
	)
}

export type FormikTextAreaProps = TextAreaProps & { name: string }

const FormikTextAreaContainer = forwardRef<
	HTMLTextAreaElement,
	FormikTextAreaProps
>((props, ref) => {
	const form = useFormikContext<any>()

	return (
		<TextArea
			onChange={form.handleChange}
			onBlur={form.handleBlur}
			id={props.name}
			value={getFromPath(form.values, props.name, "")}
			ref={ref}
			type="text"
			{...props}
		/>
	)
})

export type FormikCheckboxProps = CheckboxProps & { name: string }

const FormikCheckboxContainer = forwardRef<
	HTMLInputElement,
	FormikCheckboxProps
>((props, ref) => {
	const form = useFormikContext<any>()

	return (
		<Checkbox
			id={props.name}
			checked={Boolean(getFromPath(form.values, props.name))}
			onChange={form.handleChange}
			onBlur={form.handleBlur}
			aria-label={props.name}
			ref={ref}
			{...props}
		/>
	)
})

export interface FormikWrapperProps {
	form: FormikContextType<any>
	children: ReactNode
}

function FormikWrapperContainer({ form, children }: FormikWrapperProps) {
	return (
		<FormikProvider value={form}>
			<form onSubmit={form.handleSubmit}>{children}</form>
		</FormikProvider>
	)
}

export type FormikSubmitButtonProps = SubmitButtonProps

function FormikSubmitButtonContainer({
	children,
	disabled,
	// when the form is in not dirty ("pristine") state, disable submit
	disableWhenPristine = true,
	...rest
}: FormikSubmitButtonProps) {
	const form = useFormikContext<any>()
	const t = useTrans("common")

	return (
		<SubmitButton
			disabled={
				disabled ||
				!form.isValid ||
				(disableWhenPristine && !form.dirty)
			}
			isSubmitting={form.isSubmitting}
			type="submit"
			{...rest}
		>
			{children || t("common.form.submit")}
		</SubmitButton>
	)
}

function FormikResetContainer() {
	const form = useFormikContext<any>()
	const t = useTrans("common")

	return (
		<Button variant="transparent" onClick={form.handleReset}>
			{t("common.form.reset")}
		</Button>
	)
}

export type FormikErrorProps = {
	field: KeyOfObjectOrString<string | Object>
	reliesOnField?: KeyOfObjectOrString<string | Object>
	namespace?: string
	className?: string
}

function FormikErrorContainer<Values>({
	field,
	reliesOnField,
	namespace = "common",
	className,
}: FormikErrorProps) {
	const t = useTrans(namespace)
	const { touched, errors } = useFormikContext<Values>()

	let error =
		getFromPath(touched, field, "") &&
		typeof getFromPath(errors, field, "") === "string"
			? t(getFromPath(errors, field, "") as string)
			: null

	if (reliesOnField) {
		if (!getFromPath(touched, reliesOnField, "")) {
			error = null
		}
	}

	return <FormError error={error} id={String(field)} className={className} />
}

const FormikErrorsContainer = ({
	i18nNamespace,
}: {
	i18nNamespace: string
}) => {
	const { errors } = useFormikContext<{
		errors: { local: string; common: string }
	}>()
	const t = useTrans(i18nNamespace)
	const commonTrans = useTrans("common")

	let errorText = null
	if (errors?.errors?.local) {
		errorText = t(errors?.errors?.local)
	}
	if (!errorText && errors?.errors?.common) {
		errorText = commonTrans(errors?.errors?.common)
	}

	return <FormError error={errorText} id={errorText ?? ""} />
}

export const FormikLabel = memo(
	FormikLabelContainer,
) as typeof FormikLabelContainer

// i don't know why it's complaining!
// @ts-ignore
export const FormikInput = memo(
	FormikInputContainer,
) as typeof FormikInputContainer

export const FormikErrors = memo(
	FormikErrorsContainer,
) as typeof FormikErrorsContainer

export const FormikError = memo(
	FormikErrorContainer,
) as typeof FormikErrorContainer

export const FormikTextArea = memo(
	FormikTextAreaContainer,
) as typeof FormikTextAreaContainer

export const FormikWrapper = memo(
	FormikWrapperContainer,
) as typeof FormikWrapperContainer

export const FormikSubmitButton = memo(
	FormikSubmitButtonContainer,
) as typeof FormikSubmitButtonContainer

export const FormikCheckbox = memo(
	FormikCheckboxContainer,
) as typeof FormikCheckboxContainer

export const FormikRadio = memo(
	FormikRadioContainer,
) as typeof FormikRadioContainer

export const FormikReset = FormikResetContainer
