// D3 graphs
import { bisector, extent, max } from "d3-array"
import { timeFormat, timeFormatDefaultLocale } from "d3-time-format"

// HighCharts
import {
	Highcharts,
	HighchartsReact,
	defaultOptions,
	miniOptions,
	Highchart,
	Stockchart,
	MiniChart,
} from "@/lib/highcharts"

// Initialize
export const setLang = async (lang: string) => {
	// Set locale
	let locale
	switch (lang) {
		case "nl":
			locale = await import("../i18n/locales/nl/d3-time-format.json")
			break
		default:
			locale = await import("../i18n/locales/en/d3-time-format.json")
	}

	// Set default time format locale
	// @ts-ignore
	timeFormatDefaultLocale(locale)
}

// Time formats commonly used throughout the app
export const formatYear = timeFormat("%Y")
export const formatMonth = timeFormat("%b %y")
export const formatDay = timeFormat("%d")

// Export D3
export { bisector, extent, max, timeFormat }

// HighCharts exports
export {
	Highcharts,
	HighchartsReact,
	defaultOptions,
	miniOptions,
	Highchart,
	Stockchart,
	MiniChart,
}
