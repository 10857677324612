import { useTranslation, Trans, I18nextProvider } from "react-i18next"
import { TFunction } from "i18next"

const useTrans = (namespace: string | string[] = "common") => {
	return useTranslation(namespace).t
}

// Export
export { useTrans, useTranslation, Trans, I18nextProvider }

// Types
export type { TFunction }
