import { HTMLProps, ReactNode } from "react"
import { FiChevronDown } from "react-icons/fi"
import { useTrans } from "@/i18n"
import { classNames } from "@/lib/classnames"
import { Button, ButtonSizes, ButtonVariants } from "./Button"

type SelectOption = { key: string; value: string }

interface SelectProps {
	value?: string
	label: string | ReactNode
	options: Array<SelectOption>
	onChangeValue: (newValue: string | undefined) => void
	displayAll?: boolean
	labelProps?: HTMLProps<HTMLLabelElement> & { "data-testid"?: string }
	selectProps?: HTMLProps<HTMLSelectElement> & { "data-testid"?: string }
	variant?: ButtonVariants
	size?: ButtonSizes
	className?: string
}

function resolveValue(option?: SelectOption): string {
	return option?.value ?? ""
}

function resolveKey(option?: SelectOption): string {
	return option?.key ?? ""
}

export const Select = ({
	value,
	label,
	options,
	onChangeValue,
	displayAll,
	selectProps = {},
	size = "small",
	variant = "transparent",
	className,
}: SelectProps) => {
	const t = useTrans()

	return (
		<label
			className={classNames(
				"relative flex items-center text-xs",
				className,
			)}
		>
			<Button size={size} variant={variant}>
				{label || value}
				<FiChevronDown className="ml-2" />
				<select
					{...selectProps}
					className={classNames(
						selectProps?.className,
						"absolute left-0 top-0 h-full w-full cursor-pointer p-0 opacity-0",
					)}
					value={value}
					onChange={(e) => {
						onChangeValue((e.target.value as string) ?? undefined)
					}}
				>
					{displayAll && (
						<option value="">{t("common.select.all")}</option>
					)}
					{options.map((option, i) => (
						<option key={i} value={resolveKey(option)}>
							{resolveValue(option)}
						</option>
					))}
				</select>
			</Button>
		</label>
	)
}
