import { availableLanguageFiles, availableLanguages } from "@/misc/constants"
const localeData: { [key: string]: boolean } = {}

// Generate paths for locale data availability
availableLanguageFiles.forEach((namespace) => {
	availableLanguages.forEach((lang) => {
		const pathLocal = `./locales/${lang}/${namespace}.json`
		const pathAbsolute = `i18n/locales/${lang}/${namespace}.json`
		localeData[pathLocal] = true
		localeData[pathAbsolute] = true
	})
})

const loadLocales = async (lang: string, namespace: string) => {
	const path = `./locales/${lang}/${namespace}.json`
	// Do not try to load the chunk if it doesn't exist
	if (!localeData[path]) {
		return {}
	}
	try {
		const module = await import(`./locales/${lang}/${namespace}.json`)
		return module.default
	} catch (error) {
		console.error(
			`Failed to load locale data for ${lang}/${namespace}:`,
			error,
		)
		return {}
	}
}

export { localeData, loadLocales }
