import { Highchart, Highcharts, HighchartsReact } from "@/lib/highcharts"
import { clone, merge } from "@/lib/js"

export const graphConfig = {
	primary: {
		color: `rgba(255, 217, 0, 1)`,
		lineColor: "rgba(209, 178, 0, 1)",
		lineWidth: 5,
		fillColor: {
			linearGradient: {
				x1: 0,
				x2: 0,
				y1: 0,
				y2: 1,
			},
			stops: [
				[0, "rgba(255, 217, 0, .66)"] as const,
				[1, "rgba(255, 217, 0, 0)"] as const,
			],
		},
	},
	secondary: {
		color: `rgba(40, 123, 255, 1)`,
		lineColor: "rgba(0, 71, 185, 1)",
		lineWidth: 5,
		fillColor: {
			linearGradient: {
				x1: 0,
				x2: 0,
				y1: 0,
				y2: 1,
			},
			stops: [
				[0, "rgba(40, 123, 255, .66)"] as const,
				[1, "rgba(40, 123, 255, 0)"] as const,
			],
		},
	},
	tertiary: {
		color: `#4e5155`,
		lineColor: "#4e5155",
		opacity: 0.4,
		lineWidth: 5,
		fillColor: {
			linearGradient: {
				x1: 0,
				x2: 0,
				y1: 0,
				y2: 1,
			},
			stops: [
				[0, "rgba(478,81,85, .66)"] as const,
				[1, "rgba(478,81,85, 0)"] as const,
			],
		},
	},
	detail: {
		color: `#4e5155`,
		lineColor: "#4e5155",
		lineWidth: 1,
		fillColor: "transparent",
		dashStyle: "Dash",
		enableMouseTracking: false,
	},
}

const defaultOptions: Highcharts.Options = {
	chart: {
		style: {
			fontFamily: `system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial ,sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'`,
		},
	},
	tooltip: {
		className: "shadow rounded-sm sm:rounded-lg bg-white",
		useHTML: true,
		borderWidth: 0,
		style: {
			fill: "white",
		},
	},
}

export const LineGraph = ({ options, ...rest }: HighchartsReact.Props) => (
	<Highchart
		{...rest}
		options={merge(
			clone(defaultOptions),
			{
				credits: {
					enabled: false,
				},
				yAxis: {
					visible: false,
				},
				xAxis: {
					visible: false,
				},
				title: {
					text: "",
				},
			},
			clone(options),
		)}
	/>
)

interface ProductionTodayProps {
	sunset: number
	sunrise: number
	data: { data: Array<[number, number]>; name: string }
	onHover?: (value: any) => void
}

export const ProductionToday = ({
	sunset,
	sunrise,
	data,
	onHover,
}: ProductionTodayProps) => (
	<Highchart
		options={merge(clone(defaultOptions), {
			title: {
				text: "",
			},
			credits: {
				enabled: false,
			},
			exporting: {
				enabled: false,
			},
			yAxis: {
				visible: true,
				labels: { enabled: false },
			},
			xAxis: {
				visible: true,
				type: "datetime",
				max: sunset,
				min: sunrise,
				crosshair: {
					width: 30,
					color: `rgba(0,0,0,0.05)`,
				},
			},
			tooltip: {
				xDateFormat: "%H:%M",
				valueDecimals: 1,
				valueSuffix: "kW",
			},
			series: [
				{
					animation: false,
					type: "areaspline",
					...data,
				},
			],
			legend: { enabled: false },
			plotOptions: {
				areaspline: {
					marker: { enabled: false },
					...graphConfig.primary,
				},
				series: {
					point: {
						events: {
							mouseOver(e) {
								onHover?.(e.target)
							},
						},
					},
				},
			},
		} as Highcharts.Options)}
	/>
)

interface ProductionChartProps {
	name: string
	data: Array<[string, number]>
}

export const ProductionChart = ({ name, data }: ProductionChartProps) => (
	<LineGraph
		options={merge(clone(defaultOptions), {
			chart: {
				spacingTop: 0,
				spacingRight: 0,
				spacingLeft: 0,
				plotBorderWidth: 0,
			},
			plotOptions: { areaspline: { marker: { enabled: false } } },
			xAxis: {
				minPadding: 0,
				maxPadding: 0,
				crosshair: {
					width: 30,
					color: `rgba(0,0,0,0.05)`,
				},
			},
			series: [
				{
					...graphConfig.primary,
					animation: false,
					type: "areaspline",
					name,
					data,
				},
			],
			tooltip: {
				shared: true,
			},
		} as Highcharts.Options)}
	/>
)

interface TotalProductionProps {
	id?: string
	options?: Highcharts.Options
	actual: { name: string; data: Array<[string, number]> }
	expected: { name: string; data: Array<[string, number]> }
}

export const TotalProduction = ({
	actual,
	expected,
	id = "total-production",
	options,
}: TotalProductionProps) => (
	<Highchart
		options={merge(
			clone(defaultOptions),
			{
				credits: {
					enabled: false,
				},
				id,
				chart: {
					spacingTop: 0,
					spacingRight: 0,
					spacingLeft: 0,
					plotBorderWidth: 0,
				},
				plotOptions: { areaspline: { marker: { enabled: false } } },
				xAxis: {
					visible: false,
					minPadding: 0,
					maxPadding: 0,
					crosshair: {
						width: 30,
						color: `rgba(0,0,0,0.05)`,
					},
				},
				title: {
					text: "",
				},
				yAxis: { visible: false },
				series: [
					{
						...graphConfig.primary,
						animation: false,
						type: "areaspline",
						id: `${id}-actual`,
						...actual,
					},
					{
						...graphConfig.detail,
						animation: false,
						type: "areaspline",
						id: `${id}-expected`,
						...expected,
					},
				],
				tooltip: {
					shared: true,
				},
			} as Highcharts.Options,
			clone(options),
		)}
	/>
)
