export const floorTo = (value: number, to: number) =>
	Math.floor(value / to) * to

export const formatToDecimalPlaces = (num: number) =>
	Math.round(num * 100) / 100

export const isWholeNumber = (num: number) => num % 1 === 0

export const stripSeparatorsFromWholeNumber = (num: string) =>
	num.replace(/,/g, "")
