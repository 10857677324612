// Redux
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

// Types
import {
	InvestmentsOverviewState,
	ProjectPageSize,
	ProjectSortMethod,
	ProjectSortOrder,
	ALL_PROJECT_TYPES,
	ALL_PROJECT_STATES,
	ProjectPageViewTypes,
	ProjectTypeEnumFiltered,
	ProjectStateEnumFiltered,
} from "./types"

export const initialState: InvestmentsOverviewState = {
	perPage: ProjectPageSize.Size6,
	showHiddenProjects: true,
	hiddenIds: [],
	sortingMethod: ProjectSortMethod.Name,
	sortingOrder: ProjectSortOrder.Asc,
	filterType: ALL_PROJECT_TYPES,
	filterState: ALL_PROJECT_STATES,
	viewType: ProjectPageViewTypes.Grid,
}

const investmentsOverviewSlice = createSlice({
	name: "investments/overview",
	initialState,
	reducers: {
		setPerPage: (state, action: PayloadAction<ProjectPageSize>) => {
			state.perPage = action.payload
		},
		setShowHiddenProjects: (state, action: PayloadAction<Boolean>) => {
			state.showHiddenProjects = action.payload
		},
		setHiddenIds: (
			state,
			action: PayloadAction<{ id: number; isHidden: boolean }>,
		) => {
			const { id, isHidden } = action.payload
			if (isHidden) {
				// Add to array if isHidden is true (only if it's not already present)
				if (!state.hiddenIds.includes(id)) {
					state.hiddenIds.push(id)
				}
			} else {
				// Remove from array if isHidden is false
				state.hiddenIds = state.hiddenIds.filter(
					(hiddenId) => hiddenId !== id,
				)
			}
		},
		setSortingMethod: (state, action: PayloadAction<ProjectSortMethod>) => {
			state.sortingMethod = action.payload
		},
		setSortingOrder: (state, action: PayloadAction<ProjectSortOrder>) => {
			state.sortingOrder = action.payload
		},
		setFilterType: (
			state,
			action: PayloadAction<ProjectTypeEnumFiltered>,
		) => {
			state.filterType = action.payload
		},
		setFilterState: (
			state,
			action: PayloadAction<ProjectStateEnumFiltered>,
		) => {
			state.filterState = action.payload
		},
		setViewType: (state, action: PayloadAction<ProjectPageViewTypes>) => {
			state.viewType = action.payload
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	setPerPage,
	setShowHiddenProjects,
	setHiddenIds,
	setSortingMethod,
	setSortingOrder,
	setFilterType,
	setFilterState,
	setViewType,
} = investmentsOverviewSlice.actions

export default investmentsOverviewSlice.reducer
