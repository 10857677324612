import SunCalc from "suncalc"

// default amsterdam lat long
export function useSunCalc(
	date: Date,
	latitude: number = 51.5,
	longitude: number = -0.1,
) {
	const { sunrise, sunset } = SunCalc.getTimes(date, latitude, longitude)
	return { sunrise, sunset }
}
