import { useTrans } from "../../../../i18n"
import { Heading } from "../../../Typography"

export interface MultiGraphTooltipProps {
	label: string
	values: Array<{
		id: string
		colour: string
		value: number | string | null
	}>
}

export function MultiGraphTooltipContent({
	label,
	values,
}: MultiGraphTooltipProps) {
	const t = useTrans()

	return (
		<div className="bg-white px-4 py-3">
			<Heading as="h3" styleAs="h5" className="text-black">
				{label}
			</Heading>
			<div className="flex flex-col flex-wrap text-black md:mt-1">
				{values.map(({ value, id, colour }) => {
					return (
						<div
							key={id}
							className="mb-2 flex flex-wrap items-center last-of-type:mb-0"
						>
							<div className="mr-2">
								<svg width={10} height={10} viewBox="0 0 10 10">
									<circle r={5} cx={5} cy={5} fill={colour} />
								</svg>
							</div>
							<div>
								<div>
									{t(`common.multi_graph.tooltip.${id}`)}
								</div>
								<span className="font-bold">
									{value === null
										? t(
												`common.multi_graph.tooltip.${id}.no_data_yet`,
										  )
										: t(
												`common.multi_graph.tooltip.${id}.value`,
												{
													value,
												},
										  )}
								</span>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
