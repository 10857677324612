import { ArrowLeftIcon } from "@heroicons/react/outline"
import { Link } from "./Anchor"
import { Pages } from "@/misc/pages"

interface BackIconProps {
	href: Pages
}
export const BackIcon = ({ href }: BackIconProps) => (
	<Link href={href} data-testid="back-icon">
		<ArrowLeftIcon className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 hover:text-gray-700" />
	</Link>
)
