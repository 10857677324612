import { DateTime } from "@/lib/dates"
import { useCurrentDateTime } from "@/hooks/useCurrentDate"
import { useTrans } from "@/i18n"
import { GraphHeader } from "../../header/GraphHeader"
import { useAreaGraphContext } from "./AreaGraph"

export function AreaGraphHeader() {
	const t = useTrans("project")
	const { sunrise, date, sunset, isBeforeSunrise, isToday } =
		useAreaGraphContext()

	const currentDateTime = useCurrentDateTime()

	if (isBeforeSunrise) {
		return (
			<GraphHeader
				title={t("project.data.production_today.title")}
				subtitle={t(
					"project.data.production_today.subtitle.post_sunset",
					{
						sunrise,
						sunset,
					},
				)}
			/>
		)
	}

	return (
		<GraphHeader
			title={
				isToday
					? t("project.data.production_today.title")
					: t("project.data.production_today.in_the_past.title", {
							date: DateTime.fromJSDate(date).toFormat(
								t(
									"project.data.production_today.in_the_past.date_format",
								),
							),
					  })
			}
			subtitle={t(
				currentDateTime.toMillis() < sunset
					? "project.data.production_today.subtitle.pre_sunset"
					: "project.data.production_today.subtitle.post_sunset",
				{
					sunset: DateTime.fromMillis(sunset).toFormat("HH:mm"),
					sunrise: DateTime.fromMillis(sunrise).toFormat("HH:mm"),
					now: currentDateTime.toFormat("HH:mm"),
				},
			)}
		/>
	)
}
