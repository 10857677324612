import { Helmet } from "@/lib/seo"
import { ChangePassword } from "@/components/forms/ChangePassword"
import { useTrans } from "@/i18n"

export const ProfileChangePassword = () => {
	const t = useTrans("profile")

	return (
		<>
			<Helmet>
				<title>{t("profile.change_password.title")}</title>
			</Helmet>
			<ChangePassword />
		</>
	)
}
