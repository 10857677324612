import { useMemo, useState } from "react"
import { DateTime } from "@/lib/dates"
import { apiDateFormat } from "@/misc/constants"
import { useProjectGraphDailyProductionQuery } from "@/api/graphql"
import { useToday } from "@/hooks/useToday"
import { useCurrentProjectId } from "../hooks/useCurrentProjectId"

// Graphs
import { useAreaGraphContext } from "@/components/graphs/types/area/AreaGraph"
import { AreaGraphBeforeSunrise } from "@/components/graphs/types/area/AreaGraphBeforeSunrise"
import { ProductionDailyNoData } from "@/components/graphs/types/area/ProductionDailyNoData"
import { RealtimeProduction } from "@/components/visx/RealtimeProduction"

function useProjectGraphDailyProductionQueryChartData() {
	const { data } = useProjectGraphDailyProductionQueryData()

	return useMemo(() => {
		return (
			data?.me?.investment_projects?.results?.[0]?.investor_production_stats?.production_for_day?.production_data
				?.filter((item) => Boolean(item?.date))
				.map((item) => ({
					x: item?.date ? DateTime.fromISO(item.date).toMillis() : 0,
					y: item?.production ? parseFloat(item.production) : 0,
				})) ?? []
		)
	}, [data?.me?.investment_projects])
}

function useHasNoData() {
	const chartData = useProjectGraphDailyProductionQueryChartData()
	const { data } = useProjectGraphDailyProductionQueryData()

	return (
		!chartData?.length ||
		// does the project have no data? we can check this by looking through
		// the array and seeing if all of the values have null or "0".
		// since the api can return a full array, but full of null values or "0"
		data?.me?.investment_projects?.results?.[0]?.investor_production_stats?.production_for_day?.production_data?.every(
			(value) => value?.production === null || value?.production === "0",
		)
	)
}

function useProjectGraphDailyProductionQueryData() {
	const projectId = useCurrentProjectId()
	const { date } = useAreaGraphContext()

	return useProjectGraphDailyProductionQuery({
		projectId: String(projectId),
		day: DateTime.fromJSDate(date).toFormat(apiDateFormat),
	})
}

export function ProjectGraphProductionDaily() {
	const today = useToday()
	const [, set] = useState(280)
	const { date, sunrise, sunset } = useAreaGraphContext()

	const { data } = useProjectGraphDailyProductionQueryData()
	const chartData = useProjectGraphDailyProductionQueryChartData()

	const isBeforeSunrise = Number(date) < Number(sunrise)
	const hasNoData = useHasNoData()

	if (isBeforeSunrise) {
		return <AreaGraphBeforeSunrise />
	}

	if (hasNoData) {
		return (
			<ProductionDailyNoData
				variant={
					DateTime.fromJSDate(date).hasSame(today, "day")
						? "today"
						: "past"
				}
			/>
		)
	}

	const values = {
		sunset: DateTime.fromMillis(
			chartData[chartData.length - 1]?.x || sunset,
		).toFormat("HH:mm"),
		sunrise: DateTime.fromMillis(chartData[0]?.x || sunset).toFormat(
			"HH:mm",
		),
		now: DateTime.fromJSDate(date).toFormat("HH:mm"),
	}

	const totalValue = data?.me?.investment_projects?.results?.[0]
		?.investor_production_stats?.production_for_day?.total_production
		? parseFloat(
				data.me.investment_projects?.results?.[0]
					.investor_production_stats.production_for_day
					.total_production,
		  )
		: 0

	return (
		<RealtimeProduction
			key={date.toDateString()}
			margin={{
				top: 24,
				bottom: 48,
				left: 24,
				right: 24,
			}}
			onTooltip={(data) => {
				set(parseFloat(data.y.toFixed(2)))
			}}
			data={chartData}
			sunrise={values.sunrise}
			sunset={values.sunset}
			valueUnit="wh" // This graph is in wh instead of kwh
			totalValue={totalValue}
		/>
	)
}
