import { useEffect, useState } from "react"
import { useTrans } from "@/i18n"
import { sortBy } from "lodash"
import { useToasts } from "@/context/toasts"
import { VERIFICATION_CODE_LENGTH } from "./TwoFactorAuthSettings"

// Rest api
import {
	apiAuthCodeRequestCreate,
	apiAuthMfaChangePrimaryMethodCreate,
	useApiAuthMfaUserActiveMethodsList,
	apiAuthDeactivateCreate,
} from "@/api/rest/generated/api/api"

// Types
import { UserMFAMethod, MethodEnum } from "@/api/rest/generated/@types"

// UI
import { PromptDialog } from "@/components/dialogs/PromptDialog"
import { MfaMethodButton } from "./MfaMethodButton"

/**
 * TwoFactorAuthState
 * @returns
 */
export const TwoFactorAuthState = () => {
	const t = useTrans("profile")
	const toasts = useToasts()
	const commonTrans = useTrans("common")

	const [isPromptDialogOpen, setPromptDialogOpen] = useState<boolean>(false)
	const [dialogCallbackProps, setDialogCallbackProps] = useState({})
	const [isInvalidAuthCode, setIsInvalidAuthCode] = useState<boolean>(false)
	const [mfaState, setMfaState] = useState<UserMFAMethod[]>([])
	const [promptMessage, setPromptMessage] = useState<string>("")

	const { isSuccess, data, refetch } = useApiAuthMfaUserActiveMethodsList()

	useEffect(() => {
		if (isSuccess) {
			setMfaState(sortBy(data, ["name"]))
		}
	}, [data, isSuccess])

	const authCodeRequest = async (method: UserMFAMethod) => {
		try {
			await apiAuthCodeRequestCreate({ method: method.name })
		} catch (e) {
			toasts.addToast({
				variant: "alert",
				id: `changePrimaryMethodRequest`,
				text: commonTrans(`common.request_failed`),
			})

			return
		}
	}

	const deactivateSelectedMethodRequest = async (method: UserMFAMethod) => {
		await authCodeRequest(method)

		const promptMsgParams = {
			method: t(`profile.security.mfa_method.${method.name}`),
		}
		const promptMsg = t(
			"profile.security.two_factor_auth.state.prompt.enter_received_code.prompt",
			promptMsgParams,
		)
		setPromptMessage(promptMsg)

		setDialogCallbackProps({
			methodName: method.name,
			callbackFn: handleDeactivateSelectedMethod,
		})
		setPromptDialogOpen(true)
	}

	const handleDeactivateSelectedMethod = (
		value: string,
		methodName: MethodEnum,
	) => {
		apiAuthDeactivateCreate(methodName, {
			code: value,
		})
			.then(() => {
				refetch()
				const methodNameTranslated = t(
					`profile.security.mfa_method.${methodName}`,
				)
				toasts.addToast({
					variant: "success",
					id: `handle-deactivate-method-${Date.now()}`,
					text: t(
						`profile.security.two_factor_auth.state.deactivate_success`,
						{
							method: methodNameTranslated,
						},
					),
				})
				setPromptDialogOpen(false)
			})
			.catch(() => {
				setIsInvalidAuthCode(true)
				toasts.addToast({
					variant: "alert",
					id: `deactivate-2fa-method-error`,
					text: t(
						`profile.security.verify_code.code.invalid_verification_code`,
					),
				})
			})
	}

	const setPrimaryMethodRequest = async (method: UserMFAMethod) => {
		await authCodeRequest(method)

		const promptMsgParams = {
			method: t(`profile.security.mfa_method.${method.name}`),
		}
		const promptMsg = t(
			"profile.security.two_factor_auth.state.prompt.enter_received_code.prompt",
			promptMsgParams,
		)
		setPromptMessage(promptMsg)

		setDialogCallbackProps({
			methodName: method.name,
			callbackFn: handleSetPrimaryMethod,
		})
		setPromptDialogOpen(true)
	}

	const handleSetPrimaryMethod = (value: string, methodName: MethodEnum) => {
		apiAuthMfaChangePrimaryMethodCreate({
			code: value,
			method: methodName,
		})
			.then(() => {
				refetch()
				const methodNameTranslated = t(
					`profile.security.mfa_method.${methodName}`,
				)
				toasts.addToast({
					variant: "success",
					id: `handleChangePrimaryMethod-${Date.now()}`,
					text: t(`profile.security.two_factor_auth.state.success`, {
						method: methodNameTranslated,
					}),
				})
				setPromptDialogOpen(false)
				setPromptMessage("")
				setDialogCallbackProps({})
			})
			.catch(() => {
				setIsInvalidAuthCode(true)
				toasts.addToast({
					variant: "alert",
					id: `change-2fa-primary-method-error`,
					text: t(
						`profile.security.verify_code.code.invalid_verification_code`,
					),
				})
			})
	}

	return mfaState.length > 0 ? (
		<>
			<PromptDialog
				isOpen={isPromptDialogOpen}
				onClose={() => setPromptDialogOpen(false)}
				title={t(
					"profile.security.two_factor_auth.state.prompt.enter_received_code.header",
				)}
				prompt={promptMessage}
				onConfirm={(value, methodName, callbackFn) => {
					callbackFn(value, methodName)
				}}
				inputProps={{
					type: "input",
					props: {
						maxLength: VERIFICATION_CODE_LENGTH,
						type: "text",
						hasError: isInvalidAuthCode,
						placeholder: "000000",
					},
				}}
				{...dialogCallbackProps}
			/>

			<div className="text-sm text-gray-500 md:w-8/12">
				{mfaState.filter((method) => !method.is_primary).length > 0 && (
					<>
						<p className="mb-2">
							{t(
								"profile.security.two_factor_auth.state.methods_activated_intro",
							)}
							:
						</p>
						{mfaState
							.filter((method) => !method.is_primary)
							.map((method) => {
								return (
									<MfaMethodButton
										key={method.name}
										method={method}
										deactivateMethodRequest={
											deactivateSelectedMethodRequest
										}
										setPrimaryMethodRequest={
											setPrimaryMethodRequest
										}
									/>
								)
							})}
					</>
				)}
			</div>
		</>
	) : null
}
