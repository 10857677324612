import { useMemo } from "react"
import { useToasts } from "@/context/toasts"

// GraphQL
import {
	useAllProjectNotificationsQuery,
	useInvestorUpdateMutation,
} from "@/api/graphql"
import { useTrans } from "@/i18n"
import { Button } from "../Button"
import { CardBody, CardFooter, CardWrapper } from "../Card"

// Forms
import { FormikProvider, useFormik } from "formik"
import { FormGroup } from "../form-controls/FormGroup"
import {
	FormikCheckbox,
	FormikErrors,
	FormikSubmitButton,
} from "../form-controls/formik"
import { Label } from "../form-controls/Label"
import { handleErrorWithFormik } from "@/lib/formik"

// UI
import { Heading } from "../Typography"

interface FormValues {
	checked: {
		[key: string | number]: boolean
	}
	errors: {
		local: string
		common: string
	}
}

export const AllProjectNotifications = () => {
	const toasts = useToasts()
	const t = useTrans("profile")

	const { data, refetch } = useAllProjectNotificationsQuery()

	const updatePersonalDetailsMutation = useInvestorUpdateMutation({
		onSuccess: async (response) => {
			//TODO: Add error handling

			// do we have data? then success
			if (response?.investor_update) {
				toasts.addToast({
					variant: "success",
					id: `notifications-success-${Date.now()}`,
					text: t(`profile.project_notifications.success_message`),
				})
				await refetch()
			}
			// otherwise show a message that there are form errors
			else {
				form.setFieldError(
					"errors.common",
					"common.form_errors.unknown_error",
				)
			}
		},
		onError: (error: Error) => {
			handleErrorWithFormik(error, form)
		},
	})

	const form = useFormik<FormValues>({
		initialValues: {
			checked: {
				...data?.me?.investment_projects?.results?.reduce(
					(acc, curr) => {
						if (curr?.id) {
							const disabled_projects =
								data.me?.investor?.notification_configuration
									?.disabled_yearly_interest_report_projects ||
								[]
							const status =
								disabled_projects.filter(
									// @ts-ignore
									(project) => project?.id === curr.id,
								).length === 0 || false // @ts-ignore
							acc[curr.id] = status
						}
						return acc
					},
					{} as Record<string, boolean>,
				),
			},
			errors: { local: "", common: "" },
		},
		onSubmit: async (values, _helpers) => {
			await updatePersonalDetailsMutation.mutateAsync({
				investor: {
					notification_configuration: {
						disabled_yearly_interest_report_projects: Object.keys(
							values?.checked,
						).reduce((acc: number[], key: string) => {
							// We want to send everything to the mutation that is NOT checked!
							values?.checked[key] === false &&
								acc.push(Number(key))
							return acc
						}, []),
					},
				},
			})
		},
	})

	function unselectAll() {
		const allUnchecked = data?.me?.investment_projects?.results?.reduce(
			(acc, curr) => {
				if (curr?.id) {
					acc[curr.id] = false
				}
				return acc
			},
			{} as Record<string, boolean>,
		)
		form.setFieldValue("checked", allUnchecked)
	}

	function selectAll() {
		const allChecked = data?.me?.investment_projects?.results?.reduce(
			(acc, curr) => {
				if (curr?.id) {
					acc[curr.id] = true
				}
				return acc
			},
			{} as Record<string, boolean>,
		)
		form.setFieldValue("checked", allChecked)
	}

	const { hasSelectedAll, hasUnSelectedAll } = useMemo(() => {
		const checkedValues = Object.values(form.values.checked)

		return {
			hasSelectedAll:
				checkedValues.length ===
					data?.me?.investment_projects?.results?.length &&
				checkedValues.every((value) => value === true),
			hasUnSelectedAll: checkedValues.every((value) => !value),
		}
	}, [form.values.checked, data?.me?.investment_projects])

	return (
		<FormikProvider value={form}>
			<CardWrapper>
				<form
					onSubmit={form.handleSubmit}
					data-testid="project-notifications"
				>
					<CardBody>
						<Heading
							as="h2"
							styleAs="h5"
							className="mb-3 sm:truncate"
						>
							{t("profile.project_notifications.title")}
						</Heading>
						<FormGroup
							heading={t("profile.project_notifications.copy")}
						>
							<div className="space-y-6">
								{data?.me?.investment_projects?.results?.map(
									(project) => (
										<div
											className="flex items-center"
											key={project?.id}
										>
											<div className="order-1 ml-3">
												<Label
													htmlFor={`checked.${project?.id}`}
													positioning="grouped"
												>
													{project?.name}
												</Label>
											</div>
											<FormikCheckbox
												name={`checked.${project?.id}`}
												checked={Boolean(
													form.values.checked[
														project?.id as string
													],
												)}
												onChange={() =>
													form.setFieldValue(
														"checked",
														{
															...form.values
																.checked,
															[project?.id as string]:
																!form.values
																	.checked[
																	project?.id as string
																],
														},
													)
												}
												className="order-0"
											/>
										</div>
									),
								)}
								<div className="space-x-4 border-t border-gray-200 pt-6">
									<Button
										onClick={selectAll}
										type="button"
										disabled={hasSelectedAll}
									>
										{t(
											"profile.project_notifications.select_all",
										)}
									</Button>
									<Button
										onClick={unselectAll}
										type="button"
										disabled={hasUnSelectedAll}
									>
										{t(
											"profile.project_notifications.unselect_all",
										)}
									</Button>
								</div>
								<div>
									<FormikErrors i18nNamespace="profile" />
								</div>
							</div>
						</FormGroup>
					</CardBody>
					<CardFooter className="text-right">
						<FormikSubmitButton>
							{t(
								"profile.project_notifications.form_actions.save",
							)}
						</FormikSubmitButton>
					</CardFooter>
				</form>
			</CardWrapper>
		</FormikProvider>
	)
}
