// Router
import { Pages } from "@/misc/pages"

// Translations
import { useTrans } from "@/i18n"

// UI
import { PageTemplateLink } from "@/templates/PageTemplate"

// Hooks
import { useCurrentFinanceProject } from "../hooks/useCurrentFinanceProject"

// Types
import { ProjectTypeEnum } from "@/api/graphql"

/**
 * FinanceProjectSubMenuTabs
 * @returns
 */
export function FinanceProjectSubMenuTabs() {
	// Translation
	const t = useTrans(["finance", "common"])

	// Get current finance project
	const { data: currentProject } = useCurrentFinanceProject()

	return (
		<>
			<PageTemplateLink
				to={`${Pages.FinanceProjects}/${currentProject.project?.id}`}
				name={t("common:common.navigation.finance.performance.link")}
				analyticsId={t(
					"common:common.navigation.finance.performance.link",
				)}
			/>
			<PageTemplateLink
				to={`${Pages.FinanceSolarInterestTariff}/${currentProject.project?.id}`}
				name={t(
					"common:common.navigation.finance.solar_interest_tariff.link",
				)}
				analyticsId={t(
					"common:common.navigation.finance.dashboard.link",
				)}
			/>

			{/** Show project owner loan info */}
			{currentProject?.project?.type === ProjectTypeEnum.SolarShares ||
			currentProject?.project?.type === ProjectTypeEnum.Portfolio ||
			currentProject?.project?.type === ProjectTypeEnum.SolarBonds ? (
				<PageTemplateLink
					to={`${Pages.FinanceBondsloan}/${currentProject.project.id}`}
					name={t("common:common.navigation.finance.bonds_loan.link")}
					analyticsId={t(
						"common:common.navigation.finance.bonds_loan.link",
					)}
				/>
			) : null}
		</>
	)
}
