/**
 * Freshdesk Feedback widget
 *
 * DOCS: https://developers.freshdesk.com/widget-api/
 * SOURCE: https://informatie.zonhub.com/a/admin/widgets/17000000710
 */

// Prefill Freshdesk ticket form with user data
export const setFreshdeskUser = ({
	name,
	email,
}: {
	name: string
	email: string
}) => {
	window.FreshworksWidget("identify", "ticketForm", {
		name,
		email,
	})
}
