// React
import { useMemo } from "react"

// UI
import { Heading, P } from "@/components/Typography"
import { Text } from "@visx/text"
import {
	GraphData,
	MultiLineGraph,
	TooltipComponentProps,
	TooltipComponentType,
} from "@/components/graphs/types/multi-line/MultiLineGraph"

// Helpers
import { emptyValue } from "@/misc/helpers"

// Dates
import { DateTime } from "@/lib/dates"

// Translation
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// REST queries
import { useApiV1ComparisonEndexProgressGlobalRetrieve } from "@/api/rest/generated/api/api"
import { EndexTariffItem } from "@/api/rest/generated/@types"

/**
 * GlobalSolarInterestTariffGraph
 * @returns
 */
export function GlobalSolarInterestTariffGraph() {
	const { formatCurrency } = useLang()

	// Rest Query
	const { data } = useApiV1ComparisonEndexProgressGlobalRetrieve({
		start_date: DateTime.local().minus({ years: 1 }).toFormat("yyyy-MM-dd"),
		end_date: DateTime.local().toFormat("yyyy-MM-dd"),
	})

	const graphData = useMemo(
		() =>
			data?.endex_tariffs
				?.filter(Boolean)
				.map((entry: EndexTariffItem) => {
					return {
						x: new Date(entry.date),
						y: Math.max(entry.tariff ? entry.tariff : 0, 0), // Should never go below zero
					} as GraphData
				}) ?? [],
		[data?.endex_tariffs],
	)

	const lineDatas = useMemo(
		() => [
			{
				data: graphData,
				id: "performance_ratio",
				variant: "primary" as const,
			},
		],
		[graphData],
	)

	if (graphData.length === 0) {
		return <GraphProductionNoData />
	}

	return (
		<MultiLineGraph
			lineDatas={lineDatas}
			margin={{ left: 66, right: 25, top: 25, bottom: 50 }}
			TooltipComponent={EndexTariffTooltip}
			xTickComponent={({ formattedValue, ...props }) => {
				return (
					<Text {...props} x={props.x + 5}>
						{formattedValue}
					</Text>
				)
			}}
			yTickComponent={({ formattedValue, ...props }) => (
				<Text {...props}>
					{formattedValue
						? formatCurrency(Number(formattedValue))
						: emptyValue()}
				</Text>
			)}
		/>
	)
}

/**
 * EndexTariffTooltip
 * @param param0
 * @returns
 */
const EndexTariffTooltip: TooltipComponentType = ({
	label,
	value,
	value2,
}: TooltipComponentProps) => {
	const t = useTrans("finance")
	const { formatCurrency } = useLang()

	function getValue(value: number | string) {
		return formatCurrency(Number(value))
	}

	// only add decimal points if necessary
	if (typeof value === "number" && Math.round(value) !== value) {
		value = value.toFixed(2)
	}
	if (typeof value2 === "number" && Math.round(value2) !== value2) {
		value2 = value2.toFixed(2)
	}

	return (
		<div className="px-4 py-3">
			<Heading as="h3" styleAs="h5" className="text-black">
				{label}
			</Heading>
			<P className="grid grid-cols-[10px_auto_1fr] gap-x-3 gap-y-1 text-black md:mt-1">
				{/** endex tariff */}
				{value !== null && (
					<>
						<svg width={10} height={10} viewBox="0 0 10 10">
							<circle
								r={5}
								cx={5}
								cy={5}
								fill="rgb(20, 154, 229)"
							/>
						</svg>
						{t("finance.solar_interest_tariff.block.endex_tariffs")}
						<span className="font-bold">
							{t(
								"finance.solar_interest_tariff.graph.tooltip.endex_tariffs.value",
								{
									year: 24,
									price: getValue(value),
								},
							)}
						</span>
					</>
				)}
			</P>
		</div>
	)
}

function GraphProductionNoData() {
	const t = useTrans("project")

	return (
		<div className="flex h-full flex-col items-center justify-center">
			<Heading as="h4">
				{t("project.data.production.no_data.title")}
			</Heading>
			<p className="mt-2 max-w-lg text-center text-sm text-gray-500">
				{t("project.data.production.no_data.copy")}
			</p>
		</div>
	)
}
