import { ReactNode, HTMLAttributes } from "react"
import { DateTime } from "@/lib/dates"
import {
	Area,
	AreaChart,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts"

export type LineChartProps = HTMLAttributes<HTMLDivElement> & {
	data: any[]
	id: string
	min?: "dataMin" | number
	max?: "dataMax" | number
	secondaryData?: any[]
	color?: string | undefined
	height?: number | undefined
	minHeight?: number
	setValue?: (value: number | undefined) => void
	setLabel?: (label: string | undefined) => void
	value?: number
	label?: string
	showLegend?: boolean
	topLeft?: ReactNode | undefined
	topRight?: ReactNode | undefined
	bottomLeft?: ReactNode | undefined
	bottomRight?: ReactNode | undefined
	displayXAxis?: boolean
	margin?: {
		top: number
		right: number
		bottom: number
		left: number
	}
}

export const LineChart = ({
	data,
	id,
	secondaryData,
	color = "rgb(209, 178, 0)",
	value,
	label,
	setValue,
	min,
	max,
	setLabel,
	topLeft,
	topRight,
	margin = {
		top: 5,
		right: 0,
		left: 0,
		bottom: 0,
	},
	bottomLeft,
	showLegend,
	bottomRight,
	minHeight = 300,
	displayXAxis,
	height = 300,
	...rest
}: LineChartProps) => {
	const hasExpectedData = typeof data[0]?.expected !== "undefined"

	return (
		<div
			style={{ minHeight, height }}
			className="bg-primary flex w-full flex-col"
			onMouseLeave={() => {
				setLabel && setLabel(undefined)
				setValue && setValue(undefined)
			}}
			{...rest}
		>
			<ResponsiveContainer width="100%" height="100%">
				<AreaChart
					width={height * 1.66}
					height={height}
					data={data}
					margin={margin}
				>
					{showLegend ? (
						<Legend verticalAlign="top" height={36} />
					) : null}
					<defs>
						<linearGradient
							id={`${id}_gradient`}
							x1="0"
							y1="0"
							x2="0"
							y2="1"
						>
							<stop
								offset="5%"
								stopColor="rgb(255, 217, 1)"
								stopOpacity={1}
							/>
							<stop
								offset="100%"
								stopColor="rgb(255, 217, 0, 1)"
								stopOpacity={0}
							/>
						</linearGradient>
					</defs>
					{displayXAxis && (
						<XAxis
							dataKey="time"
							axisLine={false}
							tickLine={false}
							tickFormatter={(time) =>
								DateTime.fromJSDate(new Date(time)).toFormat(
									"DD",
								)
							}
							minTickGap={10}
						/>
					)}
					<Tooltip
						cursor={{
							stroke: "rgba(255, 217, 0, 1)",
							strokeWidth: 4,
						}}
						contentStyle={{ display: "none" }}
						// @todo formatter={} prop was removed because it made tooltip look sloppy. Probably that's why
						//  contentStyle={{ display: "none" }} is used making tooltip not visible at all.
						//  Other than that this formatter prop got new typescript signature so it should be updated
						//  in accordance with business requirements (if there were/will be any)
					/>
					<Area
						label="Value"
						name="Value"
						dataKey="value"
						type="monotone"
						stroke={color}
						fill={`url(#${id}_gradient)`}
						strokeWidth={4}
						min={min}
					/>
					{hasExpectedData ? (
						<Area
							name="Expected"
							label="Expected"
							dataKey="expected"
							type="monotone"
							stroke="#4e5155"
							fill="transparent"
							strokeWidth={4}
							min={min}
						/>
					) : null}
					{min && max ? <YAxis domain={[min, max]} hide /> : null}
				</AreaChart>
			</ResponsiveContainer>
		</div>
	)
}
