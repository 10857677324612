export const getPagination = <T extends any>(
	data: T[],
	displaySize: number,
	currentPage: number,
) => {
	if (data.length === 1) {
		return []
	}

	if (data.length < displaySize) {
		return data
	}

	// if currentPage is near the beginning, display the default
	if (currentPage < displaySize) {
		return [
			...data.slice(0, displaySize),
			{ key: "left" },
			...data.slice(data.length - 1),
		]
	}

	if (currentPage > data.length - displaySize) {
		return [
			...data.slice(0, 1),
			{ key: "right" },
			...data.slice(data.length - displaySize),
		]
	}

	const start = currentPage - Math.ceil(displaySize / 2)

	return [
		...data.slice(0, 1),
		{ key: "start" },
		...data.slice(start, start + displaySize),
		{ key: "end" },
		...data.slice(data.length - 1),
	]
}
