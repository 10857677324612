// State
export interface InvestmentsOverviewState {
	perPage: ProjectPageSize
	showHiddenProjects: Boolean
	hiddenIds: number[]
	sortingMethod: ProjectSortMethod
	sortingOrder: ProjectSortOrder
	filterType: ProjectTypeEnumFiltered
	filterState: ProjectStateEnumFiltered
	viewType: ProjectPageViewTypes
}

/**
 * Project state
 * TODO: Would be nice to use ProjectStateEnum from @/api/graphql but this breaks vitest?
 */
export const ProjectStateEnumFiltered = {
	BeingInstalled: "BEING_INSTALLED",
	FixedInterest: "FIXED_INTEREST",
	InProduction: "IN_PRODUCTION",
	MonitoringOffline: "MONITORING_OFFLINE",
	NoProduction: "NO_PRODUCTION",
	PaidOff: "PAID_OFF",
} as const
export type ProjectStateEnumFiltered =
	| (typeof ProjectStateEnumFiltered)[keyof typeof ProjectStateEnumFiltered]
	| "ALL"
export const ALL_PROJECT_STATES = "ALL" // Constant used to indicate 'all' states

/**
 * Project type
 * TODO: Would be nice to use ProjectTypeEnum from @/api/graphql but this breaks vitest?
 */
export const ProjectTypeEnumFiltered = {
	Portfolio: "PORTFOLIO",
	SolarBonds: "SOLAR_BONDS",
	SolarShares: "SOLAR_SHARES",
} as const
export type ProjectTypeEnumFiltered =
	| (typeof ProjectTypeEnumFiltered)[keyof typeof ProjectTypeEnumFiltered]
	| "ALL"
export const ALL_PROJECT_TYPES = "ALL" // Constant used to indicate 'all' types

export enum ProjectSortOrder {
	Asc = "asc",
	Desc = "desc",
}
export const projectSortOrder: ProjectSortOrder[] = [
	ProjectSortOrder.Asc,
	ProjectSortOrder.Desc,
]

/**
 * ProjectSortMethod
 * TODO: Add sorting on calculated field: investor_shares_value_stats__total_investment_for_project
 */
export enum ProjectSortMethod {
	Name = "name",
	Start = "start",
	End = "end",
	InterestPeriod = "interestperiod__end",
}
export const projectSortMethod: ProjectSortMethod[] = [
	ProjectSortMethod.Name,
	ProjectSortMethod.Start,
	ProjectSortMethod.End,
	ProjectSortMethod.InterestPeriod,
]
export enum ProjectPageViewTypes {
	Grid = "grid",
	Table = "table",
}
export const projectPageViewTypes: ProjectPageViewTypes[] = [
	ProjectPageViewTypes.Grid,
	ProjectPageViewTypes.Table,
]
export enum ProjectPageSize {
	Size6 = 6,
	Size24 = 24,
	Size50 = 50,
	Size100 = 100,
	Size200 = 200,
}
export const projectPageSizes: ProjectPageSize[] = [
	ProjectPageSize.Size6,
	ProjectPageSize.Size24,
	ProjectPageSize.Size50,
	ProjectPageSize.Size100,
	ProjectPageSize.Size200,
]
