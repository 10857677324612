import { useState } from "react"

export function useTableMetaData({
	initialFilter,
	initialSort,
	initialPage = 0,
	initialPageSize = 10,
}: {
	initialFilter?: string
	initialSort?: string
	initialPage?: number
	initialPageSize?: number
} = {}) {
	const [filter, setFilter] = useState(initialFilter)
	const [sort, setSort] = useState(initialSort)
	const [page, setPage] = useState(initialPage)
	const [pageSize, setPageSize] = useState(initialPageSize)

	return {
		filter,
		sort,
		page,
		pageSize,
		setFilter,
		setSort,
		setPage,
		setPageSize,
	}
}
