import { ReactNode, isValidElement } from "react"
import { useQueryErrorResetBoundary } from "@/lib/query"

// UI
import { ErrorState } from "./ErrorState"

// Sentry
import {
	ErrorBoundary as SentryErrorBoundary,
	ErrorBoundaryProps,
} from "@/lib/sentry"

/**
 * Error Boundary with custom children
 *
 * Note: In development mode, React will rethrow errors caught within an error boundary.
 * This will result in errors being reported twice to Sentry with the above setup, but this won’t occur in your production build.
 * DOCS: https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/
 *
 * Note: There is no direct equivalent for static getDerivedStateFromError in function components yet.
 * If you’d like to avoid creating class components, write a single ErrorBoundary component like above and use it throughout your app.
 * Alternatively, use the react-error-boundary package which does that.
 * DOCS: https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 *
 * Note: try/catch blocks won't catch errors inside hooks like useEffect and inside any children components
 * ErrorBoundary can catch them, but it won’t catch errors in async code and event handlers
 * Nevertheless, you can make ErrorBoundary catch those, you just need to catch them with try/catch first and then re-throw them back into the React lifecycle
 * DOCS: https://www.developerway.com/posts/how-to-handle-errors-in-react
 *
 * Note: Error boundaries do not catch errors for:
 * Event handlers (learn more)
 * Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
 * Server side rendering
 * Errors thrown in the error boundary itself (rather than its children)
 * DOCS: https://legacy.reactjs.org/docs/error-boundaries.html#how-about-event-handlers
 *
 * @param param0
 * @returns
 */
export function ErrorBoundary({ children, fallback }: ErrorBoundaryProps) {
	const { reset } = useQueryErrorResetBoundary()

	return (
		<SentryErrorBoundary
			onReset={reset}
			fallback={({ error, componentStack, resetError, eventId }) => {
				// This logic is derived from how @sentry/react/esm/errorboundary.js renders either types React.ReactElement | FallbackRender
				let element
				if (typeof fallback === "function") {
					element = fallback({
						error,
						componentStack,
						resetError,
						eventId,
					})
				} else {
					element = fallback
				}

				// If we have an valid element, return it
				if (isValidElement(element)) {
					return element
				}

				// Fallback expects always an React component
				return <></>
			}}
		>
			{children}
		</SentryErrorBoundary>
	)
}

// Show error Boundary with default error state (oops, something went wrong > retry button)
export function ErrorBoundaryWithErrorState({
	children,
	errorBoundaryClassName,
}: {
	children: ReactNode
	errorBoundaryClassName?: string
}) {
	return (
		<ErrorBoundary
			fallback={<ErrorState className={errorBoundaryClassName} />}
		>
			{children}
		</ErrorBoundary>
	)
}
