/** An enumeration. */
export const NetworkError = {
	NO_API_URL:
		"No API URL found. Check your environment variables to ensure that the API_URL has been set.",
	PARSE_JSON_BODY_ERROR:
		"Could not parse JSON data into body for content type application/json",
	PARSE_FORM_BODY_ERROR:
		"Could not parse formdata into body for content type multipart/form-data",
	UNKNOWN_RESPONSE: "Unknown response.",
	NO_EPHEMERAL_TOKEN: "Failed to authorise user, no ephemeral token found.",
	AUTHENTICATION_ERROR: "UserNotLoggedIn",
	NETWORK_ERROR: "Network Error",
	NETWORK_ERROR_RESOURCE: "NetworkError when attempting to fetch resource",
	FAILED_TO_FETCH: "Failed to fetch",
} as const
