import { useEffect, useRef } from "react"
import { useDebouncedState } from "@/hooks/useDebounce"
import { classNames } from "@/lib/classnames"

type Dimensions = { width: number; height: number }

export function ParentSize({
	children,
	className,
}: {
	className?: string
	children(dimensions: Dimensions): JSX.Element | null
}) {
	const ref = useRef<HTMLDivElement>(null!)
	const [dimensions, setDimensions] = useDebouncedState<Dimensions>(
		{
			width: 0,
			height: 0,
		},
		200,
	)

	useEffect(() => {
		function handler() {
			if (ref.current) {
				setDimensions(ref.current.getBoundingClientRect())
			}
		}
		window.addEventListener("resize", handler)
		handler()
		return () => {
			window.removeEventListener("resize", handler)
		}
	}, [setDimensions])

	return (
		<div ref={ref} className={classNames("h-full w-full", className)}>
			{children(dimensions)}
		</div>
	)
}
