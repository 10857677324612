/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * ZonHub
 * Project management for Solar
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `accept` - Accept
 * `reject` - Reject
 */
export type ActionEnum = (typeof ActionEnum)[keyof typeof ActionEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionEnum = {
	accept: "accept",
	reject: "reject",
} as const
