import { useEffect, useState } from "react"
import { shuffle } from "lodash"

const arbitraryValuesForBarChartImitation = [
	150, 80, 94, 83, 85, 94, 84, 93, 91, 60, 73, 84, 81, 91,
]

interface EmptyGridStateProps {
	className?: string
	itemClassName?: string
	animated?: boolean
}

export const EmptyGridState = ({
	className = "",
	itemClassName = "bg-gray-50",
	animated,
}: EmptyGridStateProps) => {
	const [values, setValues] = useState<number[]>(
		arbitraryValuesForBarChartImitation,
	)

	useEffect(() => {
		if (animated) {
			const interval = setInterval(() => {
				setValues(shuffle(values))
			}, 200)
			return () => clearInterval(interval)
		}
	}, [animated, values])

	return (
		<div className={`${className} flex items-end gap-4`}>
			{values.map((number, index) => (
				<div
					className={`${itemClassName} flex-grow`}
					style={{ height: `${number}%`, maxWidth: 20 }}
					key={`${number}-${index}`}
				/>
			))}
		</div>
	)
}

export const EmptyGridStateAnimated = ({
	className = "",
	itemClassName = "bg-gray-50",
}: EmptyGridStateProps) => {
	return (
		<div className="h-full p-10">
			<div className=" flex h-full w-full animate-pulse items-center justify-center bg-gray-100">
				<div className="h-full w-[50%]">
					<div className="flex h-full items-center justify-center">
						<EmptyGridState
							animated
							className={className}
							itemClassName={itemClassName}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
