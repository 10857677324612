import { useTrans } from "@/i18n"

// UI
import { Heading } from "../Typography"
import { useToasts } from "@/context/toasts"
import { CardBody, CardWrapper, CardFooter } from "../Card"
import { Tooltip } from "@/components/Tooltip"

// Icons
import { FiInfo } from "react-icons/fi"

// Forms
import { handleErrorWithFormik } from "@/lib/formik"
import { FormikProvider, useFormik } from "formik"
import {
	FormikErrors,
	FormikInput,
	FormikSubmitButton,
	FormGroup,
	FormikError,
	FormikLabel,
} from "../form-controls"

// GraphQL
import { useBankDetailsQuery, useInvestorIbanMutation } from "@/api/graphql"

/**
 * BankDetails
 * @returns
 */
export const BankDetails = () => {
	const t = useTrans("profile")
	const toasts = useToasts()
	const { data, refetch } = useBankDetailsQuery()

	const updatePersonalDetailsMutation = useInvestorIbanMutation({
		onSuccess: async (response) => {
			// map each error from graphql to the field it's related to
			for (const error of response?.investor_update?.errors ?? []) {
				if (error?.field) {
					form.setFieldError(error.field, error.messages[0])
				}
			}

			// do we have data? then success
			if (
				!response?.investor_update?.errors ||
				response.investor_update.errors?.length === 0
			) {
				toasts.addToast({
					variant: "success",
					id: `bank-details-success-${Date.now()}`,
					text: t("profile:profile.personal_details.success_message"),
				})
				await refetch()
			}
			// otherwise show a message that there are form errors
			else {
				form.setFieldError(
					"errors.common",
					"common.form_errors.attention_required",
				)
			}
		},
		onError: (error: Error) => {
			handleErrorWithFormik(error, form)
		},
	})

	// Formik form
	const form = useFormik({
		initialValues: {
			full_name: data?.me?.full_name,
			iban: "",
		},
		onSubmit: async (values) => {
			await updatePersonalDetailsMutation.mutateAsync({
				investor: {
					iban: values.iban,
				},
			})
		},
	})

	return (
		<CardWrapper>
			<FormikProvider value={form}>
				<form onSubmit={form.handleSubmit}>
					<CardBody>
						<Heading
							as="h2"
							styleAs="h5"
							className="mb-3 sm:truncate"
						>
							{t("profile:profile.bank_details.title")}
						</Heading>
						<FormGroup
							heading={t("profile:profile.bank_details.copy")}
						>
							<div
								className="w-fulls grid gap-6 2xl:grid-cols-2"
								data-testid="form-group"
							>
								{/** Name just as confirmation */}
								<div className="2xl:col-span-2">
									<FormikLabel
										htmlFor="full_name"
										className="flex"
									>
										<Tooltip
											content={
												<p>
													{t(
														"profile:profile.personal_details.name.tooltip",
													)}
												</p>
											}
										>
											<span className="flex items-center">
												{t(
													"profile:profile.personal_details.name.title",
												)}
												<FiInfo className="ml-2" />
											</span>
										</Tooltip>
									</FormikLabel>
									<FormikInput
										name="full_name"
										required
										className="mt-1 block w-full"
										readOnly
										disabled
										classNameWrapper="lg:w-1/2"
									/>
								</div>

								{/** Bank account number */}
								<div className="2xl:col-span-2">
									<div>
										<FormikLabel
											className="truncate"
											htmlFor="iban"
										>
											{t(
												"profile:profile.bank_details.account_number.title",
											)}
										</FormikLabel>
										<FormikInput
											placeholder={
												data?.me?.investor
													?.masked_iban ?? ""
											}
											name="iban"
											id="iban"
											aria-label="iban"
											className="block w-full"
											classNameWrapper="lg:w-1/2"
										/>
										<FormikError
											field="iban"
											namespace="profile"
										/>
									</div>

									{/** Errors */}
									<div>
										<FormikErrors i18nNamespace="profile" />
									</div>
								</div>
							</div>
						</FormGroup>
					</CardBody>
					<CardFooter className="text-right">
						<FormikSubmitButton>
							{t(
								"profile:profile.bank_details.form_actions.save",
							)}
						</FormikSubmitButton>
					</CardFooter>
				</form>
			</FormikProvider>
		</CardWrapper>
	)
}
