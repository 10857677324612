import { formatToDecimalPlaces } from "./math"

export const getKeyAndValueFromNumber = (
	number: number,
	formatter = formatToDecimalPlaces,
) => {
	let i18n = "common.numbers"
	let value: number

	if (number >= 1_000 && number < 1_000_000) {
		i18n = "common.numbers.thousand"
		value = formatter(number / 1_000)
	} else if (number >= 1_000_000 && number < 1_000_000_000) {
		i18n = "common.numbers.million"
		value = formatter(number / 1_000_000)
	} else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
		i18n = "common.numbers.billion"
		value = formatter(number / 1_000_000_000)
	} else if (number >= 1_000_000_000_000) {
		i18n = "common.numbers.trillion"
		value = formatter(number / 1_000_000_000_000)
	} else {
		value = formatter(number)
	}

	return { i18n, value }
}
