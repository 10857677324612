import { BadgeCheckIcon, ShieldExclamationIcon } from "@heroicons/react/outline"
import { motion } from "@/lib/animations"
import { FiX } from "react-icons/fi"
import { classNames } from "@/lib/classnames"

export interface ToastProps {
	text: string
	variant?: "success" | "alert"
	timing?: number
	onClose?(): void
}

export function Toast({ text, variant, timing, onClose }: ToastProps) {
	return (
		<div
			className={classNames(
				"relative flex items-center rounded bg-white px-4 py-5 shadow sm:p-6",
				onClose && "pr-12 sm:pr-12",
			)}
		>
			{variant === "success" && (
				<BadgeCheckIcon className="mr-2 w-6 flex-shrink-0 text-green-500 md:mr-4" />
			)}
			{variant === "alert" && (
				<ShieldExclamationIcon className="mr-2 w-6 flex-shrink-0 text-red-500 md:mr-4" />
			)}
			<p className="block text-sm text-gray-700">{text}</p>
			{timing ? (
				<motion.div
					className={classNames(
						"absolute bottom-0 left-0 h-1 w-full",
						variant === "alert" ? "bg-red-500" : "bg-green-500",
					)}
					initial={{ width: "0%" }}
					animate={{ width: "100%" }}
					transition={{
						duration: (timing / 1000) * 0.7,
						delay: (timing / 1000) * 0.3,
						ease: "linear",
					}}
				/>
			) : null}
			{onClose ? (
				<button
					type="button"
					onClick={onClose}
					className="absolute right-0 top-0 p-4 text-gray-700 hover:text-gray-500"
				>
					<FiX />
				</button>
			) : null}
		</div>
	)
}
