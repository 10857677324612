import { UserRoles } from "@/misc/constants"

// GraphQL
import {
	KycType,
	ProjectStateEnum,
	UsersUserProfileIdentityTypeChoices,
	useUserContextQuery,
} from "@/api/graphql"

// Sentry
import { setSentryUser } from "@/lib/sentry"

// Freshdesk
import { setFreshdeskUser } from "@/lib/feedback"

// Feature Flags
import { featureFlags } from "@/lib/featureFlags"

// Dates
import { transformTimezoneConstantToJSTimezone } from "@/lib/dates"

const roleMap: { [key: string]: UserRoles } = {
	is_superuser: UserRoles.admin,
	is_staff: UserRoles.staff,
	is_investor: UserRoles.investor,
	is_installer: UserRoles.installer,
	is_project_owner: UserRoles.projectOwner,
	is_energy_supplier: UserRoles.energyProvider,
}

export function useFeatureFlags() {
	function getFeatureFlagValue(name: string) {
		return Boolean(featureFlags.find((flag) => flag.name === name)?.value)
	}

	return {
		featureFlags,
		getFeatureFlagValue,
	}
}

// Use current user ID
export function useCurrentUserId() {
	const { data } = useUserContextQuery()
	return {
		id: data?.me?.id,
	}
}

// Use current user info
export function useCurrentUserInfo() {
	const { data } = useUserContextQuery()
	return {
		first_name: data?.me?.first_name || "",
		last_name: data?.me?.last_name || "",
	}
}

export function useCurrentUserRoles() {
	const { data } = useUserContextQuery()

	const userRoles = new Set<UserRoles>()

	if (data?.me?.roles_old) {
		for (const [role, value] of Object.entries(data.me.roles_old)) {
			if (value) {
				userRoles.add(roleMap[role])
			}
		}
	}

	// Set user_id for analytics
	if (data?.me?.id) {
		// Sentry
		setSentryUser({
			id: data.me.id,
			name: data.me.full_name || "",
			email: data.me.email,
		})

		setFreshdeskUser({
			name: data.me.full_name || "",
			email: data.me.email,
		})

		// Google Tag Manager
		if (typeof window.gtag !== "undefined") {
			window.gtag("set", {
				user_id: data.me.id,
			})
		}
	}

	function hasRole(role: UserRoles) {
		return userRoles.has(role)
	}

	function hasRoles(...roles: Array<UserRoles>) {
		return roles.every((role) => hasRole(role))
	}

	function hasOneOfRoles(...roles: Array<UserRoles>) {
		return roles.find((role) => hasRole(role))
	}

	return {
		userRoles,
		hasRoles,
		hasRole,
		hasOneOfRoles,
	}
}

export function useCurrentUserIdentityType() {
	const { data } = useUserContextQuery()
	return data?.me?.profile
		?.identity_type as UsersUserProfileIdentityTypeChoices
}

// Return current user kyc info
export function useCurrentUserKyc() {
	const { data } = useUserContextQuery()
	return data?.me?.kyc as KycType
}

// Get the current users language and timezone
export function useCurrentUserLocale() {
	const { data } = useUserContextQuery()
	return {
		language: data?.me?.profile?.language,
		timezone: transformTimezoneConstantToJSTimezone(
			data?.me?.profile?.timezone,
		),
	}
}

// User messages count
export function useCurrentUserMessagesCount() {
	const { data } = useUserContextQuery()
	return data?.me?.messages?.list?.total_count || 0
}

// Users payouts count
export function useCurrentUserPayoutsCount() {
	const { data } = useUserContextQuery()
	return data?.me?.payment_entries?.totalCount || 0
}

// Users Provisional Investments
export function useCurrentUserProvisonalInvestmentsCount() {
	const { data } = useUserContextQuery()
	return data?.me?.provisional_investments?.totalCount || 0
}

// Users Investments
export function useCurrentUserInvestmentsCount() {
	const { data } = useUserContextQuery()
	return data?.me?.investment_projects?.totalCount || 0
}

// Count Users Investments who have InProduction state
export function useCurrentUserInvestmentsInProductionCount() {
	const { data } = useUserContextQuery()
	return (
		data?.me?.investment_projects?.results?.filter(
			(project) => project?.state === ProjectStateEnum.InProduction,
		)?.length || 0
	)
}
