import { Trans } from "react-i18next"
import { TableInstance } from "react-table"
import { useWindowWidth } from "@/hooks/useWindowWidth"
import { useTrans } from "@/i18n"
import { classNames } from "@/lib/classnames"
import { getPagination } from "@/lib/pagination"
import { Button } from "../Button"
import { TableSetPageSize } from "./TableSetPageSize"

interface TablePaginationProps
	extends Pick<
		TableInstance,
		| "setPageSize"
		| "gotoPage"
		| "nextPage"
		| "canNextPage"
		| "canPreviousPage"
		| "previousPage"
		| "pageSize"
		| "pageIndex"
	> {
	amountOfRows: number
	totalNumberOfItems?: number
}

export const TablePagination = ({
	pageIndex,
	pageSize,
	previousPage,
	gotoPage,
	canPreviousPage,
	canNextPage,
	nextPage,
	amountOfRows,
	totalNumberOfItems,
	setPageSize,
}: TablePaginationProps) => {
	const t = useTrans("common")
	const width = useWindowWidth()

	const paginationItems = getPagination(
		Array(Math.ceil(amountOfRows / pageSize))
			.fill(true)
			.map((_, index) => index),
		width < 1200 ? 2 : 3,
		pageIndex + 1,
	)

	return (
		<div
			className="flex-1 sm:items-center md:flex md:justify-between"
			data-testid="tablepagination"
		>
			<div className="flex items-center justify-between">
				<p className="mr-3 text-sm text-gray-700">
					<Trans
						ns="common"
						i18nKey="common.pagination.showing_results"
						values={{
							from: pageIndex * pageSize + 1,
							// using Math.min as "(pageIndex + 1) * pageSize" gets us the amount
							// of possible items, which might be greater than the amount of items total
							to: Math.min(
								(pageIndex + 1) * pageSize,
								amountOfRows,
							),
							results: totalNumberOfItems ?? amountOfRows,
						}}
						components={{
							strong: <span className="font-medium" />,
						}}
					/>
				</p>
				<TableSetPageSize
					onPageSize={(page) => {
						setPageSize(page)
						gotoPage(0)
					}}
					pageSize={pageSize}
				/>
			</div>
			{paginationItems.length > 0 && (
				<>
					<div className="mt-3 flex flex-1 justify-between md:hidden">
						<Button
							variant="transparent"
							onClick={previousPage}
							disabled={!canPreviousPage}
						>
							{t("common.pagination.prev")}
						</Button>
						<Button
							variant="transparent"
							onClick={nextPage}
							disabled={!canNextPage}
						>
							{t("common.pagination.next")}
						</Button>
					</div>
					<nav
						className="relative z-0 hidden items-center md:inline-flex"
						aria-label={t("common.pagination.title")}
					>
						<Button
							variant="empty"
							className="mr-1 xl:mr-2"
							onClick={previousPage}
							disabled={!canPreviousPage}
							data-testid="common.pagination.prev"
						>
							{t("common.pagination.prev")}
						</Button>
						{paginationItems.map((item, index) => {
							if (typeof item === "number") {
								const firstInRow =
									typeof paginationItems[index - 1] !==
									"number"
								const lastInRow =
									typeof paginationItems[index + 1] !==
									"number"

								return (
									<Button
										key={`${item}.${index}`}
										variant={
											item === pageIndex
												? "primary"
												: "transparent"
										}
										className={classNames(
											"relative",
											!lastInRow &&
												!firstInRow &&
												"rounded-l-none rounded-r-none",
											lastInRow &&
												!firstInRow &&
												"rounded-l-none",
											firstInRow &&
												!lastInRow &&
												"rounded-r-none",
											// zindex is because when you focus on the button
											// the focus ring is hidden behind the button next to it.
											// so this forces the current button to always be on top.
											item === pageIndex
												? "z-20"
												: "z-10",
										)}
										onClick={() => gotoPage(item)}
										role="button"
										data-testid={`pagination-gotopage-${
											item + 1
										}`}
									>
										{item + 1}
									</Button>
								)
							}

							return (
								<p
									key={`${item.key}.${index}`}
									className="px-4 text-gray-500"
								>
									{t("common.pagination.delimiter")}
								</p>
							)
						})}
						<Button
							variant="empty"
							className="ml-1 xl:ml-2"
							onClick={nextPage}
							disabled={!canNextPage}
							data-testid="common.pagination.next"
						>
							{t("common.pagination.next")}
						</Button>
					</nav>
				</>
			)}
		</div>
	)
}
