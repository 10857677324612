import { useTrans } from "@/i18n"
import { sendEvent } from "@/lib/analytics"
import { callAll } from "@/lib/js"
import { Button } from "./Button"

interface PaginationAsButtonsProps {
	onNextPage(): void
	onPrevPage(): void
	currentItemsAmount: number
	totalCount?: number
	itemType?: string
	currentPage: number
	countPerPage?: number
	canGoPrev?: boolean
	canGoNext?: boolean
	analyticsId: string
	hideText?: boolean
}

export function PaginationAsButtons({
	totalCount,
	itemType = "common.pagination.item_types.generic",
	currentItemsAmount,
	currentPage,
	onNextPage,
	onPrevPage,
	countPerPage,
	canGoNext,
	canGoPrev,
	analyticsId,
	hideText = false,
}: PaginationAsButtonsProps) {
	// Translations
	const t = useTrans("common")

	// Pagination logic
	let totalPages = 1
	if (countPerPage) {
		totalPages = totalCount ? Math.ceil(totalCount / countPerPage) : 1
		canGoNext = currentPage < totalPages
		canGoPrev = currentPage > 1
	}

	return (
		<div className="grid w-full grid-cols-2 items-center justify-between gap-y-3 md:grid-cols-[1fr_auto_auto] md:gap-x-4 md:gap-y-0">
			{/** Show text */}
			{hideText === false && (
				<p className="col-span-2 text-left text-sm text-gray-500 md:order-1 md:col-span-1">
					{/** If we have totalCount, show totalCount else show default text */}
					{totalCount !== undefined
						? t("common.pagination.copy_other", {
								count: currentItemsAmount,
								total: totalCount,
								type: t(itemType, {
									count: totalCount,
								}),
						  })
						: t("common.pagination.copy", {
								count: currentItemsAmount,
						  })}

					<span className="block">
						{t("common.pagination.on_page", {
							count: currentPage,
							total: totalPages,
						})}
					</span>
				</p>
			)}

			<Button
				variant="primary"
				disabled={!canGoPrev}
				onClick={callAll(onPrevPage, () => {
					sendEvent("pagination", "prev_onclick", {
						label: analyticsId,
					})
					sendEvent(analyticsId, "prev_onclick")
				})}
				className="justify-self-start md:order-2"
			>
				{t("common.pagination.prev")}
			</Button>
			<Button
				variant="primary"
				disabled={!canGoNext}
				onClick={callAll(onNextPage, () => {
					sendEvent("pagination", "next_onclick", {
						label: analyticsId,
					})
					sendEvent(analyticsId, "next_onclick")
				})}
				className="justify-self-end md:order-3"
			>
				{t("common.pagination.next")}
			</Button>
		</div>
	)
}

export function PaginationAsButtonsLoadingState() {
	const t = useTrans("common")

	return (
		<div className="grid w-full grid-cols-2 items-center justify-between gap-y-3 md:grid-cols-[1fr_auto_auto] md:gap-x-4 md:gap-y-0">
			<p className="col-span-2 text-left text-sm font-bold text-gray-500 md:order-1 md:col-span-1">
				<span className="dummy-text block w-20 animate-pulse rounded-md bg-gray-200"></span>
				<span className="dummy-text block w-16 animate-pulse rounded-md bg-gray-200" />
			</p>
			<Button
				disabled
				className="animate-pulse justify-self-start  md:order-2"
			>
				<span className="opacity-0">{t("common.pagination.prev")}</span>
			</Button>
			<Button
				disabled
				className="animate-pulse justify-self-end  md:order-3"
			>
				<span className="opacity-0">{t("common.pagination.next")}</span>
			</Button>
		</div>
	)
}
