import { DateTime } from "@/lib/dates"
import { Helmet } from "@/lib/seo"
import { CardBody, CardWrapper } from "@/components/Card"
import { TimelineItem, TimelineWrapper } from "@/components/Timeline"
import { Heading } from "@/components/Typography"
import { useTrans } from "@/i18n"
import { useCurrentProject } from "../hooks"

export const ProjectCommunications = () => {
	const t = useTrans(["common", "project"])
	const { data } = useCurrentProject()

	const now = DateTime.local()
	const timeline = [
		{
			type: "status",
			content: t("project:project.communications.timeline.status", {
				projectName: `<bold>Solarpaark Aadijk</bold>`,
				status: `<pill variant="IN_FUNDING">producing</pill>`,
			}),
			date: now.minus({ seconds: 55 }),
		},
		{
			type: "investment",
			content: `<bold>€ 50,-</bold> invested into <bold>Solarpaark Aadijk</bold>`,
			date: now.minus({ minutes: 30 }),
		},
		{
			type: "project",
			content: `
				<heading>Solarpaark Aadijk</heading>
				<sub>${t("common:common.project_started", { date: "7d ago" })}</sub>
				<p>Solarpaark Aadijk is goed voor het opwekken van groene stroom voor meer dan 10.000 huishoudens, oftewel één derde van Almelo.</p>
			`,
			date: now.minus({ days: 9 }),
		},
	]

	return (
		<>
			<Helmet>
				<title>
					{t("project:project.communications.page_title", {
						projectName: data?.project?.name,
					})}
				</title>
			</Helmet>
			<CardWrapper>
				<CardBody>
					<Heading as="h5" className="mb-3 sm:truncate">
						{t("project:project.communications.heading")}
					</Heading>
					<div className="grid-cols-2-1 grid space-y-6 md:gap-6 md:space-y-0">
						<p
							className="text-sm text-gray-500 xl:w-3/4"
							data-testid="project.communications.copy"
						>
							{t("project:project.communications.copy", {
								projectName: data?.project?.name,
							})}
						</p>
						<div>
							<TimelineWrapper>
								{timeline.map((item, index) => (
									<TimelineItem
										key={`${item.content}-${index}`}
										type={item.type}
										content={item.content}
										date={item.date}
										position={
											index === timeline.length - 1
												? "end"
												: undefined
										}
									/>
								))}
							</TimelineWrapper>
						</div>
					</div>
				</CardBody>
			</CardWrapper>
		</>
	)
}
