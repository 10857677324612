import { useState } from "react"

// UI
import { LazyLoadImage as BaseLazyLoadImage } from "react-lazy-load-image-component"
import { ImagePlaceholder } from "./ImagePlaceholder"

interface LazyLoadImageProps {
	className: string
	src: string
	alt: string
}

/**
 * LazyLoadImage
 *
 * @param param0
 * @returns
 */
export function LazyLoadImage({ className, src, alt }: LazyLoadImageProps) {
	const [imageError, setImageError] = useState(false)

	const handleImageError = () => {
		setImageError(true)
	}

	return (
		<div className="relative h-48 w-full overflow-hidden rounded bg-gray-300 dark:bg-gray-700">
			{imageError || !src ? (
				<ImagePlaceholder className={className} />
			) : (
				<BaseLazyLoadImage
					className={className}
					src={src}
					alt={alt}
					onError={handleImageError}
				/>
			)}
		</div>
	)
}
