import { ReactNode } from "react"

interface SingleScreenLayoutProps {
	content: ReactNode
}

export const SingleScreenLayout = ({ content }: SingleScreenLayoutProps) => {
	return (
		<div className="flex h-full items-center justify-center bg-white px-4">
			<div className="mx-auto w-full max-w-sm lg:w-96">{content}</div>
		</div>
	)
}
