import { useContext } from "react"

// Navigation
import { Pages } from "@/misc/pages"
import { useLocation } from "@/lib/router"

// Context
import { MainLayoutContext } from "@/layouts/MainLayout"

// UI
import { classNames } from "@/lib/classnames"
import { Link } from "@/components/Anchor"

// Translation
import { useTrans } from "@/i18n"

/**
 * StatsSubMenu
 * @returns
 */
export function StatsSubMenu() {
	// Router
	const location = useLocation()

	const { setMobileMenuOpen } = useContext(MainLayoutContext)
	const t = useTrans(["common", "project"])

	function closeMenu() {
		setMobileMenuOpen(false)
	}

	return (
		<nav className="space-y-1 pr-3">
			<Link
				className={classNames(
					location.pathname === Pages.StatisticsPlatform &&
						"active-navigation-item",
					"block truncate text-sm hover:text-gray-500",
				)}
				href={Pages.StatisticsPlatform}
				onClick={closeMenu}
			>
				{t("common:common.navigation.statistics.platform.link")}
			</Link>
			<Link
				className={classNames(
					location.pathname === Pages.StatisticsPlatformEndex &&
						"active-navigation-item",
					"block truncate text-sm hover:text-gray-500",
				)}
				href={Pages.StatisticsPlatformEndex}
				onClick={closeMenu}
			>
				{t("common:common.navigation.statistics.platform.endex.link")}
			</Link>
		</nav>
	)
}
