// React
import { useMemo, ReactNode } from "react"

// Translations
import { useLang } from "@/context/lang"
import { useTrans } from "@/i18n"

// UI
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"

// Misc
import { useTableMetaData } from "@/hooks/useTableMetaData"

// Types
import { FiscalOverviewType } from "@/api/graphql"
type Columns<FiscalOverviewType> = Array<{
	title: string
	sort?: string
	accessor: (data: FiscalOverviewType) => ReactNode
	className?: string
}>
interface FinanceBondsLoanByYearTableProps {
	fiscalOverview?: Array<{
		__typename?: "FiscalOverviewType"
		year?: string | null
		value_start?: string | null
		value_end?: string | null
	} | null> | null
}

/**
 * FinanceBondsLoanByYearTable
 * @returns
 */
export function FinanceBondsLoanByYearTable({
	fiscalOverview,
}: FinanceBondsLoanByYearTableProps) {
	// Translate
	const { formatCurrency } = useLang()
	const t = useTrans("finance")

	const { setSort, sort } = useTableMetaData()

	const rows = useMemo(() => {
		return fiscalOverview
	}, [fiscalOverview])

	const columns: Columns<FiscalOverviewType> = [
		{
			title: t(
				"finance:finance.bonds-loan.block.fiscal-overview.header.year",
			),
			accessor: (data) => data.year,
		},
		{
			title: t(
				"finance:finance.bonds-loan.block.fiscal-overview.header.start",
			),
			accessor: (data) => formatCurrency(Number(data.value_start) || 0),
		},
		{
			title: t(
				"finance:finance.bonds-loan.block.fiscal-overview.header.end",
			),
			accessor: (data) => formatCurrency(Number(data.value_end) || 0),
		},
	]

	// Template
	return (
		<>
			<Table className="min-w-[48rem] lg:min-w-0">
				{/* table header */}
				<TableHead>
					<tr role="row">
						{columns.map((header) => {
							const isSorted =
								header.sort && sort?.endsWith(header.sort)
							const isSortedDesc =
								isSorted && sort?.startsWith("-")

							return (
								<TableHeading
									as="th"
									key={header.title}
									colSpan={1}
									variant={
										header.sort ? "sortable" : "static"
									}
									role="columnheader"
									title={header.title}
									isSorted={Boolean(isSorted)}
									isSortedDesc={Boolean(isSortedDesc)}
									onClick={() => {
										if (!header.sort) {
											return
										}

										if (!isSorted && !isSortedDesc) {
											setSort(`-${header.sort}`)
										} else if (isSortedDesc) {
											setSort(header.sort)
										} else {
											setSort("")
										}
									}}
								>
									{header.title}
								</TableHeading>
							)
						})}
					</tr>
				</TableHead>
				{/* table body and table cells */}
				<TableBody role="rowgroup" data-testid="tablebody">
					{rows?.map(({ ...row }, index) => {
						return (
							<TableRowCell
								key={row.year}
								isOdd={index % 2 === 0}
								role="row"
							>
								{columns.map((column, colIndex) => (
									<TableDataCell
										key={column.title}
										className="break-word whitespace-pre-wrap"
									>
										{/* Hide the the second column (value_start) of the last row */}
										{index === rows.length - 1 &&
										colIndex === 1
											? "-"
											: column.accessor(row)}
									</TableDataCell>
								))}
							</TableRowCell>
						)
					})}
				</TableBody>
				{rows && rows.length > 0 ? (
					<tfoot>
						<tr>
							{columns.map((column) => {
								return <TableDataCell key={column.title} />
							})}
						</tr>
					</tfoot>
				) : null}
			</Table>
		</>
	)
}
