import { ReactNode, HTMLProps, useEffect, useRef, useState } from "react"
import { FiChevronDown, FiChevronUp } from "react-icons/fi"
import { classNames } from "@/lib/classnames"
import { Subheading } from "../Typography"

interface TableProps extends HTMLProps<HTMLTableElement> {
	children: ReactNode
	layout?: "fixed" | "auto"
}

export const Table = ({
	children,
	className,
	layout = "fixed",
	...rest
}: TableProps) => {
	const [state, set] = useState<"none" | "right" | "left" | "both">("right")
	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const handler = ref.current?.addEventListener("scroll", (evt) => {
			if (evt.currentTarget instanceof HTMLDivElement) {
				const scrollWidth = evt.currentTarget.scrollWidth
				const scrollLeft = evt.currentTarget.scrollLeft
				const width = evt.currentTarget.clientWidth

				if (scrollWidth - width === scrollLeft) {
					set("left")
				} else if (scrollLeft === 0) {
					set("right")
				} else if (scrollWidth === width) {
					set("none")
				} else {
					set("both")
				}
			}
		})
		return handler
	}, [set])

	return (
		<div
			className={classNames(
				state === "right" && "content-hint-left-hidden",
				state === "left" && "content-hint-right-hidden",
				"content-hint",
			)}
		>
			<div className="w-full overflow-y-auto lg:overflow-auto" ref={ref}>
				<table
					role="table"
					{...rest}
					className={classNames(
						className,
						"w-full",
						layout === "fixed" && "2xl:table-fixed",
					)}
				>
					{children}
				</table>
			</div>
		</div>
	)
}

export const TableBody = ({
	className,
	...props
}: HTMLProps<HTMLTableSectionElement>) => {
	return (
		<tbody
			className={classNames(className, "border-b border-gray-200")}
			{...props}
		/>
	)
}

export const TableHead = ({
	...props
}: Omit<HTMLProps<HTMLTableSectionElement>, "className">) => {
	return (
		<thead
			className="sticky top-0 border-b border-t border-gray-200 border-t-gray-100 bg-gray-50"
			{...props}
		/>
	)
}

interface TableHeadingProps extends HTMLProps<HTMLTableHeaderCellElement> {
	isSorted?: boolean
	isSortedDesc?: boolean
}

export type TableDataCellProps = HTMLProps<HTMLTableDataCellElement>

export const TableDataCell = ({
	className = "",
	...props
}: TableDataCellProps) => {
	return (
		<td
			className={classNames(
				"truncate whitespace-nowrap px-6 py-4 text-sm text-gray-700",
				className,
			)}
			{...props}
		/>
	)
}

/**
 * TableRowCell
 * @param param0
 * @returns
 */
interface TableRowCellProps extends HTMLProps<HTMLTableRowElement> {
	isOdd?: boolean
	variant?: TableRowCellVariant
	withHover?: boolean
}
export type TableRowCellVariant = "neutral" | "valid" | "invalid"
export const TableRowCell = ({
	isOdd,
	variant = "neutral",
	withHover = true,
	className = "",
	...props
}: TableRowCellProps) => {
	const applyEvenOrOddstyles = {
		neutral: isOdd ? "bg-gray-50" : "bg-white",
		invalid: isOdd ? "bg-red-200" : "bg-red-100",
		valid: isOdd ? "bg-green-200" : "bg-green-100",
	}

	return (
		<tr
			className={classNames(
				applyEvenOrOddstyles[variant],

				withHover && "hover:bg-gray-100",
				className,
			)}
			{...props}
		/>
	)
}

interface TableHeadingProps extends HTMLProps<HTMLTableHeaderCellElement> {
	isSorted?: boolean
	isSortedDesc?: boolean
	// "sortable" includes hover styles
	variant?: "static" | "sortable"
	as?: "td" | "th"
}

export const TableHeading = ({
	children,
	isSorted,
	isSortedDesc,
	variant = "sortable",
	className = "",
	as: As = "td",
	...rest
}: TableHeadingProps) => {
	return (
		<As
			{...rest}
			className={classNames(
				"px-6 py-3 text-left",
				className,
				variant === "sortable" && "cursor-pointer hover:bg-gray-200",
			)}
		>
			<Subheading className="flex items-center">
				{children}
				{isSorted ? (
					isSortedDesc ? (
						<FiChevronDown
							className="ml-2"
							size={16}
							aria-hidden="true"
						/>
					) : (
						<FiChevronUp
							className="ml-2"
							size={16}
							aria-hidden="true"
						/>
					)
				) : null}
			</Subheading>
		</As>
	)
}

export const TableFooter = ({
	className,
	...rest
}: HTMLProps<HTMLTableSectionElement>) => {
	return (
		<tfoot
			className={classNames(
				className,
				"border-b border-gray-200 bg-gray-50 text-sm",
			)}
			{...rest}
		/>
	)
}
