import { useState, useEffect, Suspense } from "react"

// SEO
import { Helmet } from "@/lib/seo"

// DateTime
import { ms } from "@/lib/dates"

// UI
import { PageTemplate } from "@/templates/PageTemplate"
import { StatsMenuTabs } from "../_components/StatsMenuTabs"
import { Card } from "@/components/Card"
import { Odometer } from "@/components/Odometer"
import { Heading } from "@/components/Typography"
import { GlobalOverview } from "@/components/GlobalOverview"
import { LifetimeProductionChart } from "../_components/LifetimeProductionChart"
import {
	ProjectStatsTable,
	ProjectStatsTableLoading,
} from "../_components/ProjectStatsTable"
import { InvestmentOppertunityBanner } from "@/components/banners/InvestmentOppertunityBanner"

// Translations
import { useTrans } from "@/i18n"

// Queries
import {
	useAllPlatformStatisticsTotalProductionQuery,
	useAllPlatformStatisticsQuery,
	useAllPlatformStatisticsAdditionalQuery,
} from "@/api/graphql"

// Context
import { useAuth, AuthStateStatusEnum } from "@/context/auth"
import { useFeatureFlags } from "@/context/user"

// Utils
import { useLang } from "@/context/lang"

/**
 * AllProjectStats
 * @param param0
 * @returns
 */
export function Dashboard() {
	// Context
	const auth = useAuth()
	const { getFeatureFlagValue } = useFeatureFlags()

	// Translations
	const { formatCurrency, formatNumber } = useLang()
	const t = useTrans(["dashboard", "investments"])

	/**
	 * Fetch data
	 * TODO: Two statistics are still hardcoded! please calculate these in the backend api
	 */
	const { data } = useAllPlatformStatisticsQuery()
	const manualStatSuccessfulFundPercentage = 100
	const manualStatTotalFinancedAmount = 119664129

	return (
		<>
			<Helmet>
				<title>{t("dashboard:dashboard.title")}</title>
			</Helmet>
			<PageTemplate tabs={<StatsMenuTabs />}>
				{/** If logged in, show investment oppertunities */}
				{auth.status === AuthStateStatusEnum.LOGGED_IN ? (
					<InvestmentOppertunityBanner />
				) : null}

				<div className="mb-8 mt-8 flex justify-center gap-4">
					<div>
						<Heading as="h5" className="mb-2">
							{t(
								"dashboard:dashboard.stats.platform_stats.kwh.title",
							)}
						</Heading>

						<Suspense
							fallback={
								<span className="dummy-text block w-full animate-pulse rounded-md bg-gray-200"></span>
							}
						>
							<OdometerContainerAllProjects />
						</Suspense>
					</div>
				</div>
				{getFeatureFlagValue("ENABLE_STATS_ADDITIONAL") === true ? (
					<div className="mb-4">
						<GlobalOverview />
					</div>
				) : null}

				<div className="mb-4 grid grid-cols-1 gap-8 md:grid-cols-3">
					{/* Manual stat: Successful fund percentage */}
					<OverallStatsBlock
						title={t(
							"dashboard:dashboard.stats.platform_stats.blocks.successful_fund_percentage",
						)}
						stats={`${manualStatSuccessfulFundPercentage}%`}
					/>

					{/* Manual stat: Total finance amount */}
					<OverallStatsBlock
						title={t(
							"dashboard:dashboard.stats.platform_stats.blocks.total_financed_amount",
						)}
						stats={formatCurrency(manualStatTotalFinancedAmount)}
					/>

					{/* total_investors Stat Block */}
					<OverallStatsBlock
						title={t(
							"dashboard:dashboard.stats.platform_stats.blocks.total_investors",
						)}
						stats={`${formatNumber(
							data?.platform_statistics?.total_investors || 0,
						)}`}
					/>

					{/* repeated_investors percentage Stat Block */}
					<OverallStatsBlock
						title={t(
							"dashboard:dashboard.stats.platform_stats.blocks.percentage_of_project_wise_repeated_investors",
						)}
						stats={`${data?.platform_statistics?.percentage_of_project_wise_repeated_investors?.toFixed(
							2,
						)}%`}
					/>

					{/* saved_co2 */}
					<OverallStatsBlock
						title={t(
							"dashboard:dashboard.stats.platform_stats.blocks.saved_co2",
						)}
						stats={`${formatNumber(
							data?.platform_statistics?.saved_co2 || 0,
						)} kg`}
					/>

					{/* Solar panels Stat Block */}
					<OverallStatsBlock
						title={t(
							"dashboard:dashboard.stats.platform_stats.blocks.total_solar_panels",
						)}
						stats={`${formatNumber(
							data?.platform_statistics?.total_solar_panels || 0,
						)}`}
					/>

					{getFeatureFlagValue("ENABLE_STATS_ADDITIONAL") ===
						true && (
						<Suspense>
							<AdditionalStatsComponent />
						</Suspense>
					)}
				</div>
				<Suspense>
					<LifetimeProductionChart />
				</Suspense>
				<Suspense fallback={<ProjectStatsTableLoading />}>
					<ProjectStatsTable />
				</Suspense>
			</PageTemplate>
		</>
	)
}

/**
 * AdditionalStatsComponent
 * @returns
 */
function AdditionalStatsComponent() {
	const t = useTrans("dashboard")

	const { data } = useAllPlatformStatisticsAdditionalQuery()
	return (
		<>
			{/* Avg_investment_per_investor Stat Block */}
			<OverallStatsBlock
				title={t(
					"dashboard:dashboard.stats.platform_stats.blocks.avg_investment_per_investor",
				)}
				stats={`€ ${data?.platform_statistics?.avg_investment_per_investor?.toLocaleString()}`}
			/>

			{/* capacity_in_mwp Stat Block */}
			<OverallStatsBlock
				title={t(
					"dashboard:dashboard.stats.platform_stats.blocks.capacity_in_mwp",
				)}
				stats={`${data?.platform_statistics?.capacity_in_mwp?.toLocaleString()} MWp`}
			/>

			{/* Maximum investment Stat Block */}
			<OverallStatsBlock
				title={t(
					"dashboard:dashboard.stats.platform_stats.blocks.max_investment",
				)}
				stats={`€ ${data?.platform_statistics?.max_investment?.toLocaleString()}`}
			/>

			{/* project_partners Stat Block */}
			<OverallStatsBlock
				title={t(
					"dashboard:dashboard.stats.platform_stats.blocks.project_partners",
				)}
				stats={`${data?.platform_statistics?.project_partners?.toLocaleString()}`}
			/>

			{/* total_projects Stat Block */}
			<OverallStatsBlock
				title={t(
					"dashboard:dashboard.stats.platform_stats.blocks.total_projects",
				)}
				stats={`${data?.platform_statistics?.total_projects?.toLocaleString()}`}
			/>

			{/* households_usage Stat Block */}
			<OverallStatsBlock
				title={t(
					"dashboard:dashboard.stats.platform_stats.blocks.total_production_in_households_usage",
				)}
				stats={`${data?.platform_statistics?.total_production_in_households_usage?.toLocaleString()}`}
			/>

			{/* Investment Stat Block */}
			<OverallStatsBlock
				title={t(
					"dashboard:dashboard.stats.platform_stats.blocks.total_amount_invested",
				)}
				stats={`€ ${data?.platform_statistics?.total_amount_invested?.toLocaleString()}`}
			/>

			{/* solar_bonds_volume Stat Block */}
			<OverallStatsBlock
				title={t(
					"dashboard:dashboard.stats.platform_stats.blocks.solar_bonds_volume",
				)}
				stats={`${data?.platform_statistics?.solar_bonds_volume?.toLocaleString()}`}
			/>
		</>
	)
}

function OdometerContainerAllProjects() {
	// Config
	const timeTillRenderInSeconds = 10

	const { data } = useAllPlatformStatisticsTotalProductionQuery()
	const [value, set] = useState(
		data?.overall_statistics?.total_production_for_counter
			? parseFloat(data.overall_statistics.total_production_for_counter) /
					1000 / // WH > KWH
					1000 // KWH to MWH
			: 0,
	)

	useEffect(() => {
		function work() {
			set((value) => {
				const currentProductionSpeed = data?.overall_statistics
					?.production_speed
					? ((parseFloat(
							data.overall_statistics.production_speed, // This is watts per 250ms?
					  ) *
							4) / // Watt/second
							1000 / // Ws > KWs
							1000) * // KWs to MWs
					  timeTillRenderInSeconds
					: 0
				return value + currentProductionSpeed
			})
		}
		// 10 seconds re-render
		const timer = setInterval(work, ms(`${timeTillRenderInSeconds}s`))
		work()
		return () => {
			clearInterval(timer)
		}
	}, [data?.overall_statistics?.production_speed])

	return (
		<Odometer
			value={value}
			format="d"
			duration={timeTillRenderInSeconds * 1000}
			auto={false}
			theme="mijnstroom"
		/>
	)
}

/**
 * OverallStatsBlock
 * @param param0
 * @returns
 */
function OverallStatsBlock({ title, stats }: { title: string; stats: string }) {
	return (
		<Card className="text-center">
			<Heading as="h3" className="text-brandgray-700">
				{stats}
			</Heading>
			<div className="text-gray-400">{title}</div>
		</Card>
	)
}
