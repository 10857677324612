import { useCallback, useMemo } from "react"
import { Text } from "@visx/text"

import { Heading } from "@/components/Typography"

import { useFinanceGraphProductionQuery } from "@/api/graphql"

import { getKeyAndValueFromNumber } from "@/lib/i18n"
import { useCurrentFinanceProjectId } from "../hooks/useCurrentFinanceProjectId"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// DateTime
import { DateTime } from "@/lib/dates"
import { useCurrentDate } from "@/hooks/useCurrentDate"

// Graphs
import { trimArrayDatesAfterDate } from "@/components/graphs/lib/data"
import { useProductionGraphContext } from "@/components/graphs/types/bar/ProductionGraph"
import { MultiGraph } from "@/components/graphs/types/bar-line/MultiGraph"

const dates = [
	"common.date.january_short",
	"common.date.feburary_short",
	"common.date.march_short",
	"common.date.april_short",
	"common.date.may_short",
	"common.date.june_short",
	"common.date.july_short",
	"common.date.august_short",
	"common.date.september_short",
	"common.date.october_short",
	"common.date.november_short",
	"common.date.december_short",
]

const dates_long = [
	"common.date.january_long",
	"common.date.feburary_long",
	"common.date.march_long",
	"common.date.april_long",
	"common.date.may_long",
	"common.date.june_long",
	"common.date.july_long",
	"common.date.august_long",
	"common.date.september_long",
	"common.date.october_long",
	"common.date.november_long",
	"common.date.december_long",
]

// exported for use in header
export function useFinanceGraphProductionData() {
	const id = useCurrentFinanceProjectId()
	const { graphInterval, startTime, endTime, knmiDataStatus } =
		useProductionGraphContext()

	// Query Finance Graph Production
	return useFinanceGraphProductionQuery({
		startTime,
		endTime,
		interval: graphInterval,
		id: String(id),
		knmiDataIncluded: knmiDataStatus === "included",
	})
}

// what is going on here?
// problem: the performance ratio graph looks odd when the value falls to 0
// when we have no data. as in it makes it look bad.
// so we trim the performance ratio data if it's the future
function trimPerformanceRatioArray(
	data: Array<
		| { date?: string | null; performance_ratio?: string | null }
		| undefined
		| null
	>,
	date: DateTime,
) {
	return trimArrayDatesAfterDate(data, date)
}

export function FinanceGraphProduction() {
	const { graphInterval, knmiDataStatus } = useProductionGraphContext()
	const t = { common: useTrans("common"), project: useTrans("project") }
	const today = DateTime.fromJSDate(useCurrentDate())

	const { data } = useFinanceGraphProductionData()
	const { formatNumber } = useLang()

	const graphData = useMemo(
		() =>
			data?.me?.finance_projects?.results?.[0]?.overall_statistics?.total_production_data
				?.filter(Boolean)
				.map((node) => {
					return {
						x: String(node?.date),
						y: Math.max(
							node?.production ? parseFloat(node.production) : 0,
							0,
						),
					}
				}) ?? [],
		[data?.me?.finance_projects],
	)

	const expectedData = useMemo(
		() =>
			data?.me?.finance_projects?.results?.[0]?.overall_statistics?.expected_production_data
				?.filter(Boolean)
				.map((node) => {
					return {
						x: String(node?.date),
						y: node?.production ? parseFloat(node.production) : 0,
					}
				}) ?? [],
		[data?.me?.finance_projects],
	)

	const performanceRatio = useMemo(() => {
		let graphData =
			data?.me?.finance_projects?.results?.[0]?.overall_statistics
				?.production_performance_ratio?.performance_ratio_data ?? []

		// we only need to consider local() time since this is the graph
		// for the current year
		// remove all dates after today if viewing daily interval
		if (graphInterval === "day") {
			//@ts-ignore
			graphData = trimPerformanceRatioArray(
				graphData,
				DateTime.local().toUTC().endOf("day"),
			)
		}
		// otherwise remove all dates after this month if viewing monthly interval
		else {
			//@ts-ignore
			graphData = trimPerformanceRatioArray(
				graphData,
				DateTime.local().toUTC().endOf("month"),
			)
		}

		return (
			graphData.map((node) => {
				return {
					x: String(node?.date),
					y: node?.performance_ratio
						? Math.max(parseFloat(node.performance_ratio), 0)
						: 0,
				}
			}) ?? []
		)
	}, [data?.me?.finance_projects, graphInterval])

	const expectedPerformanceRatio = useMemo(() => {
		const totalPerformanceRatio = data?.me?.finance_projects?.results?.[0]
			?.targeted_performance_ratio
			? data.me.finance_projects?.results?.[0].targeted_performance_ratio
			: 0

		return (
			data?.me?.finance_projects?.results?.[0]?.overall_statistics?.production_performance_ratio?.performance_ratio_data?.map(
				(node) => {
					return {
						x: String(node?.date),
						y: totalPerformanceRatio,
					}
				},
			) ?? []
		)
	}, [data?.me?.finance_projects])

	const barDatas = useMemo(
		() => [
			{ data: expectedData, id: "expected_production" },
			{
				data: graphData,
				id: "total_production",
				variant: "primary" as const,
			},
		],
		[graphData, expectedData],
	)

	const lineDatas = useMemo(
		() => [
			{
				data: performanceRatio,
				id: "performance_ratio",
				variant: "primary" as const,
			},
			{
				data: expectedPerformanceRatio,
				id: "expected_performance_ratio",
			},
		],
		[expectedPerformanceRatio, performanceRatio],
	)

	// can't just use `t.common` inside here since the eslint
	// plugin complains
	const { common } = t
	const prepareTooltipLabel = useCallback(
		(x: string) => {
			if (graphInterval !== "month") {
				return x
			}

			const date = DateTime.fromISO(x)
			const month = date.month - 1
			const i18nKey = dates_long[month]
			const label = common(i18nKey)

			return label
		},
		[graphInterval, common],
	)

	if (graphData.length === 0) {
		return <GraphProductionNoData />
	}

	return (
		<MultiGraph
			key={`${graphInterval}-${knmiDataStatus}`}
			barDatas={barDatas}
			lineDatas={lineDatas}
			margin={{ left: 66, right: 25, top: 25, bottom: 50 }}
			xTickComponent={({ formattedValue, ...props }) => {
				const { i18n, value } = getKeyAndValueFromNumber(
					formattedValue
						? parseFloat(formattedValue.replace(/,/g, ""))
						: 0,
				)
				const text = t.common(i18n, {
					number: formatNumber(value),
				})

				return (
					<Text {...props} x={props.x + 5}>
						{text || value}
					</Text>
				)
			}}
			yTickComponent={({ formattedValue, ...props }) => {
				const date = formattedValue
					? DateTime.fromISO(formattedValue)
					: null

				if (graphInterval === "day") {
					if (date?.day === today.day) {
						return (
							<svg>
								<circle
									r={20}
									cx={props.x}
									cy={props.y}
									fill="#FFD900"
								/>
								<Text {...props} y={props.y + 6}>
									{date?.day}
								</Text>
							</svg>
						)
					}

					return (
						<Text {...props} y={props.y + 6}>
							{date?.day}
						</Text>
					)
				}

				const month = (date?.month ?? 1) - 1
				const i18nKey = dates[month]
				const value = t.common(i18nKey)

				if (date?.month === today.month) {
					return (
						<svg>
							<circle
								r={20}
								cx={props.x}
								cy={props.y}
								fill="#FFD900"
							/>
							<Text {...props} y={props.y + 6}>
								{value[0]}
							</Text>
						</svg>
					)
				}

				return (
					<Text {...props} y={props.y + 6}>
						{value[0]}
					</Text>
				)
			}}
			prepareTooltipLabel={prepareTooltipLabel}
		/>
	)
}

function GraphProductionNoData() {
	const t = useTrans("project")

	return (
		<div className="flex h-full flex-col items-center justify-center">
			<Heading as="h4">
				{t("project.data.production.no_data.title")}
			</Heading>
			<p className="mt-2 max-w-lg text-center text-sm text-gray-500">
				{t("project.data.production.no_data.copy")}
			</p>
		</div>
	)
}
