import {
	ReactElement,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
	useEffect,
} from "react"
import { QueryClient } from "@/lib/query"
import { useOnInactivity } from "@/hooks/useOnInactivity"
import { useTrans } from "@/i18n"
import { useToasts } from "./toasts"

// Context
import { useCurrentUserId } from "./user"

// State
import { useDispatch } from "@/state/StateProvider"
import { setToken } from "@/state/features/authSlice"
import { persistor } from "@/state/store"

// Environment variables
import { AUTH_LOGOUT_URL } from "@/lib/env"

export interface AuthState {
	authToken: string
}

export interface RefreshTokenResponse extends AuthState {
	refreshLogin: string
}

export enum AuthStateStatusEnum {
	LOGGED_IN = "logged-in",
	AUTHENTICATING = "authenticating",
	LOGGED_OUT = "logged-out",
}
interface AuthContextType {
	status: AuthStateStatusEnum
	setAuth: (state: AuthState) => void
	logOut: () => Promise<void>
	isLoggedIn: () => boolean
}

type State = Pick<AuthContextType, "status"> & {
	[key in keyof AuthState]: AuthState[key] | null
}

interface AuthProviderProps extends Partial<AuthContextType> {
	children: ReactElement
	initialStatus?: State["status"]
	queryClient?: QueryClient
}

const AuthContext = createContext<AuthContextType>(null!)

export const useAuth = () => useContext(AuthContext)

export const initialAuthState = AuthStateStatusEnum.LOGGED_OUT

export const AuthProvider = ({
	children,
	setAuth: setAuthFromProps,
	initialStatus = AuthStateStatusEnum.AUTHENTICATING,
	queryClient,
}: AuthProviderProps) => {
	// State
	const dispatch = useDispatch()
	const [status, setStatus] = useState<AuthStateStatusEnum>(initialStatus)

	// Context
	const { id: userId } = useCurrentUserId()

	useEffect(() => {
		// When we said hi succesfully, set auth state
		if (userId && status !== AuthStateStatusEnum.LOGGED_IN) {
			// Set logged out status
			setStatus(AuthStateStatusEnum.LOGGED_IN)
		}
	}, [userId])

	/**
	 * Logs out the user and redirects to the login page
	 * TODO: Call the backend to log out the user as well
	 */
	const logOut = useCallback(async () => {
		// If not already logging out
		if (status !== AuthStateStatusEnum.LOGGED_OUT) {
			// Clear redux state and persistor
			dispatch({ type: "PURGE" })
			persistor.purge()

			/**
			 * Clear the react-query cache when a user logs out
			 *
			 * if a user logs out, and we  do not clear the cache, when they
			 * log back in, react-query will hold onto the cache of the old
			 * queries with the old user. so you'll get the previous data,
			 * which might be wrong, until the new one refetches.
			 * so this clears the react query cache when a user logs out
			 *
			 * TODO: Review that this is indeed working as expected
			 */
			queryClient?.clear()

			// Set status
			setStatus(AuthStateStatusEnum.LOGGED_OUT)

			// TODO: This is a temporary hack to redirect to the old logout page
			window.location.href = AUTH_LOGOUT_URL
		}
	}, [status, queryClient])

	const setAuth = useCallback(
		({ authToken }: AuthState) => {
			// Persistent state
			dispatch(setToken(authToken))

			// Local state
			setStatus(AuthStateStatusEnum.LOGGED_IN)
		},
		[setStatus],
	)

	const isLoggedIn = useCallback(() => {
		return status === AuthStateStatusEnum.LOGGED_IN
	}, [status])

	return (
		<AuthContext.Provider
			value={{
				status,
				logOut,
				setAuth: setAuthFromProps ?? setAuth,
				isLoggedIn,
			}}
		>
			{useMemo(() => children, [children])}
		</AuthContext.Provider>
	)
}

export const AuthInactivityIndicator = () => {
	const { logOut } = useAuth()
	const t = useTrans("common")
	const { addToast } = useToasts()

	const onInactivity = useCallback(() => {
		logOut()
		addToast({
			id: "common.auth.inactivity_message",
			text: t("common.auth.inactivity_message"),
			timing: 10000,
		})
	}, [logOut, t, addToast])

	// 30 minutes inactivity
	useOnInactivity(onInactivity)

	return null
}
