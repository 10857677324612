import { ReactNode, useMemo, useCallback, TouchEvent, MouseEvent } from "react"

// Animations
import { motion, AnimatePresence } from "@/lib/animations"

// UI
import { Tooltip } from "@/components/visx/components/Tooltip/Tooltip"

// Graphs
import { localPoint } from "@visx/event"
import { useTooltip } from "@visx/tooltip"
import { Axis, Orientation, TickRendererProps } from "@visx/axis"
import { curveMonotoneX } from "@visx/curve"
import { GridRows } from "@visx/grid"
import { Group } from "@visx/group"
import { scaleTime, scaleLinear } from "@visx/scale"
import { LinePath } from "@visx/shape"
import { Text } from "@visx/text"
import { bisector } from "d3-array"

// Translations
import { useLang } from "@/context/lang"
import { ParentSize } from "../../../visx/components/ParentSize"
import { findLongestArrayInMultiGraphData } from "../../../visx/lib/MultiGraph"
import { MultiGraphTooltipProps } from "./MultiLineGraphContent"

// We do this to prevent having 2 axes renders, with grid rows, since
// it can be very confusing.
const SHOW_LINE_DATA_GRID_ROWS = false

export type GraphData = {
	x: string | Date
	y: number
}

export type LineGraphData = {
	data: Array<GraphData>
	id: string
	variant?: "primary" | "default"
}

type ValueFormatter = (value: string | number) => string | number

export type TooltipComponentProps = {
	label: string
	value: number | string | null
	value2?: number | string | null
}
export type TooltipComponentType = React.FC<TooltipComponentProps>
export type MultiGraphProps = {
	width: number
	height: number
	margin?: { top: number; right: number; bottom: number; left: number }
	lineDatas?: Array<LineGraphData>
	xTickComponent?: (tickRendererProps: TickRendererProps) => ReactNode
	yTickComponent?: (tickRendererProps: TickRendererProps) => ReactNode
	TooltipComponent?: TooltipComponentType
	prepareTooltipValues?:
		| ((x: TooltipData["x"]) => MultiGraphTooltipProps["values"])
		| null
}

export type BarGraphData = {
	data: Array<GraphData>
	id: string
	variant?: "primary" | "default"
}

MultiGraphContainer.barVariantColourMap = {
	primary: "#FFD900",
	default: "#4e5155",
}
MultiGraphContainer.lineVariantColourMap = {
	primary: "#149AE5",
	default: "#000",
}

MultiGraphContainer.buildBarDataTooltip = (
	d: BarGraphData,
	x: string,
	valueFormatter: ValueFormatter,
) => {
	const item = d.data.find((node) => node.x === x)

	return {
		value: valueFormatter(item ? getY(item).toFixed(2) : 0),
		id: d.id,
		variant: d.variant,
		colour:
			d.variant === "primary"
				? MultiGraphContainer.barVariantColourMap.primary
				: MultiGraphContainer.barVariantColourMap.default,
	}
}

export function MultiGraphContainer(
	props: Omit<MultiGraphProps, "width" | "height">,
) {
	return (
		<ParentSize>
			{({ width, height }) => {
				if (width < 10) return null
				return <MultiGraph {...props} width={width} height={height} />
			}}
		</ParentSize>
	)
}

MultiGraphContainer.defaultMargin = { top: 25, bottom: 40, left: 66, right: 25 }
MultiGraphContainer.lineVariantColourMap = {
	primary: "#149AE5",
	default: "#000",
}

MultiGraphContainer.buildLineDataTooltip = (
	d: LineGraphData,
	x: string,
	valueFormatter: ValueFormatter = (x) => x,
) => {
	const item = d.data.find((node) => node.x === x)
	const value = item ? getY(item).toFixed(2) : null

	return {
		value: value !== null ? valueFormatter(value) : null,
		id: d.id,
		variant: d.variant,
		colour:
			d.variant === "primary"
				? MultiGraphContainer.lineVariantColourMap.primary
				: MultiGraphContainer.lineVariantColourMap.default,
	}
}

export { MultiGraphContainer as MultiLineGraph }

const brandGray = "#4e5155"
const grayLight = "#C7C8CC"

type Data = {
	x: number
	y: number
}
const getX = (d: GraphData) => d.x
const getY = (d: GraphData) => Number(d.y)
const bisectDate = bisector<Data, Date>((d) => new Date(d.x)).left

interface TooltipData {
	x: GraphData["x"]
	topY: number
	centerX: number
	value: number
	value2: number
	label: string
}

function MultiGraph({
	width,
	lineDatas: lineDatasFromProps = [],
	height,
	margin = MultiGraphContainer.defaultMargin,
	yTickComponent = DefaultYTickComponent,
	xTickComponent,
	TooltipComponent,
}: MultiGraphProps) {
	const { tooltipData, tooltipLeft, tooltipTop, showTooltip, hideTooltip } =
		useTooltip<TooltipData>()

	// Translations
	const { config } = useLang()

	// flatten all datas into one array for picking
	const lineDatas = useMemo(() => {
		if (lineDatasFromProps.length > 1) {
			const longestData = findLongestArrayInMultiGraphData(
				// @ts-ignore
				lineDatasFromProps,
			)
			return longestData.data.map((item, i) => {
				// the idea here is to get the maximum Y value
				// to help with the band and linear scales below
				// otherwise the graph doesn't know the highest y value
				return Object.assign({}, item, {
					// find the largest y by using Math.max
					// we can make a new array with all the items values
					y: Math.max(
						...lineDatasFromProps.map((d) => d.data[i]?.y ?? 0),
					),
				})
			})
		}
		return lineDatasFromProps[0]?.data ?? []
	}, [lineDatasFromProps])

	// bounds
	const innerWidth = width - margin.left - margin.right
	const innerHeight = height - margin.top - margin.bottom

	// scales
	const xScaleLines = useMemo(
		() =>
			scaleTime({
				range: [0, innerWidth],
				domain: [
					Math.min(
						...lineDatas.map((d) => Number(new Date(getX(d)))),
					),
					Math.max(
						...lineDatas.map((d) => Number(new Date(getX(d)))),
					),
				],
			}),
		[innerWidth, lineDatas],
	)
	const yScaleLines = useMemo(
		() =>
			scaleLinear<number>({
				range: [innerHeight, 0],
				round: true,
				domain: [0, Math.max(...lineDatas.map(getY))],
			}),
		[innerHeight, lineDatas],
	)

	// tooltip handler
	const handleTooltip = useCallback(
		(event: TouchEvent<SVGPathElement> | MouseEvent<SVGPathElement>) => {
			const x = localPoint(event)?.x ?? 0
			const x0 = xScaleLines.invert(x)

			//@ts-ignore
			const index = bisectDate(lineDatas, x0, 1)
			const d0 = lineDatasFromProps[0].data.at(index - 1)
			const d1 = lineDatasFromProps[0].data.at(index)
			const estimation0 = lineDatasFromProps[1].data.at(
				index - 1,
			) as GraphData
			const estimation1 = lineDatasFromProps[1].data.at(
				index,
			) as GraphData

			// Endex tariff
			let d = d0
			if (d1 && getX(d1)) {
				d = // @ts-ignore
					x0.valueOf() - getX(d0).valueOf() > // @ts-ignore
					getX(d1).valueOf() - x0.valueOf()
						? d1
						: d0
			}

			// Average index tariff
			let e = estimation0
			if (estimation1 && getX(estimation1)) {
				e = // @ts-ignore
					x0.valueOf() - getX(estimation0).valueOf() > // @ts-ignore
					getX(estimation1).valueOf() - x0.valueOf()
						? estimation1
						: estimation0
			}

			// @ts-ignore
			const valueY = getY(d)
			const dataY = yScaleLines(valueY) // @ts-ignore
			const dataX = xScaleLines(getX(d))

			// we set value to null if the next value is empty
			// this is so the line/tooltip/interactive part of the graph
			// does not render the marker when it's at the "end" of the array
			let value: number | null = valueY
			let y: number | null = dataY

			if (!value) {
				const restOfArray = lineDatas.slice(index)
				// is this the end of all the 0 values? aka we are on the flat line
				//
				//    ._._._.
				//  ./       \.
				//             \._._._._.
				//               ↑↑↑↑↑ we are here so do not show the marker on the graph
				//						(marker being small yellow circle indicating current hovered value)
				if (restOfArray.every((lineDatas) => lineDatas.y === 0)) {
					value = null
					y = null
				}
				// is this the end of the data array?
				else if (!lineDatas[index]) {
					value = null
					y = null
				}
			}

			showTooltip({
				tooltipData: {
					//@ts-ignore
					label: getX(d).toLocaleDateString(config.locale, {
						month: window.innerWidth < 500 ? "short" : "long",
						year: "numeric",
						day: "numeric",
					}),
					y,
					// @ts-ignore
					x: dataX, // @ts-ignore
					y2: yScaleLines(getY(d)),
					value: Number(value),
					value2: getY(e),
					tooltipTopForEstimation: dataY,
				},
				tooltipLeft: dataX,
				// what's going on here? well, we want the top of the tooltip line
				// to be at the highest value. since we are working from the top, we need the smallest value!
				tooltipTop: Math.min(dataY, dataY),
			})
		},
		[
			xScaleLines,
			lineDatas, // Flattened version of all data sets
			lineDatasFromProps, // This contains 2 data sets
			config.locale,
			showTooltip,
			yScaleLines,
		],
	)

	const gridRowsWidth = width - margin.left - margin.right
	const hasLineDatas = lineDatas.length > 0

	return (
		<div className="relative">
			<svg width={width} height={height}>
				{/**
				 * We do this to prevent having 2 axes renders, with grid rows, since
				 * it can be very confusing.
				 */}
				{useMemo(
					() =>
						!hasLineDatas ? (
							<GridRows
								scale={yScaleLines}
								width={gridRowsWidth}
								height={height - margin.top}
								numTicks={3}
								stroke={grayLight}
								strokeDasharray={"10,2"}
								opacity={0.75}
								top={margin.top}
								left={margin.left}
							/>
						) : null,
					[
						gridRowsWidth,
						hasLineDatas,
						height,
						margin.left,
						margin.top,
						yScaleLines,
					],
				)}
				{SHOW_LINE_DATA_GRID_ROWS ? (
					<GridRows
						scale={yScaleLines}
						width={gridRowsWidth}
						height={height - margin.top}
						numTicks={3}
						stroke={grayLight}
						strokeDasharray={"10,2"}
						opacity={0.75}
						top={margin.top}
						left={margin.left}
					/>
				) : null}
				{/**
				 * This one big group is doing a lot:
				 * - It includes and renders all the line graph datas
				 * - It includes and renders the hover/interactive part
				 */}
				<Group>
					{/**
					 * These are all the line datas.
					 */}
					{useMemo(
						() =>
							lineDatasFromProps
								// keep primary variants on top of tooltip
								.sort((a) => {
									if (a.variant === "primary") return -1
									return 1
								})
								.map(({ data, id, variant }) => {
									return (
										<LinePath
											key={id}
											curve={curveMonotoneX}
											data={data}
											onTouchStart={handleTooltip}
											onTouchMove={handleTooltip}
											onMouseMove={handleTooltip}
											onMouseLeave={hideTooltip}
											x={(d) =>
												xScaleLines(Number(getX(d))) +
												margin.left
											}
											y={(d) =>
												(yScaleLines(getY(d)) ?? 0) +
												margin.top
											}
											stroke={
												variant === "primary"
													? MultiGraphContainer
															.lineVariantColourMap
															.primary
													: MultiGraphContainer
															.lineVariantColourMap
															.default
											}
											strokeWidth={2}
											strokeDasharray={
												variant === "primary"
													? ""
													: "6,9"
											}
											shapeRendering="geometricPrecision"
											markerMid={`url(#marker-circle-${variant})`}
											markerStart={`url(#marker-circle-${variant})`}
											markerEnd={`url(#marker-circle-${variant})`}
											clipPath="url(#line-mask)"
										/>
									)
								}),
						[
							lineDatasFromProps,
							margin.left,
							margin.top,
							xScaleLines,
							yScaleLines,
						],
					)}
					{/**
					 * This is the mask for the line
					 */}
					{useMemo(
						() => (
							<clipPath id="line-mask">
								<motion.rect
									x={0}
									y={0}
									height={innerHeight + margin.top}
									fill="white"
									initial={{ width: 0 }}
									animate={{
										width: innerWidth + margin.left,
									}}
									transition={{
										duration: 1.75,
										delay: 0.75,
									}}
								/>
							</clipPath>
						),
						[innerHeight, innerWidth, margin.left, margin.top],
					)}
				</Group>
				<Axis
					key="x"
					tickComponent={xTickComponent}
					tickLabelProps={() => ({
						fill: "black",
						fontSize: 16,
						fontFamily: "Static",
						fontWeight: 700,
						textAnchor: "middle",
					})}
					top={innerHeight + margin.top - 1}
					left={margin.left}
					orientation={Orientation.bottom}
					scale={xScaleLines}
					stroke="black"
					hideTicks
					numTicks={Math.max(4, Math.floor(innerWidth / 60))}
				/>

				{lineDatas?.length > 1 ? (
					<Axis
						key="line-data-y"
						orientation={Orientation.right}
						scale={yScaleLines}
						top={margin.top + 5}
						left={innerWidth + margin.right + 15}
						hideTicks
						numTicks={3}
						hideAxisLine
						tickLabelProps={() => ({
							fontSize: 20,
							color: brandGray,
							fontFamily: "Static",
							fontWeight: 700,
							opacity: 0.5,
							textAnchor: "middle",
						})}
						tickComponent={yTickComponent}
						hideZero
					/>
				) : null}
			</svg>

			{TooltipComponent && (
				<AnimatePresence>
					{tooltipData &&
					tooltipTop !== undefined &&
					tooltipLeft !== undefined ? (
						<motion.div>
							<Tooltip
								left={tooltipLeft}
								top={tooltipTop}
								offsetLeft={10}
								offsetTop={-42}
								key={
									tooltipLeft / 2 < innerWidth
										? "left"
										: "right"
								}
							>
								<TooltipComponent
									value={tooltipData.value}
									value2={tooltipData.value2}
									label={tooltipData.label}
								/>
							</Tooltip>
						</motion.div>
					) : null}
				</AnimatePresence>
			)}
		</div>
	)
}

function DefaultYTickComponent({
	formattedValue,
	...props
}: TickRendererProps) {
	return <Text {...props}>{formattedValue}</Text>
}
