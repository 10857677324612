/**
 * These are all the env variables exposed to the app.
 *
 * All variables exposed to the app at runtime have to be prefixed with 'VITE_' to be used and to be imported via 'import.meta.env'.
 * Any other env vars are only available during build-time.
 * Some env variables are generated by Vercel and are automatically prefixed 'VITE_' as well.
 * Any scripts outside the Vite environment (like cypress) should use 'process.env' directly.
 *
 * DOCS: https://vercel.com/docs/projects/environment-variables/system-environment-variables
 */

// Environment
export const VERCEL_ENV = import.meta.env.VITE_VERCEL_ENV

// Resolve Hostname
let hostName = "127.0.0.1"
if (window) {
	// Set hostname to exactly how we find it in the url
	hostName = window.location.hostname

	/**
	 * Check if we are on vercel preview url
	 * EXAMPLES: https://app-git-dependabot-npmandyarnrollup-3295.vercel.zonnepanelendelen.nl/
	 * https://app-jhmcl5l6p.vercel.zonnepanelendelen.nl/
	 */
	const regexVercel = /vercel\.([a-zA-Z0-9.-]+)/
	const matchVercel = String(window.location).match(regexVercel)
	if (matchVercel?.[1]) {
		hostName = matchVercel[1] // We are on preview vercel, this is the hostname
	}

	/**
	 * Check if we are on our production domain
	 * EXAMPLES: app.zonhub.com / app.zonnepanelendelen.nl
	 */
	const regexApp = /app\.([a-zA-Z0-9.-]+)/
	const matchApp = String(window.location).match(regexApp)

	// this is the hostname
	if (matchApp?.[1]) {
		hostName = matchApp[1]
	}
}

// When we are on 'staging', apply the subdomain prefix
let subDomainBackend = ""
if (VERCEL_ENV === "preview") {
	subDomainBackend = "stg."
}

// When we are not running on live environment
let protocol = "https" // Default protocol
let portBackend = "" // Port (only important for local)
if (hostName === "127.0.0.1") {
	protocol = "http"
	portBackend = ":8000"
	subDomainBackend = ""
}

// Dynamic env variables based on hostname
export const API_URL = `${protocol}://${subDomainBackend}${hostName}${portBackend}/`
export const GRAPHQL_URL = `${API_URL}graphql/`
export const EXTERNAL_PLATFORM_PRIVACY_POLICY = `${API_URL}privacy-policy`

// All env variables available to our app
export const SERVER_CSP_NONCE = import.meta.env.VITE_SERVER_CSP_NONCE

// Mock service worker
export const VITE_MOCK_BACKEND = import.meta.env.VITE_MOCK_BACKEND
export const MOCK_BACKEND = import.meta.env.VITE_MOCK_BACKEND

// Storybook
export const STORYBOOK_API_URL = import.meta.env.VITE_STORYBOOK_API_URL

// Google Tag Manager
export const GTM_CONTAINER_ID = import.meta.env.VITE_GTM_CONTAINER_ID

// Jest
export const JEST_WORKER_ID = import.meta.env.VITE_JEST_WORKER_ID

// Google Maps
export const GOOGLE_MAPS_API_KEY: string =
	import.meta.env.VITE_GOOGLE_MAPS_API_KEY || ""
export const GOOGLE_MAPS_ID: string = import.meta.env.VITE_GOOGLE_MAPS_ID || ""

// I18n
export const VITE_DEBUG_I18N = import.meta.env.VITE_DEBUG_I18N
export const DEBUG_I18N = import.meta.env.VITE_DEBUG_I18N

// Sentry
export const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
export const VITE_SENTRY_DEBUG = import.meta.env.VITE_SENTRY_DEBUG
export const SENTRY_DEBUG = import.meta.env.VITE_SENTRY_DEBUG
// Git
export const VERCEL_URL = import.meta.env.VITE_VERCEL_URL
export const VERCEL_GIT_COMMIT_SHA = import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA
export const VERCEL_BRANCH_URL = import.meta.env.VITE_VERCEL_BRANCH_URL
export const VERCEL_GIT_COMMIT_MESSAGE = import.meta.env
	.VITE_VERCEL_GIT_COMMIT_MESSAGE
export const VERCEL_GIT_COMMIT_AUTHOR_NAME = import.meta.env
	.VITE_VERCEL_GIT_COMMIT_AUTHOR_NAME

// External links
export const EXTERNAL_PLATFORM_INFO_CENTER = import.meta.env
	.VITE_EXTERNAL_PLATFORM_INFO_CENTER

// Define auth routes
export const AUTH_LOGOUT_URL = `${API_URL}account/logout/`
export const AUTH_LOGIN_URL = `${API_URL}account/login/`
