import { useMemo } from "react"
import { Text } from "@visx/text"
import { DateTime } from "@/lib/dates"
import { useTrans } from "@/i18n"
import { Heading } from "@/components/Typography"
import { useLang } from "@/context/lang"
import { useProjectGraphProductionQuery } from "@/api/graphql"
import { useCurrentDate } from "@/hooks/useCurrentDate"
import { getKeyAndValueFromNumber } from "@/lib/i18n"
import { useCurrentProjectId } from "../hooks/useCurrentProjectId"
import { formatGraphValue } from "@/misc/helpers"

// Graphs
import { useProductionGraphContext } from "@/components/graphs/types/bar/ProductionGraph"
import { MultiGraph } from "@/components/graphs/types/bar-line/MultiGraph"

const dates = [
	"common.date.january_short",
	"common.date.feburary_short",
	"common.date.march_short",
	"common.date.april_short",
	"common.date.may_short",
	"common.date.june_short",
	"common.date.july_short",
	"common.date.august_short",
	"common.date.september_short",
	"common.date.october_short",
	"common.date.november_short",
	"common.date.december_short",
]

const dates_long = [
	"common.date.january_long",
	"common.date.feburary_long",
	"common.date.march_long",
	"common.date.april_long",
	"common.date.may_long",
	"common.date.june_long",
	"common.date.july_long",
	"common.date.august_long",
	"common.date.september_long",
	"common.date.october_long",
	"common.date.november_long",
	"common.date.december_long",
]

// exported for use in header
export function useProjectGraphProductionData() {
	const { graphInterval, startTime, endTime } = useProductionGraphContext()
	const projectId = useCurrentProjectId()

	return useProjectGraphProductionQuery({
		projectId: String(projectId),
		startTime: startTime,
		endTime: endTime,
		interval: graphInterval,
	})
}

export function ProjectGraphProduction() {
	const { graphInterval, knmiDataStatus } = useProductionGraphContext()
	const t = { common: useTrans("common"), project: useTrans("project") }
	const today = DateTime.fromJSDate(useCurrentDate())

	const { data } = useProjectGraphProductionData()
	const { formatNumber } = useLang()

	const graphData = useMemo(
		() =>
			data?.me?.investment_projects?.results?.[0]?.investor_production_stats?.production_data
				?.filter(Boolean)
				.map((node) => {
					return {
						x: node?.date as string,
						y: node?.production
							? formatGraphValue(node?.production)
							: 0,
					}
				}) ?? [],
		[data?.me?.investment_projects],
	)

	const expectedData = useMemo(
		() =>
			data?.me?.investment_projects?.results?.[0]?.investor_production_stats?.expected_production_data
				?.filter(Boolean)
				.map((node) => {
					return {
						x: node?.date as string,
						y: node?.production
							? formatGraphValue(parseFloat(node.production))
							: 0,
					}
				}) ?? [],
		[data?.me?.investment_projects],
	)

	const datas = useMemo(
		() => [
			{
				data: graphData,
				id: "total_production",
				variant: "primary" as const,
			},
			{ data: expectedData, id: "expected_production" },
		],
		[graphData, expectedData],
	)

	if (graphData.length === 0) {
		return <GraphProductionNoData />
	}

	return (
		<MultiGraph
			key={`${graphInterval}-${knmiDataStatus}`}
			barDatas={datas}
			margin={{ left: 66, right: 25, top: 25, bottom: 50 }}
			xTickComponent={({ formattedValue, ...props }) => {
				const { i18n, value } = getKeyAndValueFromNumber(
					formattedValue
						? parseFloat(formattedValue.replace(/,/g, ""))
						: 0,
				)
				const text = t.common(i18n, {
					number: formatNumber(value),
				})

				return (
					<Text {...props} x={props.x + 5}>
						{text || value}
					</Text>
				)
			}}
			yTickComponent={({ formattedValue, ...props }) => {
				const date = formattedValue
					? DateTime.fromISO(formattedValue)
					: null

				if (graphInterval === "day") {
					if (date?.day === today.day) {
						return (
							<svg>
								<circle
									r={20}
									cx={props.x}
									cy={props.y}
									fill="#FFD900"
								/>
								<Text {...props} y={props.y + 6}>
									{date?.day}
								</Text>
							</svg>
						)
					}

					return (
						<Text {...props} y={props.y + 6}>
							{date?.day}
						</Text>
					)
				}

				const month = (date?.month ?? 1) - 1
				const i18nKey = dates[month]
				const value = t.common(i18nKey)

				if (date?.month === today.month) {
					return (
						<svg>
							<circle
								r={20}
								cx={props.x}
								cy={props.y}
								fill="#FFD900"
							/>
							<Text {...props} y={props.y + 6}>
								{value[0]}
							</Text>
						</svg>
					)
				}

				return (
					<Text {...props} y={props.y + 6}>
						{value[0]}
					</Text>
				)
			}}
			prepareTooltipLabel={(x: string) => {
				if (graphInterval !== "month") {
					return x
				}

				const date = DateTime.fromISO(x)
				const month = date.month - 1
				const i18nKey = dates_long[month]
				const label = t.common(i18nKey)

				return label
			}}
		/>
	)
}

function GraphProductionNoData() {
	const t = useTrans("project")

	return (
		<div className="flex h-full flex-col items-center justify-center">
			<Heading as="h4">
				{t("project.data.production.no_data.title")}
			</Heading>
			<p className="mt-2 max-w-lg text-center text-sm text-gray-500">
				{t("project.data.production.no_data.copy")}
			</p>
		</div>
	)
}
