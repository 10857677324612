import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface AuthSliceState {
	token: string | null
	ephemeralToken: string | null
	lastEmail: string | null
	rememberMe: boolean
}

export const initialState: AuthSliceState = {
	token: null,
	ephemeralToken: null,
	lastEmail: null,
	rememberMe: false,
}

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload
		},
		setEphemeralToken: (state, action: PayloadAction<string>) => {
			state.ephemeralToken = action.payload
		},
		setLastEmail: (state, action: PayloadAction<string | null>) => {
			state.lastEmail = action.payload
		},
		setRememberMe: (state, action: PayloadAction<boolean>) => {
			state.rememberMe = action.payload
		},
	},
})

// Action creators are generated for each case reducer function
export const { setToken, setEphemeralToken, setLastEmail, setRememberMe } =
	authSlice.actions

export default authSlice.reducer
