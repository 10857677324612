import { Helmet } from "@/lib/seo"
import { Container } from "@/components/Container"
import { RecentUserActivity } from "@/components/RecentActivity"
import { useTrans } from "@/i18n"

export const Recent = () => {
	const t = useTrans("recent")

	return (
		<>
			<Helmet>
				<title>{t("recent.title")}</title>
			</Helmet>
			<Container>
				<RecentUserActivity />
			</Container>
		</>
	)
}
