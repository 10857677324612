import { Highchart } from "@/lib/highcharts"

interface PerformanceGaugeProps {
	value: number
}

export const PerformanceGauge = ({ value }: PerformanceGaugeProps) => {
	return (
		<Highchart
			options={{
				credits: { enabled: false },
				chart: {
					type: "gauge",
				},
				title: { text: "" },
				tooltip: {
					enabled: false,
				},
				pane: {
					startAngle: -140,
					endAngle: 140,
					innerSize: "10%",
					background: [
						{
							borderColor: "transparent",
							outerRadius: 60,
							shape: "circle",
							backgroundColor: {
								radialGradient: {
									cx: 0.5,
									cy: 0.8,
									r: 1,
								},
								stops: [
									[0, "rgba(246, 191, 66, .8)"],
									[1, "rgb(246, 191, 66, .6)"],
									[2, "rgb(246, 191, 66, .4)"],
								],
							},
						},
					],
				},
				yAxis: {
					labels: {
						enabled: false,
					},
					min: 0,
					max: 100,
					tickColor: "#000",
					minorTickColor: "#000",
					tickPosition: "inside",
					offset: -30,
					lineWidth: 0,
					tickLength: 10,
					minorTickLength: 10,
					tickInterval: 10,
					tickAmount: 5,
				},
				series: [
					{
						type: "gauge",
						name: "Realtime power",
						data: [value],
						dataLabels: {
							enabled: true,
							padding: 70,
							borderColor: "transparent",
							style: {
								fontSize: "32px",
							},
							formatter: function () {
								return `${this.y}%`
							},
						},
						// the indicator
						dial: {
							baseLength: "20%",
							baseWidth: 6,
							rearLength: "30%",
						},
						// the round circle in indicator
						pivot: {
							radius: 12,
							backgroundColor: "#000",
						},
					},
				],
			}}
		/>
	)
}
