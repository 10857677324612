import { DateTime } from "@/lib/dates"
import { useMemo } from "react"
import { useLang } from "@/context/lang"
import { useCurrentDate } from "@/hooks/useCurrentDate"
import { useTrans } from "@/i18n"
import { useProjectGraphProductionData } from "./ProjectGraphProduction"

// Graphs
import { useProductionGraphContext } from "@/components/graphs/types/bar/ProductionGraph"
import { GraphHeader } from "@/components/graphs/header/GraphHeader"

function useToday() {
	return DateTime.fromJSDate(useCurrentDate())
}

export function ProjectGraphProductionHeader() {
	const t = useTrans("project")
	const { config, formatNumber } = useLang()
	const today = useToday()
	const { data } = useProjectGraphProductionData()
	const { graphInterval } = useProductionGraphContext()

	const amount = useMemo(() => {
		return data?.me?.investment_projects?.results?.[0]?.investor_production_stats?.production_data
			?.reduce(
				(acc, curr) =>
					curr?.production ? acc + parseFloat(curr.production) : acc,
				0,
			)
			.toFixed(2)
	}, [data])

	return (
		<>
			{graphInterval === "month" ? (
				<GraphHeader
					title={t("project.data.production.yearly.title", {
						year: today.year,
					})}
					subtitle={t("project.data.production.yearly.subtitle", {
						amount: amount ? formatNumber(parseFloat(amount)) : 0,
					})}
				/>
			) : (
				<GraphHeader
					title={t("project.data.production.monthly.title", {
						month: today
							.toJSDate()
							.toLocaleDateString(config.locale, {
								month: "long",
							}),
					})}
					subtitle={t("project.data.production.monthly.subtitle", {
						amount: amount ? formatNumber(parseFloat(amount)) : 0,
					})}
				/>
			)}
		</>
	)
}
