import { defaultStyles } from "@visx/tooltip"

// Export the default styles with some modifications
export const tooltipStyles = {
	...defaultStyles,
	// fixes text wrapping onto two lines
	whiteSpace: "nowrap" as const,
	background: "#fff",
	border: "0",
	padding: 0,
	maxWidth: "100vw",
}
