import { classNames } from "@/lib/classnames"

export function MenuIcon({
	isOpen,
	className,
}: {
	className?: string
	isOpen?: boolean
}) {
	return (
		<div className={classNames(className, "relative")}>
			<span
				aria-hidden="true"
				className={classNames(
					"absolute top-1/2 block h-0.5 w-full transform bg-current transition",
					!isOpen ? "rotate-45" : "-translate-y-1.5",
				)}
			/>
			<span
				aria-hidden="true"
				className={classNames(
					"absolute top-1/2 block h-0.5 transform bg-current transition",
					!isOpen ? "w-0" : "w-full",
				)}
			/>
			<span
				aria-hidden="true"
				className={classNames(
					"absolute top-1/2 block h-0.5 w-full transform bg-current transition",
					!isOpen ? "-rotate-45" : "translate-y-1.5",
				)}
			/>
		</div>
	)
}
