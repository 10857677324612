import { useFormik } from "formik"
import { ApiErrorResponseInterface } from "@/api/rest"
import { useTrans } from "@/i18n"
import { Yup } from "@/lib/yup"
import { FormikError } from "@/components/form-controls/Errors"
import {
	FormikErrors,
	FormikInput,
	FormikLabel,
	FormikSubmitButton,
	FormikWrapper,
} from "@/components/form-controls/formik"
import { Heading } from "@/components/Typography"

// Queries
import { useProfileSecurityIntroQuery } from "@/api/graphql"
import { apiAuthActivateCreate } from "@/api/rest/generated/api/api"
import { MethodEnum } from "@/api/rest/generated/@types"

const validationSchema = Yup.object().shape({
	email: Yup.string().email(
		"profile.security.two_factor_auth.form.invalid_email",
	),
})

interface EmailMethodConfirmAddressProps {
	onSuccess: () => void
}

export const EmailMethodConfirmAddress = ({
	onSuccess,
}: EmailMethodConfirmAddressProps) => {
	const t = useTrans("profile")

	const { data } = useProfileSecurityIntroQuery()

	const form = useFormik({
		validationSchema,
		initialValues: {
			email: data?.me?.email,
		},
		onSubmit: async (_values, helpers) => {
			try {
				const response = await apiAuthActivateCreate(MethodEnum.email)
				// @ts-ignore
				if (!response) {
					helpers.setFieldError(
						"errors.common",
						"form_errors.unknown_error",
					)
					return
				}

				onSuccess()
			} catch (e) {
				const error = e as ApiErrorResponseInterface | null

				if (error?.json?.error) {
					helpers.setFieldError("errors.local", error?.json?.error)
				} else {
					helpers.setFieldError(
						"errors.common",
						"form_errors.unknown_error",
					)
				}
			}
		},
	})

	return (
		<FormikWrapper form={form}>
			<Heading as="h6" styleAs="h6" className="mb-4 sm:truncate">
				{t("profile.security.two_factor_auth.confirm_email.header")}
			</Heading>

			<div className="text-gray-700">
				<p className="mb-3 text-sm text-gray-500">
					{t(
						"profile.security.two_factor_auth.email.confirm_address",
					)}
				</p>
				<div className="mb-4">
					<FormikLabel htmlFor="emil" className="sr-only">
						{t("profile.security.two_factor_auth.email.label")}
					</FormikLabel>
					<FormikInput
						disabled
						name="email"
						id="emil"
						className="block w-full lg:w-1/2"
						type="email"
					/>
					<FormikError field="email" namespace="profile" />
				</div>
				<div>
					<FormikErrors i18nNamespace="profile" />
				</div>
			</div>

			<FormikSubmitButton
				className="lg:ml-auto"
				disableWhenPristine={false}
				size="small"
			>
				{t("profile.security.two_factor_auth.email.confirm.submit")}
			</FormikSubmitButton>
		</FormikWrapper>
	)
}
