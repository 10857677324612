import { useState, useLayoutEffect } from "react"
import { throttle } from "@/lib/throttle"

export const useWindowWidth = () => {
	const [width, set] = useState(0)

	useLayoutEffect(() => {
		const setter = () => set(window.innerWidth)
		const handler = throttle(setter, 200)
		setter()
		window.addEventListener("resize", handler)
		return () => {
			window.removeEventListener("resize", handler)
		}
	}, [])

	return width
}
