import { configureStore, combineReducers } from "@reduxjs/toolkit"
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	createMigrate,
} from "redux-persist"
import storage from "redux-persist/lib/storage"

// Feature slices
import authReducer from "./features/authSlice"
import userPreferencesReducer from "./features/userPreferencesSlice"
import investmentsOverviewReducer from "./features/investmentsOverview/slice"

// Migrations
// @ts-ignore
import { migrations } from "./migrations"

// Root Reducer
const rootReducer = combineReducers({
	auth: authReducer,
	userPreferences: userPreferencesReducer,
	investmentsOverview: investmentsOverviewReducer,
})

// Store
export const store = configureStore({
	reducer: persistReducer(
		{
			key: "root",
			version: 5,
			storage,
			migrate: createMigrate(migrations, { debug: false }),
		},
		rootReducer,
	),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
		}),
})

// Persistor
export const persistor = persistStore(store)
