// Translations
import { useLang } from "@/context/lang"
import { useTrans } from "@/i18n"
import { Languages, configs } from "@/i18n/config"

// Auth context
import { useAuth, AuthStateStatusEnum } from "@/context/auth"

// UI
import { classNames } from "@/lib/classnames"
import { Select } from "./form-controls"

// GraphQL
import { Language, useUpdateUserLanguageMutation } from "@/api/graphql"

/**
 * LanguageSelector
 * @param param0
 * @returns
 */
export const LanguageSelector = ({ className }: { className?: string }) => {
	const { config, lang, setLang } = useLang()
	const t = useTrans("common")

	// Context
	const auth = useAuth()

	// Mutations
	const updateUserLanguage = useUpdateUserLanguageMutation()

	/**
	 * handleLanguageChange
	 * @param event
	 */
	function handleLanguageChange(event: React.FormEvent<HTMLSelectElement>) {
		// Set lang internally
		setLang(event.currentTarget.value as Languages)

		// Update user language in the API
		if (auth.status === AuthStateStatusEnum.LOGGED_IN) {
			updateUserLanguage.mutate({
				input: {
					// TODO: The allowed language types should be the enum provided by the API
					language:
						event.currentTarget.value.toUpperCase() as Language,
				},
			})
		}
	}

	return (
		<label
			className={classNames(
				className,
				"relative flex items-center px-4 py-2 text-sm text-gray-700",
			)}
		>
			{t("common:common.layout.quick_menu.language", {
				lang: config.display,
			})}
			<Select
				className="absolute left-0 top-0 h-full w-full cursor-pointer p-0 opacity-0"
				onChange={(event) => handleLanguageChange(event)}
				value={lang}
				data-testid="language-selector"
			>
				{Object.values(configs).map((config) => (
					<option value={config.path} key={config.path}>
						{config.display}
					</option>
				))}
			</Select>
		</label>
	)
}
