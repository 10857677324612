import { FormikContextType } from "formik"

export function handleErrorWithFormik(
	error: Error,
	form: FormikContextType<any>,
) {
	if (error?.message === "Failed to fetch") {
		form.setFieldError("errors.common", "common.form_errors.network_error")
		return
	}
	if (typeof error?.message === "string") {
		form.setFieldError("errors.common", "common.form_errors.internal_error")
		return
	}
	form.setFieldError("errors.common", "common.form_errors.unknown_error")
}
