import { PayOutState } from "@/api/graphql"
import { TableRowCellVariant } from "@/components/table-controls/TableItems"
import { DateTime } from "@/lib/dates"

// What color to give the payout state?
export function getPayoutStateColor(
	state: PayOutState | null | undefined,

	// The format this field is returned in from the API is string:  'yyyy-dd-mm'
	payment_deadline_date: string,
): TableRowCellVariant {
	// Payouts before the Mangopay migration (2024) have state 'null'. We exclude these from the check
	if (state === null) {
		return "neutral"
	} else if (
		DateTime.fromISO(payment_deadline_date) < DateTime.now() &&
		state !== null &&
		state !== PayOutState.PayoutCompleted &&
		state !== PayOutState.TransferCompleted
	) {
		return "invalid"
	} else {
		return "valid"
	}
}
