import { FiChevronDown } from "react-icons/fi"
import { Button } from "../Button"

interface TableSetPageSizeProps {
	pageSizes?: Array<number>
	pageSize: number
	onPageSize: (pageSize: number) => void
}

export const TableSetPageSize = ({
	pageSize,
	onPageSize,
	pageSizes = [10, 20, 30, 40, 50],
}: TableSetPageSizeProps) => {
	return (
		<label className="relative">
			<Button
				size="small"
				variant="transparent"
				className="text-gray-500 hover:text-gray-900"
			>
				{pageSize}
				<FiChevronDown className="ml-2" />
				<select
					className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
					onChange={(evt) =>
						onPageSize(parseInt(evt.currentTarget.value))
					}
					value={pageSize ?? undefined}
					data-testid="tablesetpagesize"
				>
					{pageSizes.map((item) => (
						<option value={item} key={item}>
							{item}
						</option>
					))}
				</select>
			</Button>
		</label>
	)
}
