import { memo, useMemo } from "react"

// UI
import { CardBody } from "@/components/Card"
import { SearchInput } from "@/components/form-controls/Input"
import { Heading, Subheading } from "@/components/Typography"

// Table
import {
	CellProps,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
	UseTableOptions,
} from "react-table"
import { TableEmptyState } from "@/components/table-controls/TableEmptyState"
import {
	Table,
	TableBody,
	TableDataCell,
	TableFooter,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import { TableWarning } from "@/components/table-controls/TableWarning"
import { TablePagination } from "@/components/table-controls/TablePagination"

import { useLang } from "@/context/lang"

// Queries
import { PaymentEntryType } from "@/api/graphql"

import { useTrans } from "@/i18n"
import { normaliseString } from "@/lib/js"

// Hooks
import { useCurrentPaymentDetail } from "../PaymentDetailProjectOwner"
import { emptyValue } from "@/misc/helpers"

export function generateTableRowId(
	row: Pick<PaymentEntryType, "name" | "supplier_account">,
	suffix = "tablerow",
) {
	return `${suffix}-${row.supplier_account}-${normaliseString(
		row.name ?? "",
	)}`
}

/**
 * PaymentOverviewForProjectOwner
 * @returns
 */
export const PaymentOverviewForProjectOwner = memo(() => {
	// Translations
	const t = useTrans(["common", "payments"])
	const { formatCurrency, formatNumber } = useLang()

	// Queries
	const { data } = useCurrentPaymentDetail()

	const columns = useMemo(() => {
		const columns: UseTableOptions<PaymentEntryType>["columns"] = [
			{
				Header: t("payments:payments.overview.header.number_of_shares"),
				accessor: "share_count",

				Footer: (info) => {
					const total = useMemo(
						() =>
							info.rows.reduce(
								(sum, row) => row.values.share_count + sum,
								0,
							),
						[info.rows],
					)

					return (
						<>
							<Subheading className="mb-2">
								{t(
									"payments:payments.overview_project_owner.footer.total_shares",
								)}
							</Subheading>
							<p className="text-sm font-medium text-gray-700">
								{formatNumber(total)}
							</p>
						</>
					)
				},
			},
			{
				Header: t("payments:payments.overview.header.name"),
				accessor: "name",
			},
			{
				Header: t("payments:payments.overview.header.energy_generated"),
				accessor: "production",
				Cell: ({ value }) => (
					<>{`${value ? formatNumber(value) : ""} kWh`}</>
				),
			},
			{
				Header: t("payments:payments.overview.header.interest"),
				accessor: "cost",
				Cell: ({ value }) => <>{formatCurrency(value || 0)}</>,
			},
			{
				Header: t("payments:payments.overview.header.amortization"),
				accessor: "amortization",
				Cell: ({ value }) => <>{formatCurrency(value || 0)}</>,
			},
			{
				Header: t("payments:payments.overview.header.settlement"),
				accessor: "repayment",
				Cell: ({ value }) => <>{formatCurrency(value || 0)}</>,
			},
			{
				Header: t("payments:payments.overview.header.sum"),
				id: "sum",
				accessor: (data) =>
					(data.cost ?? 0) +
					(data.amortization ?? 0) +
					(data.repayment ?? 0),
				Cell: ({ value }: CellProps<PaymentEntryType, number>) => (
					<>{formatCurrency(value)}</>
				),
				Footer: (info) => {
					const total = useMemo(
						() =>
							info.rows.reduce(
								(sum, row) => row.values.sum + sum,
								0,
							),
						[info.rows],
					)

					return (
						<>
							<Subheading className="mb-2">
								{t(
									"payments:payments.overview_project_owner.footer.total_sum",
								)}
							</Subheading>
							<p className="text-sm font-medium text-gray-700">
								{total ? formatCurrency(total) : emptyValue()}
							</p>
						</>
					)
				},
			},
		]

		return columns
	}, [t, formatCurrency, formatNumber])

	const tableData =
		data?.interest_payments?.results?.[0]?.payment_entries ?? []

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		footerGroups,
		page,
		rows,
		setPageSize,
		state: { pageSize, pageIndex },
		prepareRow,
		gotoPage,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = useTable(
		{
			columns,
			data: tableData as PaymentEntryType[],
			defaultColumn: {
				Cell: ({
					value,
				}: CellProps<PaymentEntryType, string | number>) => (
					<TableDataCell>{value}</TableDataCell>
				),
			},
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
	)

	return (
		<>
			<CardBody>
				<div className="flex flex-1 flex-col space-y-4 sm:flex-row sm:items-center sm:space-y-0">
					<Heading as="h2" styleAs="h5" className="sm:truncate">
						{t("payments:payments.overview_project_owner.title", {
							projectOwner:
								data?.interest_payments?.results?.[0]?.project
									?.owner?.name,
						})}
					</Heading>
					<div className="sm:order-3 sm:ml-auto">
						<SearchInput
							onChange={(evt) =>
								setGlobalFilter(evt.currentTarget.value)
							}
							label={t("payments:payments.search.placeholder")}
							className="md:width-auto min-w-full"
						/>
					</div>
				</div>
			</CardBody>
			<div className="md:hidden">
				<TableWarning
					message={t("common:common.table.mobile_warning.copy")}
				/>
			</div>
			<Table
				{...getTableProps({
					className: "min-w-[64rem] lg:min-w-0",
				})}
			>
				<TableHead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<TableHeading
									{...column.getHeaderProps({
										...column.getSortByToggleProps(),
										className: (column as any).className,
									})}
									isSorted={column.isSorted}
									isSortedDesc={column.isSortedDesc}
								>
									{column.render("Header")}
								</TableHeading>
							))}
						</tr>
					))}
				</TableHead>
				{/* table body and table cells */}
				<TableBody {...getTableBodyProps()} data-testid="tablebody">
					{page.map((row) => {
						prepareRow(row)

						return (
							<TableRowCell
								isOdd={row.index % 2 === 0}
								data-testid={generateTableRowId(row.original)}
								{...row.getRowProps()}
							>
								{row.cells.map((cell) => {
									return (
										<TableDataCell {...cell.getCellProps()}>
											{cell.render("Cell")}
										</TableDataCell>
									)
								})}
							</TableRowCell>
						)
					})}
					{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
					{page.length < pageSize && pageIndex !== 0 ? (
						<>
							{Array(Math.max(pageSize - page.length, 1))
								.fill(true)
								.map((_, index) => (
									<TableRowCell
										key={index}
										withHover={false}
										isOdd={index % 2 === 0}
									>
										<TableDataCell colSpan={columns.length}>
											<span className="dummy-text" />
										</TableDataCell>
									</TableRowCell>
								))}
						</>
					) : null}
				</TableBody>
				<TableFooter>
					{footerGroups.map((group) => (
						<tr {...group.getFooterGroupProps()}>
							{group.headers.map((column) => {
								return (
									<TableDataCell
										{...column.getFooterProps({
											className: "text-gray-600",
										})}
										data-testid={`footer-${column.id}`}
									>
										{column.render("Footer")}
									</TableDataCell>
								)
							})}
						</tr>
					))}
				</TableFooter>
			</Table>
			{page.length === 0 && (
				<CardBody>
					<TableEmptyState>
						{t(
							"payments:payments.overview_project_owner.empty_state",
						)}
					</TableEmptyState>
				</CardBody>
			)}
			{rows.length !== 0 && (
				<CardBody>
					<TablePagination
						amountOfRows={rows.length}
						pageIndex={pageIndex}
						pageSize={pageSize}
						previousPage={previousPage}
						gotoPage={gotoPage}
						canPreviousPage={canPreviousPage}
						canNextPage={canNextPage}
						nextPage={nextPage}
						setPageSize={setPageSize}
						totalNumberOfItems={
							data?.interest_payments?.results?.[0]
								?.payment_entries?.length
						}
					/>
				</CardBody>
			)}
		</>
	)
})
