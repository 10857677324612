import type { DetailedHTMLProps, ButtonHTMLAttributes, ReactNode } from "react"
import { Link, LinkProps } from "@/lib/router"
import { classNames } from "@/lib/classnames"

export type ButtonVariants = keyof typeof VARIANT_CLASSES
export type ButtonSizes = keyof typeof SIZE_CLASSES

const VARIANT_CLASSES = {
	primary: `bg-primary-500 hover:bg-primary-600 focus:ring-primary-500 text-dark border-primary-500 hover:border-primary-600`,
	secondary: `bg-secondary-500 hover:bg-secondary-700 hover:border-secondary-700 focus:ring-secondary-500 text-white`,
	disabled: `bg-gray-200 text-black border-gray-200`,
	transparent: `bg-white hover:bg-gray-50 focus:ring-primary-500 border-gray-200 text-gray-500 hover:text-gray-900 text-dark`,
	empty: `bg-transparent border-0 text-gray-500 hover:text-gray-900 shadow-none text-dark`,
	error: `bg-red-500 hover:bg-red-600 focus:ring-red-500 text-white`,
	dark: `bg-gray-700 hover:bg-black focus:ring-gray-800 text-white`,
}

const DISABLED_CLASSES: {
	[key in ButtonVariants | "disabled"]?: string
} = {
	disabled: `bg-gray-300 hover:bg-gray-300 focus:ring-gray-300 border-gray-300`,
	empty: `bg-transparent focus:ring-gray-300 text-gray-300 shadow-none`,
}

const SIZE_CLASSES = {
	regular: "px-4 py-2 text-base",
	small: "px-4 py-2 text-sm",
	xsmall: "px-4 py-2 text-xs",
	xxsmall: "px-2 py-2 text-xs",
	pill: "px-2 py-1 text-xs",
}

const getClassName = ({
	disabled,
	variant,
	className,
}: {
	disabled?: boolean
	variant: ButtonVariants
	size: ButtonSizes
	className?: string
}) => {
	// if it's disabled, we check if there are disabled class names
	// otherwise we grab the variant
	const styleClass = disabled
		? DISABLED_CLASSES[variant] ?? DISABLED_CLASSES.disabled
		: VARIANT_CLASSES[variant]

	const classNameRoot = classNames(
		"overflow-hidden",
		"inline-flex",
		"border",
		"rounded-md shadow-sm",
		"font-medium",
		"transition",
		"focus:outline-none focus:ring-2 focus:ring-offset-2",
		styleClass,
	)

	return classNames(className, classNameRoot)
}

export interface ButtonProps
	extends Omit<
		DetailedHTMLProps<
			ButtonHTMLAttributes<HTMLButtonElement>,
			HTMLButtonElement
		>,
		"className" | "ref" | "size"
	> {
	variant?: ButtonVariants
	size?: ButtonSizes
	className?: string
	componentRight?: ReactNode
}

/**
 * Button
 * @param param0
 * @returns
 */
export const Button = ({
	componentRight,
	className = "",
	variant = "primary",
	size = "regular",
	children,
	...props
}: ButtonProps) => {
	const classNameRoot = getClassName({
		disabled: props.disabled,
		variant,
		size,
		className,
	})

	return (
		<button className={classNameRoot} {...props}>
			{/** Button content */}
			<div
				className={classNames(
					"inline-flex items-center justify-center",
					SIZE_CLASSES[size],
				)}
			>
				{children}
			</div>

			{/** Render component inside button on the right */}
			{componentRight !== undefined && <>{componentRight}</>}
		</button>
	)
}

interface ButtonLinkProps extends Omit<LinkProps, "className"> {
	variant?: ButtonVariants
	size?: ButtonSizes
	className?: string
	disabled?: boolean
}

export const ButtonLink = ({
	className = "",
	variant = "primary",
	size = "regular",
	children,
	...props
}: ButtonLinkProps) => {
	const classNameRoot = getClassName({
		disabled: props.disabled,
		variant,
		size,
		className,
	})

	return (
		<Link className={classNameRoot} {...props}>
			{children}
		</Link>
	)
}

export interface SubmitButtonProps extends ButtonProps {
	className?: string
	isSubmitting?: boolean
	disableWhenPristine?: boolean
}

export const SubmitButton = ({
	className,
	children,
	isSubmitting,
	...props
}: SubmitButtonProps) => (
	<Button
		type="submit"
		className={classNames(
			className,
			isSubmitting && "relative overflow-hidden",
		)}
		{...props}
	>
		{isSubmitting ? (
			<>
				<span className="relative z-10">{children}</span>
				<ProgressIndicator />
			</>
		) : (
			children
		)}
	</Button>
)

const ProgressIndicator = () => (
	<div
		data-testid="progress-indicator"
		className="animate-conveyor-slow pointer-events-none absolute left-0 top-0 h-full"
		style={{
			width: `calc(100% + 40px)`,
			background: `repeating-linear-gradient(
				45deg,
				rgba(255,255,255,0.5),
				rgba(255,255,255,0.5) 10px,
				rgba(255,255,255,0) 10px,
				rgba(255,255,255,0) 20px
			)`,
		}}
	/>
)
