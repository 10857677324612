// React
import { Fragment, useMemo, ReactNode } from "react"

// Dates
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/misc/constants"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"
import { emptyValue } from "@/misc/helpers"

// Queries
import { useAllInvestorPaymentsQuery } from "@/api/graphql"

// Tables
import {
	CellProps,
	ColumnInstance,
	useExpanded,
	useGlobalFilter,
	useSortBy,
	useTable,
	UseTableOptions,
} from "react-table"
import {
	TableBody,
	TableDataCell,
	TableHead,
	TableRowCell,
	TableHeading,
} from "@/components/table-controls/TableItems"

// UI
import { classNames } from "@/lib/classnames"
import { Heading } from "@/components/Typography"

// Types
import { PaymentEntryTypeExtended } from "../../Payments"

// Utils
import { getPayoutStateColor } from "../utils"

/**
 * PayoutsTablePrint
 * @returns
 */
export function PayoutsTablePrint() {
	// Translations
	const t = useTrans("project")
	const { formatCurrency, formatNumber } = useLang()

	// Queries
	const { data } = useAllInvestorPaymentsQuery({
		ordering: "-payment__interestperiod__end", // Uses internal python model structure
		limit: null, // This will return alls results
	})

	// Memo
	const investments = useMemo(() => {
		return data?.me?.payment_entries?.results?.map((payment) => {
			// Return payments
			return {
				...payment,
				state_color: getPayoutStateColor(
					payment?.state,
					payment?.interest_period?.payment_deadline_date,
				),
			}
		})
	}, [data?.me?.payment_entries?.results])

	// Table Data
	const columns = useMemo(() => {
		const cols: UseTableOptions<PaymentEntryTypeExtended>["columns"] = [
			{
				id: "interest_period.start",
				accessor: (data) =>
					DateTime.fromISO(data.interest_period?.start).toFormat(
						dateFormat,
					),
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t(
							"project:project.revenue.table.headers.interest_period",
						)}
					</DefaultTableHeader>
				),
			},
			{
				id: "payment_deadline_date",
				accessor: (data) =>
					DateTime.fromISO(
						data?.interest_period?.payment_deadline_date,
					).toFormat(dateFormat) || emptyValue(),
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t(
							"project.revenue.table.headers.payment_deadline_date",
						)}
					</DefaultTableHeader>
				),
			},
			{
				id: "project.name",
				accessor: (data) => data?.project?.name,
				Cell: ({ value }: { value: string }) => (
					<TableDataCell className="font-medium">
						{value}
					</TableDataCell>
				),
				Header: () => (
					<TableHeading variant="static">
						{t("project:project.revenue.table.headers.project")}
					</TableHeading>
				),
			},
			{
				id: "transaction_id",
				accessor: (data) => data?.transaction_id,
				Cell: ({ value }: { value: string }) => (
					<TableDataCell className="font-medium">
						{value}
					</TableDataCell>
				),
				Header: () => (
					<TableHeading variant="static">
						{t(
							"project:project.revenue.table.headers.transaction_id",
						)}
					</TableHeading>
				),
			},
			{
				id: "share_count",
				accessor: (data) =>
					data?.share_count
						? formatNumber(data.share_count)
						: emptyValue(),
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t(
							"project:project.revenue.table.headers.solar_shares",
						)}
					</DefaultTableHeader>
				),
			},
			{
				id: "cost",
				accessor: (data) =>
					data?.cost ? formatCurrency(data.cost) : emptyValue(),
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t("project:project.revenue.table.headers.interest")}
					</DefaultTableHeader>
				),
			},
			{
				id: "amortization",
				accessor: (data) =>
					data?.amortization
						? formatCurrency(data.amortization)
						: emptyValue(),
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t(
							"project:project.revenue.table.headers.amortization",
						)}
					</DefaultTableHeader>
				),
			},
			{
				id: "repayment",
				accessor: (data) =>
					data?.repayment
						? formatCurrency(data.repayment)
						: emptyValue(),
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t(
							"project:project.revenue.table.headers.compensation",
						)}
					</DefaultTableHeader>
				),
			},
			{
				id: "paymentSum",
				accessor: (data) =>
					Number(data?.cost) +
					Number(data?.amortization) +
					Number(data?.repayment),
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t("project:project.revenue.table.headers.payment")}
					</DefaultTableHeader>
				),
				Cell: ({ value }: { value: number }) => (
					<TableDataCell>
						{value ? formatCurrency(value) : emptyValue()}
					</TableDataCell>
				),
			},
			{
				id: "project.type",
				accessor: (data) => data?.project?.type,
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t(
							"project:project.revenue.table.headers.project_type",
						)}
					</DefaultTableHeader>
				),
				Cell: ({ value }: { value: number }) => (
					<TableDataCell>
						{t(`common:common.project.type.${value}`)}
					</TableDataCell>
				),
			},
			{
				id: "project.amortization_schedule",
				accessor: (data) => data?.project?.amortization_schedule,
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t(
							"project:project.revenue.table.headers.project_amortization_schedule",
						)}
					</DefaultTableHeader>
				),
				Cell: ({ value }: { value: number }) => (
					<TableDataCell>
						{t(
							`common:common.project.amortization_schedule.${value}`,
						)}
					</TableDataCell>
				),
			},
		]

		return cols.filter(Boolean)
	}, [])
	const {
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		visibleColumns,
	} = useTable(
		{
			columns,
			data: investments as PaymentEntryTypeExtended[],
			defaultColumn: {
				Cell: ({
					value,
				}: CellProps<PaymentEntryTypeExtended, string | number>) => (
					<TableDataCell className="break-word whitespace-pre-wrap">
						{value}
					</TableDataCell>
				),
			},
		},
		useGlobalFilter,
		useSortBy,
		useExpanded,
	)

	return (
		<>
			<div
				className={
					"grid-rows-[minmax(20px,1fr)_1fr] rounded-md bg-gray-50 px-4 py-3"
				}
			>
				<Heading as="h3">
					{t("project:project.revenue.all.title")}
				</Heading>
			</div>
			{/** Using default HTML table instead of TableItems to prevent scrollbar */}
			<table className="w-full">
				<TableHead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => {
								const { key } = column.getHeaderProps()

								return (
									<Fragment key={key}>
										{column.render("Header")}
									</Fragment>
								)
							})}
						</tr>
					))}
				</TableHead>
				<TableBody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row)

						const { key, ...rest } = row.getRowProps({
							...row.getToggleRowExpandedProps(),
						})

						const isOdd = row.index % 2 === 0
						let className = ""

						return (
							<Fragment key={row.id}>
								<TableRowCell
									{...rest}
									isOdd={isOdd}
									variant={row.original.state_color}
									className={classNames(className)}
								>
									{row.cells.map((cell) => {
										const { key } = cell.getCellProps({
											className:
												cellClassNameMap[
													cell.column.id
												],
										})
										return (
											<Fragment key={key}>
												{cell.render("Cell")}
											</Fragment>
										)
									})}
								</TableRowCell>
								<TableRowCell
									isOdd
									withHover={false}
									className="p-0"
								>
									<td
										colSpan={visibleColumns.length}
										className="p-0"
									></td>
								</TableRowCell>
							</Fragment>
						)
					})}
				</TableBody>
			</table>
		</>
	)
}

const cellClassNameMap: { [key: string]: string } = {
	expander: "w-10",
}
const DefaultTableHeader = ({
	column,
	children,
}: {
	column: ColumnInstance<PaymentEntryTypeExtended>
	children: ReactNode
}) => {
	return (
		<TableHeading
			{...column.getHeaderProps(
				column.getSortByToggleProps({
					// @ts-ignore
					isSorted: column.isSorted,
					isSortedDesc: column.isSortedDesc,
				}),
			)}
		>
			{children}
		</TableHeading>
	)
}
