import { Route, Routes } from "react-router-dom"
import { ProfileSecurityIndex } from "../components/ProfileSecurityIndex"
import { ProfileSecurityVerifyCode } from "../components/ProfileSecurityVerifyCode"
import { ProfileSecurityBackupCodes } from "../components/ProfileSecurityBackupCodes"
import { NotFound } from "@/pages/NotFound"

export function ProfileSecurity() {
	return (
		<Routes>
			<Route path="verify" element={<ProfileSecurityVerifyCode />} />
			<Route
				path={"regenerate-backup-codes"}
				element={<ProfileSecurityBackupCodes />}
			/>
			<Route path="/" element={<ProfileSecurityIndex />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}
