import { CSSProperties, ReactElement, useCallback, useState } from "react"
import { QuestionMarkCircleIcon } from "@heroicons/react/solid"
import { classNames } from "@/lib/classnames"

const defaultMargin = 16

interface CustomCssVar extends CSSProperties {
	"--before-left": string
}

interface TooltipProps {
	text: string
	targetClassNamesProp?: string
	children?: ReactElement
}

export const Tooltip = ({
	text,
	targetClassNamesProp,
	children,
}: TooltipProps) => {
	const [style, setStyle] = useState<CustomCssVar>({
		"--before-left": "0px",
	})

	const handleRef = useCallback((node: HTMLDivElement) => {
		if (node) {
			const styles = window.getComputedStyle(node, ":before")

			const tooltipWidth = parseInt(styles?.width || "0", 10)
			const tooltipHalfWidth = tooltipWidth / 2

			if (tooltipHalfWidth > node.offsetLeft) {
				setStyle({
					"--before-left": `${
						defaultMargin + tooltipHalfWidth - node.offsetLeft
					}px`,
				})
			}
		}
	}, [])

	return (
		<div
			className={classNames(
				targetClassNamesProp,
				"ml-1 inline-block w-[1.5em] space-x-3 space-y-3 align-middle text-sm",
			)}
		>
			<div>
				<div
					style={style}
					ref={handleRef}
					className="relative before:invisible 
					before:absolute before:-top-3 before:left-[var(--before-left)] before:z-10 
					before:w-max before:max-w-[12rem] before:max-w-xs before:-translate-x-1/2 before:-translate-y-full 
					before:rounded-lg before:bg-gray-700 before:px-2 before:py-1.5 
					before:text-sm before:text-white before:transition-all before:content-[attr(data-tip)] after:invisible 
					after:absolute after:-top-3 after:left-1/2 after:z-10 
					after:h-0 after:w-0 after:-translate-x-1/2 after:border-8 after:border-b-transparent after:border-l-transparent after:border-r-transparent 
					after:border-t-gray-700 after:transition-all hover:before:visible 
					hover:after:visible sm:before:max-w-sm before:sm:text-base"
					data-tip={text}
				>
					{children ? children : <QuestionMarkCircleIcon />}
				</div>
			</div>
		</div>
	)
}
