import { useEffect, useRef } from "react"
import OdometerJSGenerator from "@/lib/odometer"
import "./odometer.css"

const OdometerJS = OdometerJSGenerator() as any

type OdometerProps = {
	unit?: "wh" | "kwh" | "mwh"
	animation?: boolean
	auto?: boolean
	duration?: number
	format?: string
	selector?: string
	theme?: string
	value: number
}

export function Odometer({ unit = "mwh", value, ...props }: OdometerProps) {
	const odometer = useRef<any>(null!)
	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (!odometer.current) {
			odometer.current = new OdometerJS({
				el: ref.current,
				...props,
			})
		}
		odometer.current.update(value)
	}, [])

	useEffect(() => {
		if (odometer.current && typeof odometer.current.update === "function") {
			odometer.current.update(value)
		}
	}, [value])

	return (
		<div className="flex flex-col items-center">
			<div className="rounded-2rem text-brandgray-700 flex bg-gray-100 p-4 shadow-lg">
				<div ref={ref} />
				<div className="flex items-center text-[22px] font-bold">
					{unit}
				</div>
			</div>
		</div>
	)
}
