import { AnimatePresence, motion, useReducedMotion } from "@/lib/animations"
import { createContext, ReactNode, useContext, useMemo, useState } from "react"
import { Toast, ToastProps } from "@/components/Toast"
import { useIsMounted } from "@/hooks/useIsMounted"

interface ToastType {
	id: string
	role?: string
	variant?: ToastProps["variant"]
	text: string
	timing?: number
}

export interface ToastContextType {
	toasts: Array<ToastType>
	addToast(newToast: ToastType): void
	removeToast(id: ToastType["id"]): void
}

const ToastContext = createContext<ToastContextType>(null!)

export const useToasts = () => useContext(ToastContext)

export function ToastProvider({
	children,
}: Partial<ToastContextType> & {
	children: ReactNode
}) {
	const isMounted = useIsMounted()
	const [toasts, setToasts] = useState<Array<ToastType>>([])

	function addToast(toast: ToastType) {
		const newToast = { ...toast, timing: toast.timing ?? 3500 }
		if (toasts.find((toast) => toast.id === newToast.id)) {
			return
		}
		requestAnimationFrame(() => {
			setToasts((toasts) => [...toasts, newToast])
			setTimeout(() => {
				if (!isMounted.current) return
				requestAnimationFrame(() => {
					removeToast(newToast.id)
				})
			}, newToast.timing)
		})
	}

	function removeToast(id: ToastType["id"]) {
		setToasts((toasts) => toasts.filter((t) => t.id !== id))
	}

	return (
		<ToastContext.Provider value={{ toasts, addToast, removeToast }}>
			{useMemo(() => children, [children])}
		</ToastContext.Provider>
	)
}

export function Toasts() {
	const { toasts, removeToast } = useToasts()
	const isReducedMotion = useReducedMotion()

	return (
		<AnimatePresence>
			{toasts
				.filter(
					(value, index, self) =>
						self.map((x) => x.id).indexOf(value.id) === index,
				)
				.map((toast) => (
					<motion.div
						key={toast.id}
						initial={{
							opacity: 0,
							y: isReducedMotion ? 0 : 50,
							scale: isReducedMotion ? 1 : 0.95,
						}}
						animate={{ opacity: 1, y: 0, scale: 1 }}
						exit={{
							opacity: 0,
							y: isReducedMotion ? 0 : 20,
							scale: isReducedMotion ? 1 : 0.95,
							transition: {
								scale: {
									ease: "linear",
								},
							},
						}}
						role={toast.role ?? "status"}
					>
						<Toast
							text={toast.text}
							variant={toast.variant}
							timing={toast.timing}
							onClose={() => removeToast(toast.id)}
						/>
					</motion.div>
				))}
		</AnimatePresence>
	)
}
