// React
import {
	createContext,
	Suspense,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react"

// Animations
import { motion } from "@/lib/animations"

// Analytics
import { generateSendEventDebounced, sendEvent } from "@/lib/analytics"

// UI
import { classNames } from "@/lib/classnames"
import { ProjectCard, ProjectCardPreloader } from "@/components/ProjectCard"
import {
	InvestmentsTable,
	InvestmentsTableLoadingState,
} from "./_components/InvestmentsTable"
import { Heading } from "@/components/Typography"
import {
	PaginationAsButtons,
	PaginationAsButtonsLoadingState,
} from "@/components/PaginationAsButtons"
import { Button } from "@/components/Button"
import { Card } from "@/components/Card"
import { SearchInput } from "@/components/form-controls/Input"
import { Select } from "@/components/form-controls"
import { Checkbox } from "@/components/form-controls/Checkbox"
import { ErrorBoundaryWithErrorState } from "@/components/errors/ErrorBoundary"

// Icons
import { FiArrowDown, FiChevronDown } from "@/lib/icons"

// GraphQL
import { useInvestmentsGridViewQuery } from "@/api/graphql"

// State
import { useSelector, useDispatch } from "@/state/StateProvider"
import {
	setFilterState,
	setFilterType,
	setPerPage,
	setViewType,
	setSortingMethod,
	setSortingOrder,
	setShowHiddenProjects,
} from "@/state/features/investmentsOverview/slice"

// Translations
import { useTrans } from "@/i18n"

// Types
import {
	InvestmentsOverviewState,
	projectPageSizes,
	projectPageViewTypes,
	ProjectPageViewTypes,
	ProjectSortOrder,
	ALL_PROJECT_STATES,
	ALL_PROJECT_TYPES,
	ProjectStateEnumFiltered,
	ProjectTypeEnumFiltered,
	ProjectPageSize,
	ProjectSortMethod,
} from "@/state/features/investmentsOverview/types"
interface InvestmentsProps {
	className?: string
}

const onSearchSendEvent = generateSendEventDebounced(1000)

export const InvestmentsContext = createContext<{
	search: string
	setSearch: (search: string) => void
}>(null!)

/**
 * Investments
 * @param param0
 * @returns
 */
export const Investments = ({ className = "" }: InvestmentsProps) => {
	const t = useTrans(["common", "investments"])

	// State
	const [search, setSearch] = useState("")

	// Redux state
	const { viewType } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<InvestmentsContext.Provider
			value={{
				search,
				setSearch,
			}}
		>
			<div
				className={`${className} flex flex-1 flex-col space-y-4 sm:flex-row sm:space-y-0`}
				data-testid="myinvestments"
			>
				{/** Search bar */}
				<div className="sm:order-3 sm:ml-auto">
					<SearchInput
						onChange={(evt) => {
							onSearchSendEvent("investments", "on_search", {
								label: evt.currentTarget.value,
							})
							setSearch(evt.currentTarget.value)
						}}
						label={t("investments:investments.search.placeholder")}
						className="md:width-auto min-w-full"
					/>
				</div>
				<div className="order-1 flex-row space-y-4 sm:flex md:space-x-4 md:space-y-0">
					<div className="flex space-x-4">
						{/** Sort order */}
						<ButtonSorting />

						{/** Filter on project type */}
						<ButtonFilterProjectType />

						{/** Filter on project status */}
						<ButtonFilterProjectState />
					</div>

					{/** Set page size */}
					<ButtonPageSize />

					{/** Swith table views */}
					<ButtonViewType />

					{/** Show hidden projects */}
					<CheckboxShowHidden />
				</div>
			</div>
			<ErrorBoundaryWithErrorState errorBoundaryClassName="mt-5">
				{viewType === "grid" ? (
					<Suspense fallback={<InvestmentsGridLoadingState />}>
						<InvestmentsGrid />
					</Suspense>
				) : null}

				{viewType === "table" ? (
					<Suspense fallback={<InvestmentsTableLoadingState />}>
						<InvestmentsTable />
					</Suspense>
				) : null}
			</ErrorBoundaryWithErrorState>
		</InvestmentsContext.Provider>
	)
}
/**
 * InvestmentsGrid
 * @returns
 */
function InvestmentsGrid() {
	// Context
	const { search, setSearch } = useContext(InvestmentsContext)

	// Redux state
	const dispatch = useDispatch()
	const {
		filterState,
		filterType,
		sortingMethod,
		sortingOrder,
		perPage: limit,
		hiddenIds,
		showHiddenProjects,
	} = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	// State
	const [currentPage, setCurrentPage] = useState<number>(0)

	// Query
	const { data, isPreviousData } = useInvestmentsGridViewQuery({
		limit,
		offset: currentPage * limit,
		name: search,
		ordering:
			sortingOrder === ProjectSortOrder.Asc
				? sortingMethod
				: `-${sortingMethod}`,
		state: filterState,
		type: filterType,
	})

	// Projects
	const projects = useMemo(
		() =>
			data?.me?.investment_projects?.results?.filter((project) => {
				// Only filter if we are not showing hidden projects
				if (showHiddenProjects === false) {
					const projectId = Number(project?.id)
					return hiddenIds.includes(projectId) === false // If project is not hidden
				}
				return true
			}) ?? [],
		[data?.me?.investment_projects?.results, showHiddenProjects, hiddenIds],
	)

	const onReset = useCallback(() => {
		setSearch("")
		dispatch(setFilterState(ALL_PROJECT_STATES))
		dispatch(setFilterType(ALL_PROJECT_TYPES))
		dispatch(setSortingOrder(ProjectSortOrder.Asc))
		dispatch(setSortingMethod(ProjectSortMethod.Name))
		setCurrentPage(0)
	}, [setSearch, setFilterState, setSortingMethod, setSortingOrder])

	return (
		<>
			<dl
				className={classNames(
					"mt-5 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3",
					isPreviousData && "opacity-50",
				)}
				data-testid="grid"
			>
				{projects.length === 0 && (
					<div className="sm:col-span-2 lg:col-span-1 lg:col-start-2">
						<NoResults onReset={onReset} />
					</div>
				)}
				{projects.map((project) => (
					<div key={`${project?.id}`}>
						<ProjectCard
							id={project?.id}
							analyticsContext="investments"
							className="h-full"
							key={project?.id}
							totalRepayment={
								project?.investor_shares_value_stats
									?.total_repaid_for_project
									? parseFloat(
											project.investor_shares_value_stats
												.total_repaid_for_project,
									  )
									: 0
							}
							hasEnergySupplierMessage={false}
							projectName={project?.name ?? ""}
							amountInvested={
								project?.investor_shares_value_stats
									?.total_investment_for_project
									? parseFloat(
											project.investor_shares_value_stats
												.total_investment_for_project,
									  )
									: 0
							}
							energySupplier={project?.installer?.name ?? ""}
							comingInterestPeriod={
								project?.current_interest_period?.end
									? new Date(
											project.current_interest_period.end,
									  )
									: null
							}
							slug={project?.id ?? ""}
							energySavings={
								project?.investor_production_stats
									?.investor_generated_power_in_kwh
									? parseFloat(
											project.investor_production_stats
												.investor_generated_power_in_kwh,
									  )
									: 0
							}
							status={project?.state}
							type={project?.type}
							image={project?.image_url ?? ""}
						/>
					</div>
				))}
			</dl>
			<div className="mt-6 flex justify-center md:mt-8">
				<PaginationAsButtons
					countPerPage={limit}
					totalCount={data?.me?.investment_projects?.totalCount ?? 0}
					itemType={"common.pagination.item_types.project"}
					currentPage={currentPage + 1}
					currentItemsAmount={projects?.length ?? 0}
					onNextPage={() =>
						setCurrentPage((currentPage) => currentPage + 1)
					}
					onPrevPage={() =>
						setCurrentPage((currentPage) =>
							Math.max(currentPage - 1, 0),
						)
					}
					analyticsId="investments"
				/>
			</div>
		</>
	)
}

function InvestmentsGridLoadingState() {
	return (
		<>
			<dl className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
				{Array(6)
					.fill(true)
					.map((_, index) => (
						<div key={index} data-testid="spinner">
							<ProjectCardPreloader />
						</div>
					))}
			</dl>
			<div className="mt-6 flex justify-center md:mt-8 md:gap-x-4">
				<PaginationAsButtonsLoadingState />
			</div>
		</>
	)
}

const NoResults = ({ onReset }: { onReset: () => void }) => {
	const t = useTrans()

	return (
		<motion.div
			data-testid="investment-item-noresults"
			layoutId="no-results"
			exit={{
				scale: 0.95,
				opacity: 0,
			}}
			animate={{
				scale: 1,
				opacity: 1,
			}}
			initial={{
				scale: 0.95,
				opacity: 0,
			}}
		>
			<Card>
				<div className="space-y-4 text-center">
					<Heading as="h2" styleAs="h5">
						{t("investments:investments.no_results.title")}
					</Heading>
					<p className="text-gray-500">
						{t("investments:investments.no_results.copy")}
					</p>
					<Button onClick={onReset}>
						{t("investments:investments.no_results.button")}
					</Button>
				</div>
			</Card>
		</motion.div>
	)
}

function ButtonSorting() {
	const t = useTrans(["common", "investments"])

	// Redux state
	const dispatch = useDispatch()
	const { sortingMethod, sortingOrder } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<Button
			size="small"
			variant="transparent"
			componentRight={
				<div
					className="inline-flex h-full items-center justify-center bg-gray-100 px-4 py-2"
					onClick={() => {
						const next =
							sortingOrder === ProjectSortOrder.Asc
								? ProjectSortOrder.Desc
								: ProjectSortOrder.Asc
						sendEvent("investments", "on_sort_order", {
							label: next,
						})
						dispatch(setSortingOrder(next))
					}}
				>
					<FiArrowDown
						className={classNames(
							sortingOrder === ProjectSortOrder.Asc &&
								"rotate-180 transform",
						)}
					/>
				</div>
			}
		>
			<div className="relative">
				<label htmlFor="sortingMethod">
					{t(`investments:investments.sort_method.${sortingMethod}`)}
					<Select
						name="sortingMethod"
						className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
						onChange={(evt) => {
							sendEvent("investments", "on_sort_method", {
								label: evt.currentTarget.value,
							})
							dispatch(
								setSortingMethod(
									evt.currentTarget
										.value as ProjectSortMethod,
								),
							)
						}}
						value={sortingMethod ?? undefined}
					>
						{Object.entries(ProjectSortMethod).map(
							([_key, value]) => (
								<option value={value} key={value}>
									{t(
										`investments:investments.sort_method.${value}`,
									)}
								</option>
							),
						)}
					</Select>
				</label>
			</div>
		</Button>
	)
}

function ButtonFilterProjectType() {
	const t = useTrans(["common", "investments"])

	// Redux state
	const dispatch = useDispatch()
	const { filterType } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<div className="relative">
			<label htmlFor="projectType">
				<Button size="small" variant="transparent">
					{filterType.toUpperCase() === ALL_PROJECT_TYPES
						? t("investments:investments.filter.types")
						: t(`common:common.project.type.${filterType}`)}
					<FiChevronDown className={"ml-2"} />
					<Select
						name="projectType"
						className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
						onChange={(evt) => {
							sendEvent("investments", "on_type_filter", {
								label: evt.currentTarget.value,
							})
							dispatch(
								setFilterType(
									evt.currentTarget.value.toUpperCase() as ProjectTypeEnumFiltered,
								),
							)
						}}
						value={filterType ?? undefined}
					>
						<option value={ALL_PROJECT_TYPES}>
							{t("investments:investments.filter.all")}
						</option>
						{Object.entries(ProjectTypeEnumFiltered).map(
							([_key, value]) => (
								<option value={value} key={value}>
									{t(`common:common.project.type.${value}`)}
								</option>
							),
						)}
					</Select>
				</Button>
			</label>
		</div>
	)
}

function ButtonFilterProjectState() {
	const t = useTrans(["common", "investments"])

	// Redux state
	const dispatch = useDispatch()
	const { filterState } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<div className="relative">
			<label htmlFor="projectStatus">
				<Button size="small" variant="transparent">
					{filterState.toUpperCase() === ALL_PROJECT_STATES
						? t("investments:investments.filter.status")
						: t(`common:common.project.status.${filterState}`)}
					<FiChevronDown className={"ml-2"} />
					<Select
						name="projectStatus"
						className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
						onChange={(evt) => {
							sendEvent("investments", "on_status_filter", {
								label: evt.currentTarget.value,
							})
							dispatch(
								setFilterState(
									evt.currentTarget.value.toUpperCase() as ProjectStateEnumFiltered,
								),
							)
						}}
						value={filterState ?? undefined}
					>
						<option value={ALL_PROJECT_STATES}>
							{t("investments:investments.filter.all")}
						</option>
						{Object.entries(ProjectStateEnumFiltered).map(
							([_key, value]) => (
								<option value={value} key={value}>
									{t(`common:common.project.status.${value}`)}
								</option>
							),
						)}
					</Select>
				</Button>
			</label>
		</div>
	)
}

function ButtonPageSize() {
	const t = useTrans(["common", "investments"])

	// Redux state
	const dispatch = useDispatch()
	const { perPage: limit } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<div className="relative">
			<label htmlFor="pageSize w-full">
				<Button size="small" variant="transparent" className="w-full">
					{t("investments:investments.pagination.set_page_size", {
						count: limit,
					})}
					<FiChevronDown className={"ml-2"} />
					<Select
						name="pageSize"
						className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
						onChange={(evt) =>
							dispatch(
								setPerPage(
									Number(
										evt.currentTarget.value,
									) as ProjectPageSize,
								),
							)
						}
						value={limit ?? undefined}
					>
						{projectPageSizes.map((item) => (
							<option value={item} key={item}>
								{item}
							</option>
						))}
					</Select>
				</Button>
			</label>
		</div>
	)
}

function ButtonViewType() {
	const t = useTrans("common")

	// Redux state
	const dispatch = useDispatch()
	const { viewType } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<div className="relative">
			<label htmlFor="pageSize w-full">
				<Button
					size="small"
					variant="transparent"
					className="w-full capitalize"
				>
					{t(`common:common.project.view.${viewType.toUpperCase()}`)}
					<FiChevronDown className={"ml-2"} />
					<Select
						name="pageSize"
						className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
						onChange={(event) =>
							dispatch(
								setViewType(
									event.currentTarget
										.value as ProjectPageViewTypes,
								),
							)
						}
						value={viewType ?? undefined}
					>
						{projectPageViewTypes.map((item) => (
							<option value={item} key={item}>
								{t(
									`common:common.project.view.${item.toUpperCase()}`,
								)}
							</option>
						))}
					</Select>
				</Button>
			</label>
		</div>
	)
}

/**
 * CheckboxShowHidden
 * @returns
 */
function CheckboxShowHidden() {
	const t = useTrans("investments")

	// Redux state
	const dispatch = useDispatch()
	const { showHiddenProjects } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<div className="relative">
			<label
				htmlFor="showHiddenProjects"
				className="focus:ring-primary-500 flex h-full cursor-pointer items-center font-medium capitalize text-gray-500 transition hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2"
			>
				<Checkbox
					id="showHiddenProjects"
					name="showHiddenProjects"
					checked={showHiddenProjects === true}
					onChange={() =>
						dispatch(setShowHiddenProjects(!showHiddenProjects))
					}
					className="mr-2"
				/>
				{t("investments:investments.overview.show-hidden")}
			</label>
		</div>
	)
}
