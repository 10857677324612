import { ReactNode, ReactElement } from "react"
import { AnimatePresence, motion } from "@/lib/animations"
import { YAxisOptions } from "highcharts"
import { DateTime } from "@/lib/dates"
import { useDebouncedState } from "@/hooks/useDebounce"
import { MiniChart } from "@/lib/highcharts"
import { CardBody, CardWrapper } from "./Card"
import { Heading } from "./Typography"

// Graphs
import { LineChart } from "./graphs/types/line/LineChart"

interface MiniGraphProps {
	title: ReactNode
	heading(value: number): ReactElement
	icon(props: { className: string }): ReactElement
	data: Array<{ date: string; value: number }>
	timeFormat?: string
}

export const MiniGraph = ({
	title,
	heading,
	icon: Icon,
	data,
	timeFormat = "LLL dd HH:MM",
}: MiniGraphProps) => {
	const [value, setValue, debouncedSetValue] = useDebouncedState<
		undefined | number
	>(undefined)
	const [label, setLabel, debouncedSetLabel] = useDebouncedState<
		undefined | string
	>(undefined)

	const displayValue = value ?? data[data.length - 1].value
	const displayLabel = label ?? data[data.length - 1].date

	return (
		<CardWrapper
			onMouseLeave={() => {
				debouncedSetValue(undefined)
				debouncedSetLabel(undefined)
			}}
			className="group flex flex-col"
		>
			<div className="px-4 py-5 pr-0 sm:p-6 sm:pb-0">
				<div className="grid-cols-stat-widget grid items-center gap-4">
					<Icon className="group-hover:text-primary-500 w-6 text-gray-400 transition" />
					<div>
						<p className="text-sm text-gray-500">{title}</p>
						<Heading
							className="text-dark flex items-center"
							as="h4"
						>
							{heading(displayValue)}
							<AnimatePresence>
								{displayLabel !== null && value ? (
									<motion.span
										className="ml-2 font-sans text-sm font-normal normal-case text-gray-500"
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
									>
										{DateTime.fromMillis(
											parseInt(displayLabel),
										).toFormat(timeFormat)}
									</motion.span>
								) : null}
							</AnimatePresence>
						</Heading>
					</div>
				</div>
			</div>
			<div className="mt-auto">
				<LineChart
					id={
						typeof title === "string"
							? title.replace(/\s+/g, "-")
							: "minigraph"
					}
					height={100}
					minHeight={100}
					data={data}
					setLabel={setLabel}
					setValue={setValue}
				/>
			</div>
		</CardWrapper>
	)
}

interface MiniGraphStaticProps {
	title: string
	heading: () => ReactElement
	icon: (props: { className: string }) => ReactElement
	data: Array<[number, number]>
	series?: Highcharts.Options["series"]
	yAxis?: YAxisOptions
	yAxisDash?: YAxisOptions
}

export const MiniGraphStatic = ({
	title,
	heading,
	icon: Icon,
	data,
	series = [],
	yAxis = {},
	yAxisDash = {},
}: MiniGraphStaticProps) => {
	return (
		<CardWrapper className="group">
			<CardBody className="pr-0">
				<div className="grid-cols-stat-widget grid items-center gap-4">
					<Icon
						className={`group-hover:text-primary-500 w-6 text-gray-400 transition`}
					/>
					<div>
						<p className="text-sm text-gray-500">{title}</p>
						<Heading className="text-dark" as="h4">
							{heading()}
						</Heading>
					</div>
				</div>
			</CardBody>
			<div>
				<MiniChart
					options={
						{
							tooltip: { enabled: false },
							plotOptions: {
								series: {
									enableMouseTracking: false,
									states: {
										hover: {
											enabled: false,
										},
									},
								},
							},
							yAxis: [
								{
									id: "primary",
									type: "datetime",
									visible: false,
									...yAxis,
								},
								{
									id: "dash",
									type: "datetime",
									min: 0,
									max: 100,
									visible: false,
									...yAxisDash,
								},
							],
							series: [
								{
									animation: false,
									type: "areaspline",
									data,
									yAxis: "primary",
									name: title,
								},
								...series,
							],
						} as Highcharts.Options
					}
				/>
			</div>
		</CardWrapper>
	)
}
