import { useState } from "react"
import { Yup } from "@/lib/yup"
import { useTrans } from "@/i18n"
import { useFormik } from "formik"
import { ApiErrorResponseInterface } from "@/api/rest"
import {
	FormikErrors,
	FormikInput,
	FormikLabel,
	FormikSubmitButton,
	FormikWrapper,
} from "@/components/form-controls/formik"
import { Heading } from "@/components/Typography"
import { FormikError, TextArea } from "@/components/form-controls"
import { Button } from "@/components/Button"
import { VERIFICATION_CODE_LENGTH } from "../TwoFactorAuthSettings"

// Queries
import { apiAuthActivateConfirmCreate } from "@/api/rest/generated/api/api"
import { MethodEnum } from "@/api/rest/generated/@types"

const validationSchema = Yup.object().shape({
	confirmation_code: Yup.string().test(
		"valid-confirmation-code",
		"profile.security.verify_code.code.invalid_verification_code_format",
		(value) => value?.toString().length === VERIFICATION_CODE_LENGTH,
	),
})

interface EmailMethodCodeVerificationProps {
	onSuccess: () => void
	onClose: () => void
}

export const EmailMethodCodeVerification = ({
	onSuccess,
	onClose,
}: EmailMethodCodeVerificationProps) => {
	const t = useTrans("profile")
	const [backupCodes, setBackupCodes] = useState<string[]>()
	const [isFinished, setIsFinished] = useState<boolean>(false)

	const form = useFormik({
		validationSchema,
		initialValues: {
			confirmation_code: "",
		},
		validateOnMount: true,
		onSubmit: async (values, helpers) => {
			try {
				const response = await apiAuthActivateConfirmCreate(
					MethodEnum.email,
					{
						code: values.confirmation_code,
					},
				)

				setIsFinished(true)
				setBackupCodes(response.backup_codes)

				if (!response) {
					helpers.setFieldError(
						"errors.common",
						"form_errors.unknown_error",
					)
					return
				}

				onSuccess()
			} catch (e) {
				const error = e as ApiErrorResponseInterface | null

				// there can be a translatable error message under "code" key returned
				if (error?.json?.code && error?.json?.code.length === 1) {
					helpers.setFieldError(
						"confirmation_code",
						error.json.code.at(0),
					)
				} else if (error?.json?.error) {
					helpers.setFieldError("errors.local", error?.json?.error)
				} else {
					helpers.setFieldError(
						"errors.common",
						"form_errors.unknown_error",
					)
				}
			}
		},
	})

	return isFinished ? (
		<>
			<Heading as="h6" styleAs="h6" className="mb-3 sm:truncate">
				{t("profile.security.two_factor_auth.email.finish.header")}
			</Heading>

			<div className="text-sm text-gray-500 text-gray-700">
				<div className="mb-2 block">
					{t("profile.security.two_factor_auth.email.finish.intro")}
				</div>
				<TextArea
					id="backup_codes"
					rows={backupCodes?.length}
					className="mb-2 block w-full resize-none"
					value={backupCodes?.join("\n")}
					readOnly
				/>
				<div className="mb-2">
					<p>
						{t(
							"profile.security.two_factor_auth.2fa_sms.finish.helper",
						)}
					</p>
				</div>
				<Button onClick={onClose} size="small">
					{t(
						"profile.security.two_factor_auth.2fa_sms.finish.button",
					)}
				</Button>
			</div>
		</>
	) : (
		<FormikWrapper form={form}>
			<Heading as="h6" styleAs="h6" className="mb-4 sm:truncate">
				{t("profile.security.two_factor_auth.email.2fa_code.header")}
			</Heading>

			<div className="text-gray-700">
				<label
					htmlFor="confirmation_code"
					className="mb-4 block text-sm text-gray-500"
				>
					{t("profile.security.two_factor_auth.email.2fa_code.intro")}
				</label>
				<div className="mb-4">
					<FormikLabel
						htmlFor="confirmation_code"
						className="sr-only"
					>
						{t("profile.security.two_factor_auth.2fa_code.label")}
					</FormikLabel>
					<FormikInput
						name="confirmation_code"
						id="confirmation_code"
						className="block w-full lg:w-1/2"
						type="text"
						placeholder={t(
							"profile.security.verify_code.code.placeholder",
						)}
					/>
					<FormikError
						field="confirmation_code"
						namespace="profile"
					/>
				</div>
				<div>
					<FormikErrors i18nNamespace="profile" />
				</div>
			</div>

			<FormikSubmitButton className="lg:ml-auto" size="small">
				{t(
					"profile.security.two_factor_auth.2fa_code.verify_email_address",
				)}
			</FormikSubmitButton>
		</FormikWrapper>
	)
}
