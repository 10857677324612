// SEO meta
import { Helmet } from "@/lib/seo"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// UI
import { PageTemplate } from "@/templates/PageTemplate"
import { GraphLabel, GraphLabels } from "@/components/graphs/GraphLabels"
import { StatsMenuTabs } from "../_components/StatsMenuTabs"
import { Heading } from "@/components/Typography"
import { Card } from "@/components/Card"
import { SolarInterestTariffGraph } from "@/components/graphs/types/area/SolarInterestTariffGraph"

// REST queries
import { useApiV1ComparisonProjectEndexPriceRetrieve } from "@/api/rest/generated/api/api"

// Graphs
import { GlobalSolarInterestTariffGraph } from "../_components/GlobalSolarInterestTariffGraph"

/**
 * AllProjectsSolarInterestTariff
 * @returns
 */
export function AllProjectsSolarInterestTariff() {
	const t = useTrans(["finance", "common"])
	const { formatNumber } = useLang()

	// Rest Query
	const { data: dataEndexPrice } =
		useApiV1ComparisonProjectEndexPriceRetrieve(1) // TODO: Now uses hardcoded projectId because this data is the same for all projects. Could work without

	return (
		<>
			<Helmet>
				<title>
					{t("finance:finance.solar_interest_tariff.title")}
				</title>
			</Helmet>
			<PageTemplate
				title={t("finance:finance.solar_interest_tariff.title")}
				tabs={<StatsMenuTabs />}
			>
				{/** Current Endex */}
				<Card className="mb-8 flex flex-col md:w-1/2">
					<Heading as="h3" styleAs="h6" className="mr-2 lg:mr-0">
						Endex 12-0-12
					</Heading>

					{dataEndexPrice?.price ? (
						<>
							{`${dataEndexPrice?.start} - ${dataEndexPrice?.end}`}
							<div>
								{`€${formatNumber(
									dataEndexPrice?.price / 1000,
									{
										minimumFractionDigits: 6,
										maximumFractionDigits: 6,
									},
								)} per kWh`}
							</div>
						</>
					) : null}
				</Card>

				{/** Endex graph */}
				<Card>
					<Heading as="h3" styleAs="h6" className="mr-2 lg:mr-0">
						{t(
							"finance:finance.solar_interest_tariff.block.endex_tariffs_full_year.title",
						)}
					</Heading>

					<SolarInterestTariffGraph>
						<SolarInterestTariffGraph.Content>
							<GlobalSolarInterestTariffGraph />
						</SolarInterestTariffGraph.Content>
						<SolarInterestTariffGraph.Footer>
							<GraphLabels className="p-3 px-4 sm:p-5 sm:px-6">
								<GraphLabel
									type="line"
									variant="primary"
									title={t(
										"finance:finance.solar_interest_tariff.block.endex_tariffs",
									)}
								/>
							</GraphLabels>
						</SolarInterestTariffGraph.Footer>
					</SolarInterestTariffGraph>
				</Card>
				<Card className="mt-8">
					<Heading as="h3" styleAs="h6" className="mr-2 lg:mr-0">
						{t(
							"finance:finance.solar_interest_tariff.block.info.title",
						)}
					</Heading>
					<p className="whitespace-break-spaces">
						{t(
							"finance:finance.solar_interest_tariff.block.info.copy",
						)}
					</p>
				</Card>
			</PageTemplate>
		</>
	)
}
