import { HTMLProps } from "react"
import { classNames } from "@/lib/classnames"

export const Container = ({
	className = "",
	...props
}: HTMLProps<HTMLDivElement>) => (
	<div
		className={classNames(
			className,
			"mx-auto max-w-7xl px-4 sm:px-6 lg:px-8",
		)}
		{...props}
	/>
)
