export enum UserRoles {
	admin = "admin",
	investor = "investor",
	projectOwner = "projectOwner",
	energyProvider = "energyProvider",
	staff = "staff",
	installer = "installer",
}

export enum Environment {
	production = "production",
	development = "development",
}

export const dateFormat = "dd-MM-yyyy"
export const apiDateFormat = "yyyy-MM-dd"

export const twoFACodeLength = 6

export const graphMinKwhRoundThreshold = 100

export const mapCoordinatesCenterOfNetherlands = {
	lat: "52.271044",
	lng: "5.519243",
}

export const availableLanguages = ["nl", "en"]
export const availableLanguageFiles = [
	"common",
	"config",
	"confirm",
	"d3-time-format",
	"dashboard",
	"dialog",
	"finance",
	"investments",
	"investor",
	"not-found",
	"payments",
	"profile",
	"project",
	"projects",
	"recent",
	"register",
	"sign-in",
]
