export function throttle<Params extends any[]>(
	func: (...args: Params) => any,
	limit: number,
): (...args: Params) => void {
	let inThrottle: boolean

	return (...args: Params) => {
		if (!inThrottle) {
			inThrottle = true
			func(...args)
			setTimeout(() => (inThrottle = false), limit)
		}
	}
}
