// Queries
import { useSingleProjectStatsIrrQuery } from "@/api/graphql"

// UI
import { Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { Tooltip } from "@/components/Tooltip"

// Icons
import { FiInfo } from "@/lib/icons"

// I18n
import { useTrans } from "@/i18n"

/**
 * SingleProjectStatsInternalRateOfReturn
 * @param param0
 * @returns
 */
export function SingleProjectStatsInternalRateOfReturn({
	projectId,
}: {
	projectId: number
}) {
	// I18n
	const t = useTrans("dashboard")

	// Fetch
	const { data } = useSingleProjectStatsIrrQuery({
		id: String(projectId),
	})

	return (
		<Card className="h-full group-hover:bg-gray-50 md:col-span-1 xl:col-span-1">
			<div className="space-y-3">
				<Tooltip
					content={
						<p>
							{t(
								"dashboard.stats.project_stats.blocks.interest.title.tooltip",
							)}
						</p>
					}
				>
					<span className="flex items-center">
						<Heading as="h5" className="text-gray-600">
							{t(
								"dashboard.stats.project_stats.blocks.interest.title",
							)}
						</Heading>
						<FiInfo className="ml-2" />
					</span>
				</Tooltip>

				<div className="grid grid-cols-2 gap-2">
					<dt className="text-sm font-medium text-gray-500">
						{t(
							"dashboard.stats.project_stats.blocks.interest.expected",
						)}
					</dt>
					<dd className="text-right text-sm text-gray-900">
						{data?.project_statistics?.internal_rate_of_return?.toFixed(
							2,
						)}{" "}
						%
					</dd>
					<dt className="text-sm font-medium text-gray-500">
						{t(
							"dashboard.stats.project_stats.blocks.interest.memorandum",
						)}
					</dt>
					<dd className="text-right text-sm text-gray-900">
						{data?.project_statistics?.expected_internal_rate_of_return?.toFixed(
							2,
						)}{" "}
						%
					</dd>
				</div>
			</div>
		</Card>
	)
}

/**
 * SingleProjectStatsInternalRateOfReturnLoading
 * @returns
 */
export function SingleProjectStatsInternalRateOfReturnLoading() {
	// I18n
	const t = useTrans("dashboard")

	return (
		<Card className="h-full group-hover:bg-gray-50 md:col-span-1 xl:col-span-1">
			<div className="space-y-3">
				<Tooltip
					content={
						<p>
							{t(
								"dashboard.stats.project_stats.blocks.interest.title.tooltip",
							)}
						</p>
					}
				>
					<span className="flex items-center">
						<Heading as="h5" className="text-gray-600">
							{t(
								"dashboard.stats.project_stats.blocks.interest.title",
							)}
						</Heading>
						<FiInfo className="ml-2" />
					</span>
				</Tooltip>

				<div className="grid grid-cols-2 gap-2">
					<dt className="text-sm font-medium text-gray-500">
						{t(
							"dashboard.stats.project_stats.blocks.interest.expected",
						)}
					</dt>
					<dd className="text-right text-sm text-gray-900"></dd>
					<dt className="text-sm font-medium text-gray-500">
						{t(
							"dashboard.stats.project_stats.blocks.interest.memorandum",
						)}
					</dt>
					<dd className="text-right text-sm text-gray-900"></dd>
				</div>
			</div>
		</Card>
	)
}
