import locales from "countries-list"
import { CoreLocationCountryChoices } from "@/api/graphql"

export interface Country extends locales.Country {
	code: string
}

// Create a list of countries with their code and name
export const countries = Object.entries(locales.countries).reduce<
	Array<Country>
>((acc, [code, country]) => {
	acc.push({ code, ...country })
	return acc
}, [])

// Check which countries are allowed in the app according to this enum list CoreLocationCountryChoices
export const onlyCoreLocationCountryChoices = countries.filter((country) =>
	Object.values(CoreLocationCountryChoices).includes(
		country.code as CoreLocationCountryChoices,
	),
)
