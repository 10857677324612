import { Helmet } from "@/lib/seo"

// UI
import { Heading } from "@/components/Typography"
import { useToasts } from "@/context/toasts"
import { CardBody, CardFooter, CardWrapper } from "@/components/Card"

// Form
import { FormikProvider, useFormik } from "formik"
import { handleErrorWithFormik } from "@/lib/formik"
import { FormGroup } from "@/components/form-controls/FormGroup"
import {
	FormikCheckbox,
	FormikErrors,
	FormikSubmitButton,
} from "@/components/form-controls/formik"
import { Label } from "@/components/form-controls/Label"

// GraphQL
import {
	useProjectNotificationsQuery,
	useProjectNotificationsUpdateMutation,
} from "@/api/graphql"

import { useTrans } from "@/i18n"
import { useCurrentProjectId } from "../hooks/useCurrentProjectId"

export const ProjectNotifications = () => {
	const toasts = useToasts()
	const t = useTrans("project")
	const id = useCurrentProjectId()
	const { data, refetch } = useProjectNotificationsQuery({ id: String(id) })

	// Mutation
	const updateProjectNotifications = useProjectNotificationsUpdateMutation({
		onSuccess: async (response) => {
			// do we have data? then success
			if (response?.investor_update) {
				toasts.addToast({
					variant: "success",
					id: `project-notifications-success-${Date.now()}`,
					text: t(`project.notifications.success_message`),
				})
				await refetch()
			}
			// otherwise show a message that there are form errors
			else {
				form.setFieldError(
					"errors.common",
					"common.form_errors.unknown_error",
				)
			}
		},
		onError: (error: Error) => {
			handleErrorWithFormik(error, form)
		},
	})

	// Form
	const form = useFormik({
		initialValues: {
			status: data?.project?.investor_yearly_report_notification_status,
			errors: { local: "", common: "" },
		},
		onSubmit: async (values) => {
			const disabledProjects =
				values.status === true ? [] : [Number(data?.project?.id)]

			await updateProjectNotifications.mutateAsync({
				investor: {
					notification_configuration: {
						disabled_yearly_interest_report_projects:
							disabledProjects,
					},
				},
			})
		},
	})

	return (
		<>
			<Helmet>
				<title>
					{t("project.notifications.page_title", {
						projectName: data?.project?.name,
					})}
				</title>
			</Helmet>
			<FormikProvider value={form}>
				<CardWrapper>
					<form onSubmit={form.handleSubmit}>
						<CardBody>
							<Heading
								as="h5"
								className="mb-3 sm:truncate"
								data-testid="project.notifications.title"
							>
								{t("project.notifications.title")}
							</Heading>
							<FormGroup
								heading={t("project.notifications.copy", {
									projectName: data?.project?.name,
								})}
							>
								<div className="space-y-6">
									<div className="flex items-center">
										<div className="order-1 ml-3">
											<Label
												htmlFor="status"
												positioning="grouped"
											>
												{t(
													"project.notifications.label",
												)}
											</Label>
										</div>
										<FormikCheckbox
											name="status"
											className="order-0"
										/>
									</div>
									<div>
										<FormikErrors i18nNamespace="profile" />
									</div>
								</div>
							</FormGroup>
						</CardBody>
						<CardFooter className="text-right">
							<FormikSubmitButton>
								{t("project.notifications.form_actions.save")}
							</FormikSubmitButton>
						</CardFooter>
					</form>
				</CardWrapper>
			</FormikProvider>
		</>
	)
}
