export const transformErrorToText = (
	error: any,
	options: {
		filteredErrorMessages?: Set<string>
		ignoredErrorMessages?: Set<string>
	} = {},
) => {
	try {
		const message = JSON.parse(error.text)?.message

		if (options?.ignoredErrorMessages?.has(message)) {
			return "unknown_error"
		}

		if (options?.filteredErrorMessages) {
			if (options?.filteredErrorMessages?.has(message)) {
				return message
			}
		} else {
			return message
		}
	} catch {}

	return "unknown_error"
}
