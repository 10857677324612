// React
import { Fragment, useMemo, useState } from "react"

// Router
import { useNavigate } from "@/lib/router"
import { Pages } from "@/misc/pages"

// Queries
import {
	ProjectTypeEnum,
	PublicProjectType,
	useProjectsStatsTableQuery,
} from "@/api/graphql"

// Translate
import { useTrans } from "@/i18n"

// Tables
import {
	CellProps,
	useExpanded,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
	UseTableOptions,
} from "react-table"
import {
	Table,
	TableBody,
	TableDataCell,
	TableRowCell,
	TableHead,
	TableHeading,
} from "@/components/table-controls/TableItems"

// UI
import { CardBody, CardWrapper } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { SearchInput } from "@/components/form-controls/Input"
import { PaginationAsButtons } from "@/components/PaginationAsButtons"

// Constants
import { mapCoordinatesCenterOfNetherlands } from "@/misc/constants"

/**
 * ProjectStatsTable
 * @returns
 */
export function ProjectStatsTable() {
	// State
	const [filter, setFilter] = useState<string | undefined>()
	const limit = 20
	const [currentPage, setCurrentPage] = useState<number>(0)

	// Translations
	const t = useTrans(["project", "common"])

	// Query
	const { data } = useProjectsStatsTableQuery({
		latitude: mapCoordinatesCenterOfNetherlands.lat,
		longitude: mapCoordinatesCenterOfNetherlands.lng,
		radius: "500",
		limit,
		offset: currentPage * limit,
		name__icontains: filter,
		type: [
			ProjectTypeEnum.SolarShares,
			ProjectTypeEnum.SolarBonds,
			ProjectTypeEnum.Portfolio,
		],
	})

	// Memo
	const projects = useMemo(() => {
		return data?.public_projects?.results
	}, [data?.public_projects?.results])

	// PaginatorInfo
	const navigate = useNavigate()
	const totalProjectsCount = useMemo(
		() => data?.public_projects?.totalCount ?? 0,
		[data?.public_projects?.totalCount],
	)

	const columns = useMemo(() => {
		const cols: UseTableOptions<PublicProjectType>["columns"] = [
			{
				accessor: "name",
				Header: () => (
					<TableHeading>
						{t(
							"dashboard:dashboard.stats.projects_table.header.project",
						)}
					</TableHeading>
				),
			},
			{
				accessor: "type",
				Header: () => (
					<TableHeading>
						{t(
							"dashboard:dashboard.stats.projects_table.header.type",
						)}
					</TableHeading>
				),
				Cell: ({ value }: { value?: string | null }) => (
					<TableDataCell className="font-medium">
						<div className="bg-primary-500 inline-block rounded-md px-4 py-1 font-bold text-black opacity-90 shadow-2xl md:ml-4 md:mt-2 lg:mt-0">
							{t(`common:common.project.type.${value}`)}
						</div>
					</TableDataCell>
				),
			},
			{
				accessor: "start",
				Header: () => (
					<TableHeading>
						{t(
							"dashboard:dashboard.stats.projects_table.header.start_date",
						)}
					</TableHeading>
				),
			},
			{
				accessor: "project_obligations_status",
				Header: () => (
					<TableHeading>
						{t("common:common.project.obligations_status")}
					</TableHeading>
				),
				Cell: ({ value }: { value?: string | null }) => (
					<TableDataCell className="font-medium">
						{t(`common:common.project.obligations_status.${value}`)}
					</TableDataCell>
				),
			},
		]

		return cols.filter(Boolean)
	}, [])

	// Table
	const {
		getTableProps,
		getTableBodyProps,
		page,
		headerGroups,
		state: { pageSize, pageIndex },
		prepareRow,
	} = useTable(
		{
			columns,
			data: projects as PublicProjectType[],
			defaultColumn: {
				Cell: ({
					value,
				}: CellProps<PublicProjectType, string | number>) => (
					<TableDataCell>{value}</TableDataCell>
				),
			},
		},
		useGlobalFilter,
		useSortBy,
		useExpanded,
		usePagination,
	)

	return (
		<CardWrapper className="mb-4">
			<CardBody>
				<div className="md:flex">
					<Heading as="h5" className="mb-3 sm:truncate">
						{t("dashboard:dashboard.stats.projects_table.title")}
					</Heading>
					<div className="mb-4 ml-auto flex gap-4">
						<div className="sm:ml-auto">
							<SearchInput
								onChange={(event) => {
									setFilter(event.currentTarget.value)
								}}
								label={"Zoeken"}
								className="md:width-auto min-w-full"
							/>
						</div>
					</div>
				</div>

				<Table
					{...getTableProps({
						className: "min-w-[64rem] lg:min-w-0",
					})}
					data-testid="tablebody"
				>
					<TableHead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => {
									const { key } = column.getHeaderProps()

									return (
										<Fragment key={key}>
											{column.render("Header")}
										</Fragment>
									)
								})}
							</tr>
						))}
					</TableHead>
					<TableBody
						{...getTableBodyProps()}
						data-testid="tablebody-overview"
						data-pageindex={pageIndex}
					>
						{page.map((row) => {
							prepareRow(row)

							const isOdd = row.index % 2 === 0
							return (
								<Fragment key={row.id}>
									<TableRowCell
										isOdd={isOdd}
										className={"cursor-pointer"}
										onClick={() =>
											navigate(
												`${Pages.StatisticsPlatformSingleProject}/${row.original.id}`,
											)
										}
									>
										{row.cells.map((cell, index) => {
											return (
												<Fragment key={index}>
													{cell.render("Cell")}
												</Fragment>
											)
										})}
									</TableRowCell>
								</Fragment>
							)
						})}
						{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
						{page.length < pageSize && pageIndex !== 0 ? (
							<>
								{Array(Math.max(pageSize - page.length, 1))
									.fill(true)
									.map((_, index) => (
										<TableRowCell
											key={index}
											withHover={false}
											isOdd={index % 2 === 0}
										>
											<TableDataCell
												colSpan={columns.length}
											>
												<span className="dummy-text" />
											</TableDataCell>
										</TableRowCell>
									))}
							</>
						) : null}
					</TableBody>
				</Table>
				<CardBody>
					{totalProjectsCount !== 0 && (
						<PaginationAsButtons
							countPerPage={limit}
							totalCount={totalProjectsCount ?? 0}
							itemType={"common.pagination.item_types.project"}
							currentPage={currentPage + 1}
							currentItemsAmount={totalProjectsCount ?? 0}
							onNextPage={() =>
								setCurrentPage((currentPage) => currentPage + 1)
							}
							onPrevPage={() =>
								setCurrentPage((currentPage) =>
									Math.max(currentPage - 1, 0),
								)
							}
							analyticsId="investments"
						/>
					)}
				</CardBody>
			</CardBody>
		</CardWrapper>
	)
}

/**
 * ProjectStatsTableLoading
 * @returns
 */
export function ProjectStatsTableLoading() {
	// Translations
	const t = useTrans(["project", "common"])

	return (
		<CardWrapper className="mb-4">
			<CardBody>
				<div className="md:flex">
					<Heading as="h5" className="mb-3 sm:truncate">
						{t("dashboard:dashboard.stats.projects_table.title")}
					</Heading>
					<div className="mb-4 ml-auto flex gap-4">
						<div className="sm:ml-auto">
							<SearchInput
								disabled
								label={"Zoeken"}
								className="md:width-auto min-w-full"
							/>
						</div>
					</div>
				</div>

				<Table className="min-w-[64rem] lg:min-w-0">
					<TableHead>
						<tr>
							<TableHeading>
								{t(
									"dashboard:dashboard.stats.projects_table.header.project",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"dashboard:dashboard.stats.projects_table.header.start_date",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"dashboard:dashboard.stats.projects_table.header.current_expected_irr",
								)}
							</TableHeading>
							<TableHeading>
								{t("common:common.project.obligations_status")}
							</TableHeading>
						</tr>
					</TableHead>
					<TableBody>
						<TableRowCell
							className={"cursor-pointer"}
						></TableRowCell>
					</TableBody>
				</Table>
			</CardBody>
		</CardWrapper>
	)
}
