import { useEffect, useRef } from "react"

export const useIsMounted = () => {
	const ref = useRef(true)

	useEffect(() => {
		// this fixes an issue with hot reloading
		// where it can happen that the component unmounts
		// and what it's replaced by doesn't set the flag back to true
		ref.current = true

		return () => {
			ref.current = false
		}
	}, [])

	return ref
}
