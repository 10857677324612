import { classNames } from "@/lib/classnames"
import { useTrans } from "@/i18n"

// UI
import { Button } from "../../Button"

type GraphActionsProps = {
	availableIntervals: Array<string>
	setGraphInterval: any
	graphInterval: "day" | "month" | "year" | "all"
}

export function GraphActions({
	availableIntervals,
	setGraphInterval,
	graphInterval,
}: GraphActionsProps) {
	const t = useTrans("project")

	return (
		<>
			{([...availableIntervals] as const).map((interval, index) => {
				return (
					<Button
						key={interval}
						onClick={() => setGraphInterval(interval)}
						className={classNames(
							index === availableIntervals.length - 1
								? "rounded-l-none border-l-0"
								: index === 0
								? "rounded-r-none"
								: "rounded-none",
							graphInterval === interval && "relative z-10",
						)}
						variant={
							graphInterval === interval
								? "primary"
								: "transparent"
						}
					>
						{t(`project.data.energy_producted.button.${interval}`)}
					</Button>
				)
			})}
		</>
	)
}
