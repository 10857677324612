import { useAllInvestmentProjectsDatesQuery } from "@/api/graphql"

// This specifically calls Investment projects
export function useInvestmentsProjectsFirstDate() {
	const { data, ...rest } = useAllInvestmentProjectsDatesQuery()

	// Customize the data to fetch single project
	return {
		...rest,
		data: {
			...data,
			project:
				data?.me?.investment_projects?.results?.reduce(
					(minStartDateProject, currentProject) => {
						const currentDate = new Date(currentProject?.start)
						const minDate = new Date(minStartDateProject?.start)

						return currentDate < minDate
							? currentProject
							: minStartDateProject
					},
					data.me.investment_projects.results[0],
				) || null,
		},
	}
}
