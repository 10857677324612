import { Helmet } from "@/lib/seo"
import { Container } from "@/components/Container"
import { useTrans } from "@/i18n"
import { PageTemplate } from "@/templates/PageTemplate"
import { Heading } from "@/components/Typography"

export const ProjectNotFound = () => {
	const t = useTrans("project")

	return (
		<>
			<Helmet>
				<title>{t("project.not_found.title")}</title>
			</Helmet>
			<PageTemplate title={t("project.not_found.title")}>
				<Container className="my-6 sm:my-8">
					<div className="flex justify-center">
						<div
							className={
								"overflow-hidden rounded-lg bg-white px-4 py-6 text-center shadow sm:px-6 sm:py-8 lg:px-8 lg:py-10"
							}
						>
							<Heading as="h3" styleAs="h5" className="sm:mt-2">
								{t("project.not_found.heading")}
							</Heading>
							<p className="m-auto mt-4 max-w-sm text-sm text-gray-600">
								{t("project.not_found.message")}
							</p>
						</div>
					</div>
				</Container>
			</PageTemplate>
		</>
	)
}
