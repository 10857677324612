import { ReactElement } from "react"
import { useTrans } from "@/i18n"
import { FiMail, FiPhone } from "react-icons/fi"
import { UserMFAMethod } from "@/api/rest/generated/@types"
import { Button } from "@/components/Button"

const iconsDict: { [key: string]: ReactElement } = {
	email: <FiMail className="mr-1 inline-flex" />,
	sms_api: <FiPhone className="mr-1 inline-flex" />,
}

export const MfaMethodButton = ({
	method,
	deactivateMethodRequest,
	setPrimaryMethodRequest,
}: {
	method: UserMFAMethod
	deactivateMethodRequest: (method: UserMFAMethod) => any
	setPrimaryMethodRequest: (method: UserMFAMethod) => any
}) => {
	const t = useTrans("profile")

	return (
		<div className="mb-1 flex items-center">
			{iconsDict[method.name]}
			<span>{t(`profile.security.mfa_method.${method.name}`)}</span>

			<Button
				className="ml-2"
				size="pill"
				variant="secondary"
				onClick={() => setPrimaryMethodRequest(method)}
			>
				{t("profile.security.two_factor_auth.state.btn_set_as_primary")}
			</Button>

			<Button
				className="ml-2"
				size="pill"
				variant="error"
				onClick={() => deactivateMethodRequest(method)}
			>
				{t("profile.security.two_factor_auth.state.btn_disable")}
			</Button>
		</div>
	)
}
