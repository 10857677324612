import { useTrans } from "@/i18n"
import {
	useProductionGraphContext,
	AvailableDatePeriods,
} from "./ProductionGraph"

// DateTime
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/misc/constants"

// UI
import { classNames } from "@/lib/classnames"
import { Button } from "@/components/Button"
import { ChevronDownIcon } from "@heroicons/react/outline"
import {
	DatePicker,
	DatePickerDate,
	transformToDatePickerDate,
} from "@/components/form-controls/DatePicker"

// Animations
import { getTransitionPopoverProps } from "@/lib/animations"
import { Popover, Transition } from "@headlessui/react"

/**
 * ProductionGraphActions
 * @returns
 */
export function ProductionGraphActions() {
	const t = useTrans("project")
	const { setGraphInterval, graphInterval } = useProductionGraphContext()

	return (
		<div className="mt-4 flex flex-col gap-4 md:flex-row lg:mt-0">
			{/** Date range picker */}
			<DateRangePicker />
			<div>
				<Button
					onClick={() => setGraphInterval("day")}
					data-testid="project.data.production.button.monthly"
					className={classNames(
						"rounded-r-none",
						graphInterval === AvailableDatePeriods.DAY &&
							"relative z-10",
					)}
					variant={
						graphInterval === AvailableDatePeriods.DAY
							? "primary"
							: "transparent"
					}
				>
					{t("project.data.production.button.monthly")}
				</Button>
				<Button
					onClick={() => setGraphInterval("month")}
					className={classNames(
						"rounded-l-none border-l-0",
						graphInterval === AvailableDatePeriods.MONTH &&
							"relative z-10",
					)}
					variant={
						graphInterval === AvailableDatePeriods.MONTH
							? "primary"
							: "transparent"
					}
				>
					{t("project.data.production.button.yearly")}
				</Button>
			</div>
		</div>
	)
}

/**
 * DateRangePicker
 * @param param0
 * @returns
 */
function DateRangePicker({ className }: { className?: string }) {
	// Context
	const { startTime, setStartTime, endTime, setEndTime } =
		useProductionGraphContext()

	return (
		<div className={classNames("flex items-center gap-4", className)}>
			<Popover.Group className="flex items-center divide-x divide-gray-200">
				<Popover className="relative z-20 inline-block text-left">
					<Popover.Button
						as="div"
						className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
					>
						<Button variant="transparent" size="small">
							<span className="mr-2">
								{DateTime.fromJSDate(startTime).toFormat(
									dateFormat,
								)}
							</span>

							<ChevronDownIcon
								className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
								aria-hidden="true"
							/>
						</Button>
					</Popover.Button>

					<Transition {...getTransitionPopoverProps()}>
						<Popover.Panel className="absolute left-0 mt-2 origin-top-left rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
							{({ close }) => (
								<div
									className="min-w-[300px]"
									data-testid="daterangepicker-from"
								>
									<DatePicker
										selectedDate={transformToDatePickerDate(
											startTime,
										)}
										onSelectDate={(
											date: DatePickerDate,
										) => {
											const newDate =
												DateTime.fromObject(date)
											setStartTime(newDate.toJSDate())
											close()
										}}
									/>
								</div>
							)}
						</Popover.Panel>
					</Transition>
				</Popover>
			</Popover.Group>
			<span className="text-sm text-gray-500">to</span>
			<Popover.Group className="flex items-center divide-x divide-gray-200">
				<Popover className="relative z-20 inline-block text-left">
					<Popover.Button
						as="div"
						className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
					>
						<Button variant="transparent" size="small">
							<span className="mr-2">
								{DateTime.fromJSDate(endTime).toFormat(
									dateFormat,
								)}
							</span>
							<ChevronDownIcon
								className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
								aria-hidden="true"
							/>
						</Button>
					</Popover.Button>

					<Transition {...getTransitionPopoverProps()}>
						<Popover.Panel className="absolute left-0 mt-2 origin-top-left rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
							{({ close }) => (
								<div
									className="min-w-[300px]"
									data-testid="daterangepicker-to"
								>
									<DatePicker
										selectedDate={transformToDatePickerDate(
											endTime,
										)}
										onSelectDate={(
											date: DatePickerDate,
										) => {
											const newDate =
												DateTime.fromObject(date)
											setEndTime(newDate.toJSDate())
											close()
										}}
									/>
								</div>
							)}
						</Popover.Panel>
					</Transition>
				</Popover>
			</Popover.Group>
		</div>
	)
}
