import { get } from "@/lib/cookies"
import { NetworkError } from "./network-errors"

// Env variables
import {
	VERCEL_ENV,
	API_URL as ORIGINAL_API_URL,
	STORYBOOK_API_URL,
} from "@/lib/env"

export const normaliseApiUrl = (url?: string) => {
	if (!url) {
		throw new Error(NetworkError.NO_API_URL)
	}

	// make sure no slash is present at the end of the url
	const withSlash = url.replace(/\/$/, "")

	// in production we force https
	// so the url starts with https://
	if (VERCEL_ENV !== "development") {
		if (!url.includes("127.0.0.1")) {
			return withSlash.replace(/^(https?:\/\/)?/, "https://")
		}
	}

	return withSlash
}

export const apiUrlOverride = new URL(window.location.href).searchParams.get(
	"mockapi",
)

const API_URL = normaliseApiUrl(
	apiUrlOverride || ORIGINAL_API_URL || STORYBOOK_API_URL,
)

export const getUrl = (url: string) => {
	if (!url.startsWith("/")) {
		url = `/${url}`
	}
	return `${API_URL}${url.replace(/\/?$/, "/")}`
}

const ignoredErrors = new Set([
	"The email/pin combination provided could not be found",
	"The token/subject combination provided could not be found",
])

const ENABLE_LOG = false

export const api = async <T extends unknown>(
	url: string,
	options?: RequestInit,
): Promise<T> => {
	try {
		url = `/${url.replace(/^\//, "")}`

		const headers = new Headers(options?.headers)
		if (!headers.has("accept")) {
			headers.set("accept", "application/json")
		}
		if (!headers.has("content-type")) {
			headers.set("content-type", "application/json")
		}

		headers.set("X-CSRFToken", get("csrftoken") || "")

		const response = await fetch(getUrl(url), {
			method: "POST",
			...options,
			headers,
			credentials: "include",
			mode: "cors",
		})

		// response.ok means status is in the range of 200-299
		if (!response.ok) {
			throw response
		}

		if (response.statusText === "No Content") {
			return null as T
		}

		const json = await response.json()

		return json
	} catch (response: any) {
		let text
		try {
			text = await response.text()
		} catch {}

		let json
		try {
			json = JSON.parse(text)
		} catch {
			json = text
		}

		const error = new Error(`ApiError: ${response.status} at ${url}`)
		const options: ApiErrorResponseInterface = {
			__apierror: true,
			url,
			status: response.status,
			text,
			json,
		}

		if (!ignoredErrors.has(json?.message)) {
			if (ENABLE_LOG) console.error(error, options)
		}

		// rethrow the error so consumer can still catch it
		throw options
	}
}

export interface ApiErrorResponseInterface {
	__apierror?: true
	url: string
	status: number
	text: string | null
	json: Record<string, any> | null
}
