// GraphQL
import {
	useAllProjectNotificationsQuery,
	useInvestorUpdateMutation,
} from "@/api/graphql"
import { useTrans } from "@/i18n"
import { CardBody, CardFooter, CardWrapper } from "../Card"

// Forms
import { FormikProvider, useFormik } from "formik"
import { FormGroup } from "../form-controls/FormGroup"
import {
	FormikCheckbox,
	FormikErrors,
	FormikSubmitButton,
} from "../form-controls/formik"
import { Label } from "../form-controls/Label"
import { handleErrorWithFormik } from "@/lib/formik"

// UI
import { Heading } from "../Typography"
import { useToasts } from "@/context/toasts"

export const GeneralNotifications = () => {
	const toasts = useToasts()
	const t = useTrans("profile")
	const { data, refetch } = useAllProjectNotificationsQuery()

	const updatePersonalDetailsMutation = useInvestorUpdateMutation({
		onSuccess: async (response) => {
			// Success
			if (response?.investor_update) {
				toasts.addToast({
					variant: "success",
					id: `bank-details-success-${Date.now()}`,
					text: t(`profile.personal_details.success_message`),
				})
				await refetch()
			}
			// otherwise show a message that there are form errors
			else {
				form.setFieldError(
					"errors.common",
					"common.form_errors.attention_required",
				)
			}
		},
		onError: (error: Error) => {
			handleErrorWithFormik(error, form)
		},
	})

	const form = useFormik({
		initialValues: {
			disabledYearlyInterestReportProjects:
				data?.me?.investor?.notification_configuration
					?.disabled_yearly_interest_report_projects ?? [],
			annualCheck:
				data?.me?.investor?.notification_configuration
					?.receive_check_data ?? false,
			monthlyReport:
				data?.me?.investor?.notification_configuration
					?.receive_monthly_report ?? false,
			errors: { local: "", common: "" },
		},
		onSubmit: async (values) => {
			await updatePersonalDetailsMutation.mutateAsync({
				investor: {
					notification_configuration: {
						receive_check_data: values?.annualCheck ?? false,
						receive_monthly_report: values?.monthlyReport ?? false,
					},
				},
			})
		},
	})

	return (
		<FormikProvider value={form}>
			<CardWrapper>
				<form
					onSubmit={form.handleSubmit}
					data-testid="general-notifications"
				>
					<CardBody>
						<Heading
							as="h2"
							styleAs="h5"
							className="mb-3 sm:truncate"
						>
							{t("profile.general_notifications.title")}
						</Heading>
						<FormGroup
							heading={t("profile.general_notifications.copy")}
						>
							<div className="space-y-6">
								<div className="flex items-start">
									<div className="order-1 ml-3 flex-grow">
										<Label htmlFor="annualCheck">
											{t(
												"profile.general_notifications.annual_check.title",
											)}
											<p className="mb-2 mt-1 text-xs text-gray-500 md:w-10/12">
												{t(
													"profile.general_notifications.annual_check.copy",
												)}
											</p>
										</Label>
									</div>
									<FormikCheckbox
										name="annualCheck"
										className="order-0 mt-1"
									/>
								</div>
								<div className="flex items-start">
									<div className="order-1 ml-3 flex-grow">
										<Label htmlFor="monthlyReport">
											{t(
												"profile.general_notifications.monthly_report.title",
											)}
											<p className="mb-2 mt-1 text-xs text-gray-500 md:w-10/12">
												{t(
													"profile.general_notifications.monthly_report.copy",
												)}
											</p>
										</Label>
									</div>
									<FormikCheckbox
										name="monthlyReport"
										className="order-0 mt-1"
									/>
								</div>
								<div>
									<FormikErrors i18nNamespace="profile" />
								</div>
							</div>
						</FormGroup>
					</CardBody>
					<CardFooter className="text-right">
						<FormikSubmitButton>
							{t(
								"profile.general_notifications.form_actions.save",
							)}
						</FormikSubmitButton>
					</CardFooter>
				</form>
			</CardWrapper>
		</FormikProvider>
	)
}
