import { Environment, graphMinKwhRoundThreshold } from "./constants"
import { stripSeparatorsFromWholeNumber } from "@/lib/math"
import { groupBy } from "lodash"

// Env variables
import { VERCEL_ENV, JEST_WORKER_ID } from "@/lib/env"

export const isProduction = (): boolean => VERCEL_ENV === Environment.production

export const isDevelopment = (): boolean =>
	VERCEL_ENV === Environment.development

export const isTesting = (): boolean => JEST_WORKER_ID !== undefined

/**
 * truncate float without rounding it up or down and return new float with two decimals
 * @param value
 */
export const truncateFloatNumber = (value: number) => {
	return Math.floor(value * 100) / 100.0
}

export const emptyValue = (): string => "-"

export const formatGraphValue = (value: number | string): number => {
	const formattedValue =
		typeof value === "string"
			? parseFloat(stripSeparatorsFromWholeNumber(value))
			: value

	return formattedValue > graphMinKwhRoundThreshold
		? Math.round(formattedValue)
		: formattedValue
}

// Function that generates a string with share numbers, given an array.
export function getShareNumberRange(data: any, t: Function) {
	const strList: string[] = []
	const groupedData = groupBy(
		data
			.sort((a: number, b: number) => a - b) // Sort this first because apperantly share numbers may return out-of-order
			.map((x: any, i: number) => [i, x]),
		([i, x]) => i - x,
	)

	// Convert object to iterable using Object.entries()
	for (const g of Object.values(groupedData)) {
		const ilist = g.map(([_, item]) => item)
		if (ilist.length > 1) {
			strList.push(
				t("investments:investments.fiscal.share_numbers", {
					shareNumberStart: ilist[0],
					shareNumberEnd: ilist[ilist.length - 1],
				}),
			)
		} else {
			strList.push(`${ilist[0]}`)
		}
	}
	return strList.join(", ")
}
