import { Fragment, Suspense } from "react"

// Router
import { useNavigate } from "@/lib/router"
import { Pages } from "@/misc/routes"

// Translations
import { useTrans } from "@/i18n"

// UI
import { Heading } from "@/components/Typography"
import { LoadingScreen } from "@/screens/LoadingScreens"
import { CardBody, CardWrapper, Card } from "@/components/Card"
import { Button } from "@/components/Button"
import { FiscalOverviewByYear } from "./components/FiscalOverviewByYear"

// Errors
import { ErrorBoundary } from "@/components/errors/ErrorBoundary"
import { ErrorState } from "@/components/errors/ErrorState"

/**
 * Fiscal
 * @returns
 */
export const Fiscal = () => {
	const t = useTrans("investments")
	const navigate = useNavigate()

	return (
		<div className={`space-y-8`} data-testid="fiscaloverview">
			{/** Banner to new financial overview */}
			<Card className="mb-3 w-full bg-yellow-50">
				<Heading as="h2" styleAs="h5" className="mb-3 sm:truncate">
					{t("investments:fiscal.banner.title")}
				</Heading>
				<div className="mb-4">
					<p className="text-sm text-gray-500">
						{t("investments:fiscal.banner.subtitle")}
					</p>
				</div>
				<Button
					className="inline-block"
					onClick={() => {
						navigate(Pages.InvestmentsProjectsCurrentOverview)
					}}
				>
					{t("investments:fiscal.banner.cta")}
				</Button>
			</Card>

			{/** Fiscal year overview */}
			<CardWrapper>
				<CardBody>
					<Heading
						as="h2"
						styleAs="h5"
						className="mb-3 sm:truncate"
						data-testid="investments.fiscal.header"
					>
						{t("investments:investments.fiscal.header")}
					</Heading>
					<div className="space-y-4">
						{t("investments:investments.fiscal.copy")
							.split("\n\n")
							.map((text, index) => (
								<p
									className="text-sm text-gray-500 sm:w-3/4 lg:w-1/2"
									key={`${text}.${index}`}
								>
									{text.split("\n").map((text, index) => (
										<Fragment key={`${text}.${index}`}>
											{text}
											<br />
										</Fragment>
									))}
								</p>
							))}
					</div>
				</CardBody>
			</CardWrapper>

			<Suspense fallback={<LoadingScreen className="h-full" />}>
				<ErrorBoundary
					fallback={
						<ErrorState
							title={t(
								"investments:investments.fiscal.error.title",
							)}
							message={t(
								"investments:investments.fiscal.error.message",
							)}
						/>
					}
				>
					<FiscalOverviewByYear />
				</ErrorBoundary>
			</Suspense>
		</div>
	)
}
