import { ReactNode } from "react"
import { UserRoles } from "@/misc/constants"
import { useCurrentUserRoles } from "@/context/user"
import { NotFound } from "@/pages/NotFound"

interface AuthenticatedRouteProps {
	roles: Array<UserRoles>
	fallback?: ReactNode
	children: ReactNode
}

export const AuthenticatedRoute = ({
	roles,
	fallback = <NotFound />,
	children,
}: AuthenticatedRouteProps) => {
	const { hasOneOfRoles } = useCurrentUserRoles()

	if (hasOneOfRoles(...roles)) {
		return <>{children}</>
	}

	return <>{fallback}</>
}
