// Features
import { initialState as initialAuthState } from "./features/authSlice"
import { initialState as initialInvestmentsOverviewState } from "./features/investmentsOverview/slice"
import { initialState as initialUserPreferencesState } from "./features/userPreferencesSlice"

// Values
import { ProjectSortMethod } from "./features/investmentsOverview/types"

// Export migrations
export const migrations = {
	1: (state) => {
		return state
	},
	2: (state) => {
		return {
			...state,
			auth: {
				...initialAuthState,
				...state.auth,
			},
			investmentsOverview: {
				...initialInvestmentsOverviewState,
				...state.investmentsOverview,
			},
			userPreferences: {
				...initialUserPreferencesState,
				...state.userPreferences,
			},
		}
	},
	3: (state) => {
		return {
			...state,
			auth: {
				...initialAuthState,
				...state.auth,
			},
			investmentsOverview: {
				...initialInvestmentsOverviewState,
				...state.investmentsOverview,
			},
			userPreferences: {
				...initialUserPreferencesState,
				...state.userPreferences,
			},
		}
	},
	4: (state) => {
		return {
			...state,
			investmentsOverview: {
				...state.investmentsOverview,
				sortingMethod: ProjectSortMethod.Name,
			},
		}
	},
	5: (state) => {
		return {
			...state,
			investmentsOverview: {
				...state.investmentsOverview,
				showHiddenProjects: true,
			},
		}
	},
}
