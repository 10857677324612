import { useTrans } from "@/i18n"
import { EmptyGridState } from "../../../EmptyGridState"
import { Heading } from "../../../Typography"

function FloatingEmptyGridState() {
	return (
		<div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
			<EmptyGridState className="h-full max-h-40 w-full justify-center" />
		</div>
	)
}

export function AreaGraphBeforeSunrise() {
	const t = useTrans()

	return (
		<div className="absolute left-0 top-1/2 flex w-full -translate-y-1/2 flex-col items-center justify-center">
			<FloatingEmptyGridState />
			<div className="z-2 relative">
				<Heading as="h4" className="text-center">
					{t("No data yet")}
				</Heading>
				<p className="mt-2 max-w-2xl text-center text-sm text-gray-500">
					{t(
						"There has been no data collected today as the sun has not risen yet, come back when the sun is up.",
					)}
				</p>
			</div>
		</div>
	)
}
